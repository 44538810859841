export default {
    isFunction(func) {
        return (
            Object.prototype.toString.call(func) === '[object Function]' ||
            Object.prototype.toString.call(func) === '[object AsyncFunction]'
        );
    },
    isString(str) {
        return Object.prototype.toString.call(str) === '[object String]';
    },
    isArray(arr) {
        return Object.prototype.toString.call(arr) === '[object Array]';
    },
    isDate(date) {
        return Object.prototype.toString.call(date) === '[object Date]';
    },
    isNull(obj) {
        return obj === null || obj === undefined;
    },
    isObject(obj) {
        return Object.prototype.toString.call(obj) === '[object Object]';
    },
    isRegExp(obj) {
        return Object.prototype.toString.call(obj) === '[object RegExp]';
    },
    isEmpty(obj) {
        if (!obj) {
            return false; 
        }
        for (let name in obj) {
            if (obj.hasOwnProperty(name)) {
                return false; 
            }
        }
        return true;
    }
};
