// import { createPopper } from '@popperjs/core/lib/popper-lite';
import { createPopper, preventOverflow, flip, offset } from '@popperjs/core';
// import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';
// import flip from '@popperjs/core/lib/modifiers/flip';
// import offset from '@popperjs/core/lib/modifiers/offset';

const stop = e => e.stopPropagation();
export default {
    emits: ['popperStatusChange'],
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: Boolean,
            default: false
        },
        reference: {},
        popper: {},
        offset: {
            type: Array,
            default: () => [] //[0,0]
        },
        placement: {
            type: String,
            default: 'bottom-start'
        },
        popperOptions: {
            type: Object,
            default: () => ({})
        },
        modifiers: {
            type: Array,
            default: () => []
        },
        appendToBody: {
            type: Boolean,
            default: true
        },
        visibleArrow: Boolean
    },
    data: function() {
        return {
            showPopper: false,
            currentPlacement: ''
        };
    },
    watch: {
        value(val) {
            this.showPopper = val;
        },
        showPopper(val) {
            if (this.disabled) {
                return; 
            }
            this.$emit('popperStatusChange', val);
            val && this.updatePopper();
        }
    },
    created() {
        this.popperInstance = null;
    },

    methods: {
        show() {
            this.showPopper = true;
        },
        hide() {
            this.showPopper = false;
        },
        toggle() {
            this.showPopper = !this.showPopper;
        },
        createPopper() {
            this.currentPlacement = this.currentPlacement || this.placement;
            if (
                !/^(top|bottom|left|right)(-start|-end)?$/g.test(
                    this.currentPlacement
                )
            ) {
                console.error('error placement value');
                return;
            }
            let reference = (this.referenceEle =
                this.referenceEle || this.reference || this.$refs.ref_reference);
            const popper = (this.popperEle =
                this.popperEle || this.popper || this.$refs.ref_popper);
            if (
                !this.referenceEle &&
                this.$refs.wrapper &&
                this.$refs.wrapper.children[0]
            ) {
                reference = this.referenceEle = this.$refs.wrapper.children[0];
            }
            if (!reference || !popper) {
                return; 
            }
            if (document) {
                this.appendToBody && document.body.appendChild(this.popperEle);
            }
            const arrow = this.visibleArrow && this.appendArrow(this.popperEle);
            const options = this.popperOptions || {};
            const modifiers = [
                preventOverflow,
                flip,
                offset,
                this.getOffsetModifier(),
                ...(options.modifiers || []),
                ...this.modifiers
            ];
            this.visibleArrow &&
                arrow &&
                modifiers.push({
                    name: 'arrow',
                    options: {
                        element: arrow,
                        padding: 5
                    }
                });

            options.modifiers = modifiers;
            options.placement = this.currentPlacement;
            this.doDestroy();
            // console.log('popper options', options);
            this.popperInstance = createPopper(reference, popper, options);
            this.popperEle.addEventListener('click', stop);
        },
        appendArrow(element) {
            if (this.appended) {
                return;
            }
            this.appended = true;
            const arrow = document.createElement('div');
            arrow.setAttribute('data-popper-arrow', true);
            arrow.classList.add('arrow');
            element.appendChild(arrow);
            return arrow;
        },
        getOffsetModifier() {
            return {
                name: 'offset',
                options: {
                    offset:
                        this.visibleArrow && !this.offset.length
                            ? [0, 8]
                            : this.offset
                }
            };
        },
        updatePopper() {
            const popper = this.popperInstance;
            if (popper) {
                popper.update();
            } else {
                this.createPopper();
            }
        },
        doDestroy(force) {
            if (!this.popperInstance || (this.showPopper && !force)) {
                return; 
            }
            this.popperInstance.destroy();
            this.popperInstance = null;
        }
    },
    beforeUnmount() {
        this.doDestroy(true);
        this.popperElm && this.popperElm.removeEventListener('click', stop);
        this.popperEle &&
            this.popperEle.parentNode === document.body &&
            document.body.removeChild(this.popperEle);
    }
};
