import util from './util';

const ENABLE_DYNAMIC_REGISTER = 'enableDynamicRegister';
const FIRST_PAGE = 'source';
const VIEW_COUNT = 'view_count';
const EXPIRES = 1;
const AUTOPOPUP_CONFIG = 'autopopup';
const REGISTRATION_CONFIG = 'lp_registration';

const WHITE_PAGEKEY = [
    'evaluation',
    'evaluation-seller',
    'seller-evaluation',
    'lead-generation',
    'sell',
    'seller-tips',
    'successful-activation',
    'he-home',
    'schedule_meeting_link',
    'meeting',
    'password-reset',
    'password-forget'
];
const WHITE_PATHNAME = [
    '/listing-for-cms',
    '/listing-for-cms-snapshot',
    '/site/privacy-terms',
    '/forget-password',
    '/reset-password'
];
let TIMEOUT_QUEUE = [];
// the pages need popup agreement pop
const AGREEMENT_LISTING_PAGES = ['sold-listing', 'listing'];
const AGREEMENT_LISTING_DETAIL_PAGES = [
    'listing-detail',
    'listing-detail-sold'
];
const HAS_POP_AGREEMENT = '_PopupAgreement';

//  There is a cache landingpage use the rules landingpage the rule of 
export const getRegistrationOptions = (isLandingPage, options) => {
    const globalData = window.sitePrepareData();
    // record the first page , to trigger its autopopup
    let firstPageView = util.getCookie(FIRST_PAGE),
        lp_name = `lp=${window.location.pathname}`;
    if (isLandingPage) {
        util.setStorageItem(
            'localStorage',
            AUTOPOPUP_CONFIG,
            JSON.stringify(options)
        );
        let registrationSetting = { ...globalData.registration };
        registrationSetting.registerUserType = globalData.registerUserType;
        if (globalData.leadType) {
            registrationSetting.registerUserType = globalData.leadType;
        }
        delete registrationSetting.autopopup;
        util.setStorageItem(
            'localStorage',
            REGISTRATION_CONFIG,
            JSON.stringify(registrationSetting)
        );
        if (firstPageView !== lp_name) {
            // use new lp rule
            util.setCookie(FIRST_PAGE, lp_name, EXPIRES);
            util.setCookie(VIEW_COUNT, 0, EXPIRES);
        }

        //  if lp Configured dynamicRegister just write cookie, does not exist dynamicRegister reset the configuration 
        util.setCookie(
            ENABLE_DYNAMIC_REGISTER,
            globalData.registration?.autopopup?.isDynamicRegister ? 1 : 0,
            EXPIRES
        );

        return [options, {}];
    }
    if (firstPageView) {
        // use lp rule
        let autopopupOptions = JSON.parse(
            util.getStorageItem('localStorage', AUTOPOPUP_CONFIG) || '{}'
        );
        let registrationSetting = JSON.parse(
            util.getStorageItem('localStorage', REGISTRATION_CONFIG) || '{}'
        );
        registrationSetting = fixLeadType(registrationSetting);
        return [autopopupOptions, registrationSetting];
    }
    return [options, {}];
};
function fixLeadType(registrationSetting) {
    if (!registrationSetting) {
        return; 
    }
    let role = registrationSetting.registerUserType;
    let leadTypes = {
        BUYER: '2',
        SELLER: '1',
        BANDS: '1,2',
        RENTAL: '5',
        OTHER: '-1',
    }
    if (leadTypes[role]) {
        registrationSetting.registerUserType = leadTypes[role];
        util.setStorageItem(
            'localStorage',
            REGISTRATION_CONFIG,
            JSON.stringify(registrationSetting)
        );
    }
    return registrationSetting;

}
//  Do you need a pop-up frame? 
const tool = {
    passLandingPage() {
        return util.getCookie(FIRST_PAGE);
    },
    isInPages(pages, pathName) {
        let pageArr = [];
        if (pages) {
            // Since the string passed in the background has been modified into json string ， But prevent raw data from malformed 
            try {
                pageArr = JSON.parse(pages);
            } catch (e) {
                // here to avoid url Cases with commas in are separated 
                pageArr = pages
                    .split(',/')
                    .map((link, index) => (index ? '/' : '') + link);
            }
        }

        for (let i = 0, len = pageArr.length; i < len; i++) {
            let pageItem = pageArr[i];
            const detailReg = /:[^/]+/;
            //  compatible /agent/{fullname}/:id
            if (pageItem === '/agent/detail/:id' || pageItem === '/agents/detail/:id') {
                pageItem = '/agents/:detail/:id';
            }
            if (detailReg.test(pageItem)) {
                const pageReg = new RegExp(
                    pageItem
                        .replace(/:id/g, '\\d+')
                        .replace(/:[^/]+/g, '[^/]+') + '$'
                );
                if (pageReg.test(pathName)) {
                    return true;
                }
            }
            if (pageItem === pathName) {
                return true;
            }
        }
        return false;
    },
    isListingPage(globalData) {
        return ['listing', 'sold-listing'].includes(globalData.pageKey);
    },
    isDetailPage(globalData) {
        return ['listing-detail', 'listing-detail-sold'].includes(
            globalData.pageKey
        );
    },
    isAdCreate(globalData) {
        return globalData.creatorId == -9;
    },
    isAdSource() {
        return (
            util.getCookie('_ads_source') || util.getQueryField('x_ads_source')
        );
    },
    isCurrentPage() {
        let firstPageView = util.getCookie(FIRST_PAGE),
            lp_name = `lp=${window.location.pathname}`;
        return firstPageView == lp_name;
    }
};

const isInNeedPopupPage = (triggerType, pageType, pages) => {
    var globalData = window.sitePrepareData();
    switch (triggerType) {
    case 0:
        // 0: Selected Pages
        //  exclude  pageArr page of 
        return !tool.isInPages(pages, window.location.pathname);
    case 1:
        //1: Only Listing detail pages
        return tool.isDetailPage(globalData);
        // 3 || 4: Landing Pages or HE  pass type Determine whether a pop-up window ， Fail triggerType
    case 3:
        //pageType yes landingPage proprietary 
        if (pageType == 2) {
            //Never Require Registration
            return false;
        }
        if (pageType == 1) {
            // Detail
            return tool.isDetailPage(globalData);
        }
        if (pageType == 5) {
            // Current Page -> Listing
            return tool.isCurrentPage();
        }
        return true;
    case 4:
        return true;
    default:
        //2:Never Trigger Registration Popup
        return false;
    }
};
//  Is it in the whitelist 
const isInWhiteList = globalData => {
    let isInPageKeys = WHITE_PAGEKEY.includes(globalData.pageKey),
        isInPathname = WHITE_PATHNAME.includes(window.location.pathname),
        isPopup =
            util.getQueryField('isPopup') === '0' ||
            util.getCookie('_isPopup') === 'false';
    return isInPageKeys || isInPathname || isPopup;
};
//register/login automatic poping up
const onAutoPopup = (vm, options) => {
    var globalData = window.sitePrepareData();
    // everytime open new page or reload clearTimeout
    clearTimeQueue();
    if (isInWhiteList(globalData)) {
        // if this page is in whitelist, clear all the timeout and dont't pop up;
        return false;
    }

    if (window.sitePrepareData && window.sitePrepareData()._disableAutoPopup) {
        return false;
    }

    const isAdSource = tool.isAdSource();
    let [
        {
            type,
            delay,
            click,
            triggerType,
            pageType,
            pages,
            closeCallback,
            allowClose
        },
        registration
    ] = getRegistrationOptions(globalData.isLandingPage, options);
    const regSysLanguage = registration.sysLanguage;
    let regLanguageTextFn = {};
    delete registration.sysLanguage;
    if (regSysLanguage && regSysLanguage.en) {
        let keys = Object.keys(regSysLanguage.en);
        regLanguageTextFn = keys.reduce(
            (pre, k) =>
                Object.assign(pre, {
                    [k]: lng => regSysLanguage[lng]?.[k] ?? registration[k]
                }),
            {}
        );
    }
    if (!globalData.isLandingPage && isAdSource) {
        //landingPage + IDX（ non-advertising source ） Go configuration 
        if (tool.isDetailPage(globalData)) {
            //  Ad parameters have the highest priority 
            vm.openSignLog({
                ...registration,
                allowClose: false,
                languageTextFn: regLanguageTextFn
            });
            return true;
        }

        if (tool.isListingPage(globalData) && !tool.passLandingPage()) {
            //  Advertising parameters do not pop up on the list page 
            return;
        }
    }
    type = parseInt(type);
    if (!type) {
        return false;
    }
    triggerType = parseInt(triggerType);
    let flag = isInNeedPopupPage(triggerType, pageType, pages);
    if (!flag) {
        return false;
    }
    const count = parseInt(util.getCookie(VIEW_COUNT)) || 0;
    util.setCookie(VIEW_COUNT, count + 1, EXPIRES);

    allowClose =
        !isAdSource &&
        (allowClose === undefined
            ? globalData.registration?.allowClose
            : allowClose); //  Compatible with stock data ， The new volume station automatically pops up and the button can be closed autopopup inside 
    //  if selected Listing detail pages， Then the sold details page is fixed 3s rear bullet 
    if (triggerType === 1 && globalData.pageKey === 'listing-detail-sold') {
        let timeId = setTimeout(() => {
            vm.openSignLog({
                ...registration,
                allowClose,
                languageTextFn: regLanguageTextFn
            });
        }, 3000);
        TIMEOUT_QUEUE.push(timeId);
        return true;
    }
    const popSignLogin = () => {
        vm.openSignLog({
            ...registration,
            allowClose,
            onClose: closeCallback,
            onsuccess: closeCallback,
            languageTextFn: {
                ...regLanguageTextFn,
                questionMsg: () =>
                    triggerType === 4
                        ? vm.$t('common:register.questionMsg')
                        : ''
            }
        });
    };
    // page views
    if (type === 1) {
        click = parseInt(click) || 0;
        if (click <= count + 1) {
            popSignLogin();
        }
    }
    // browing time
    if (type === 2) {
        delay = parseInt(delay) || 0;
        let timeId = setTimeout(() => {
            popSignLogin();
        }, delay * 1000);
        TIMEOUT_QUEUE.push(timeId);
    }
    return flag;
};
export const clearTimeQueue = () => {
    if (TIMEOUT_QUEUE.length > 0) {
        TIMEOUT_QUEUE.forEach(timeId => {
            clearTimeout(timeId);
        });
        TIMEOUT_QUEUE = [];
    }
};

// get agreement popup's title and content by mlsOrg.
export const getAgreementTitleContent = (
    mlsOrgs = [],
    pageKey = '',
    moduleData = []
) => {
    let hasPopAgreement = util.getStorageItem(
        'localStorage',
        HAS_POP_AGREEMENT
    );
    // ad is not agreement
    if (hasPopAgreement || mlsOrgs.length === 0 || tool.isAdSource()) {
        return false;
    }

    if (AGREEMENT_LISTING_PAGES.includes(pageKey)) {
        for (let mls of mlsOrgs) {
            let title = mls.popUpTitle,
                content = mls.popUpContent;
            if (title || content) {
                return { title, content };
            }
        }
    }
    if (AGREEMENT_LISTING_DETAIL_PAGES.includes(pageKey)) {
        // TODO：house detail mlsOrg
        let module = moduleData.find(
            m => m.name === 'md-detail-info' || m.name === 'md-sold-detail'
        );
        let mlsOrgId;
        try {
            mlsOrgId = module && module.data.listingDetail.info.mlsOrgId;
        } catch {
            console.log("error: house's detail info is empty.");
        }
        if (mlsOrgId) {
            let mls = mlsOrgs.find(m => m.id == mlsOrgId),
                title = mls && mls.popUpTitle,
                content = mls && mls.popUpContent;
            if (title || content) {
                return { title, content };
            }
        }
    }
    return false;
};

export default onAutoPopup;
