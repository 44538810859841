import { getFederatedDebug } from '@/common/debug';
import axios from 'axios';

import { isSSRServer } from '@/config';


// import * as vue from 'vue';

// const wrapShareScope = () => {
//     return {
//         'vue':{
//             'undefined':{
//                 get: () => Promise.resolve(() => vue)
//                 //   get: () => import(__VUE_LIB).then(module => ()=> module)
//             }
//         }
//     }
// }
// var remoteLoadCache = {};
// const getViteFederatedComponent = async({remoteUrl, componentName, unwrapDefault=true})=>{
//     if (!isSSRServer){
//         if (!remoteLoadCache[remoteUrl]){
//             // webpack error: Critical dependency: the request of a dependency is an expression
//             remoteLoadCache[remoteUrl] = import(remoteUrl).then(res => {
//                 res.init(wrapShareScope());
//                 return res;
//             });
//         }
//         var res = await remoteLoadCache[remoteUrl];
//         var r = await res.get(componentName);
//         var result = r();
//         if (unwrapDefault){
//             return result.default;
//         }
//         return result;
//     }
// }


let urlCache = new Set();

const loadScript = async url => {
    if (!url) {
        return Promise.reject('url is required'); 
    }
    if (urlCache.has(url)) {
        return;
    }
    const element = document.createElement('script');
    element.src = url;
    element.type = 'text/javascript';
    element.async = true;

    return new Promise((resolve, reject) => {
        element.onload = () => {
            urlCache.add(url);
            resolve();
        };
        element.onerror = reject;
        document.head.appendChild(element);
    });
};


const getWebpackFederatedComponent = async function({ remoteUrl, componentName, scope = "dynamic", unwrapDefault = true, shareScope }) {
    if (!isSSRServer) {
        await  loadScript(remoteUrl);
        if (window[scope]) {
            if (shareScope) {

            }
            var res = await window[scope].get(componentName);
            console.log('getWebpackFederatedComponent', res)
            if (res) {
                var expr = res();
                if (unwrapDefault) {
                    return expr.default || expr;
                }
                return expr;
            }
        }
    }
}

//  Obtain  url
const getFederatedInfo = async function({ blockid, scope }) {
    let res = await axios.get(`/api/site-plugin/version/getAppCurrentVersion`, {
        params: { appId: blockid }
    });
    try {
        let data = JSON.parse(res.data?.data || '{}');
        let federation = data.federation || [];
        for (let i = 0; i < federation.length; i++) {
            let item = federation[i];
            if (item.scope === scope) {
                return {
                    url: item.remoteUrl || '',
                    from: data.from || 'webpack',
                };
            }
        }
        console.log(federation, scope);
    } catch (e) {
        console.error(e);
    }
    return '';
};


const getFederatedComponentApp = async function({ remoteUrl, scope, componentName, blockid }) {
    let form = 'webpack';
    if (!(getFederatedDebug() && remoteUrl)) {
        let info = await getFederatedInfo({
            blockid: blockid,
            scope: scope
        });
        if (info) {
            remoteUrl = info.url;
            form = info.form;
        }
    }

    if (!remoteUrl) {
        console.log('Remote URL is empty');
        return;
    }
    return getFederatedComponent({
        form,
        remoteUrl: remoteUrl,
        componentName: componentName,
        scope,
    });
};

const componentCache = new Map();
const getFederatedComponent = async({ remoteUrl, scope, componentName, from = 'webpack' }) => {
    const key = `${remoteUrl}-${scope}-${componentName}-${from}`;
    let cache = componentCache.get(key);
    if (cache) {
        return cache;
    }
    let Comp;
    // if(from == 'vite'){
    //     Comp = await getViteFederatedComponent({remoteUrl,componentName});
    // }else{
    //     Comp =  await getWebpackFederatedComponent({remoteUrl,scope,componentName});
    // }
    Comp =  await getWebpackFederatedComponent({ remoteUrl, scope, componentName });
    if (Comp && Comp.default) {
        Comp = Comp.default;
    }
    componentCache.set(key, Comp);
    return Comp;
} 

export {
    getFederatedInfo,
    getFederatedComponentApp,
    getFederatedComponent
}

