<template>
    <pop
        :showMask="true"
        :allowClose="true"
        class="out-sharing-pop"
        :onClose="onClose"
    >
        <div class="out-sharing-title">{{ $t("outSharing.manageSettings") }}</div>
        <div class="out-sharing-content">
            <div class="out-sharing-div">
                <span class="out-sharing-desc">{{ $t("outSharing.desc1") }}</span>
                <siteSwitch :value="optOut" @change="onSwitch" />
            </div>
            <p class="out-sharing-p">{{ $t("outSharing.desc2") }}</p>
            <div class="out-sharing-note">
                <p class="out-sharing-note-title">{{ $t("outSharing.note") }}:</p>
                <p class="out-sharing-p">
                    <span class="disc"></span>
                    <span class="desc">{{ $t("outSharing.desc3") }}</span>
                </p>
                <p class="out-sharing-p">
                    <span class="disc"></span>
                    <span class="desc">{{ $t("outSharing.desc4") }}</span>
                </p>
            </div>
        </div>
        <div class="out-sharing-footer">
            <button class="cancel" @click="onClose">{{ $t('verify.cancel') }}</button>
            <button class="confirm" @click="onConfirm">{{ $t('verify.confirm') }}</button>
        </div>
    </pop>
</template>
<script>
import util from '@/common/util';
import { mapState } from 'pinia';
import siteSwitch from '@/components/switch.vue';
import { useUserStore } from '@/store';
import { getCookieSetting, setCookieSetting, COOKIE_LEVEL } from '@/components/cookieAuthority/cookieStatus';
export default {
    i18next: {
        ns: 'common'
    },
    components: {
        siteSwitch
    },
    data: function() {
        return {
            optOut: false,
        }
    },
    created() {
        let localConfig = this.getLocal();
        this.optOut = localConfig;
        if (this.user) {
            this.getConfig().then(config => {
                this.optOut = localConfig || config;
                if (localConfig && !config) {
                    this.setConfig();
                } else if (!localConfig && config) {
                    this.setLocal()
                }
            })
        }
    },
    computed: {
        ...mapState(useUserStore, {
            user: store => store.user
        }),
    },
    methods: {
        onClose() {
            this.destroy()
        },
        onSwitch(value) {
            this.optOut = value;
        },
        getConfig() {
            let t = new Date().getTime();
            return this.axiosIns.get(`/api-site/dsar/optOutSharding/status?_t=${t}`).then(res => {
                return res.data?.open;
            })
        },
        setConfig() {
            this.setTargeting();
            return this.axiosIns.post('/api-site/dsar/optOutSharding', {
                open: this.optOut
            })
        },
        getLocal() {
            let local = localStorage.getItem('optOutSharing');
            if (local === null) {
                return null;
            } else {
                return local === '1'
            }
            
        },
        setLocal() {
            this.setTargeting();
            localStorage.setItem('optOutSharing', this.optOut ? '1' : '0');
        },
        setTargeting() {
            let setting = getCookieSetting(util)
            if (this.optOut) {
                setting[COOKIE_LEVEL.targeting] = false;
                setCookieSetting(setting, util)
            }
        },
        onConfirm() {
            if (this.user) {
                this.setLocal();
                this.setConfig().then(_ => {
                    this.onClose();
                });
            } else {
                this.setLocal();
                this.onClose();
            }
        }
    }
}
</script>
<style lang="scss">
@import '@/theme/media.scss';
@import '@/theme/mixin.scss';
.out-sharing-pop{
    .pop-mask{
        z-index: calc(var(--level-high) - 1);
    }
    .pop{
        line-height: 1.5;
        background: #fff;
        width: 720px;
        z-index: var(--level-high);
    }
    .out-sharing-content{
        padding: 30px;
    }
    .out-sharing-title{
        text-transform: uppercase;
        border-bottom: 1px solid #dadada80;
        line-height: 70px;
        font-size: 20px;
        font-family: var(--font-bold);
        padding-left: 30px;
        padding-right: 30px;
    }
    .site-switch{
        width: 40px;
        height: 20px;
        border-radius: 10px;
        flex-shrink: 0;
        .site-switch-cursor{
            width: 16px;
            height: 16px;
            transform: translate(2px, 2px);
            border: 0px solid;
        }
        &.on{
            background: var(--g-primary-color);
            .site-switch-cursor{
                transform: translate(-2px, 2px);
            }
        } 
    }
    .out-sharing-div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        .out-sharing-desc{
            font-size: 14px;
            font-family: var(--font-bold);
            color: #191919;
        }
    }
    .out-sharing-p{
        font-size: 12px;
        color: #505050;
        
    }
    .out-sharing-note-title{
        color: #505050;
        font-size: 12px;
        font-family: var(--font-bold);
        margin-bottom: 10px;
        font-size: 14px;
    }
    .out-sharing-note{
        border: 1px solid #dadada80;
        background: #dadada19;
        padding: 10px;
        border-radius: 4;
        margin-top: 30px;
        .out-sharing-p{
            display: flex;
            .disc{
                width: 5px;
                height: 5px;
                background: #A8A8A8;
                border-radius: 50%;
                flex-shrink: 0;
                flex-grow: 0;
                margin-right: 10px;
                margin-top: 6px;
            }
            .desc{
                flex: 1;
            }
        }
        .out-sharing-p + .out-sharing-p{
            margin-top: 10px;
        }
    }
    .out-sharing-footer{
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid #dadada80;
        font-size: 14px;
        line-height: 1.5;
        padding: 20px 30px;
        .cancel{
            color: #505050;
            cursor: pointer;
            width: 100px;
            height: 40px;
            margin-right: 10px;
            background: transparent;
        }
        .confirm{
            color: #fff;
            background: var(--g-primary-color);
            width: 100px;
            height: 40px;
        }
    }
    @include media-600{
        .pop{
            width: 345px;
        }
    }
}

</style>