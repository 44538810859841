<template>
    <div class="md-custom-code federated js mounted" v-if="showComponent">
        <div ref="dom"></div>
    </div>
</template> 
<script>
import {
    getFederatedComponentApp
} from '@/common/utils/load-remote';
import util from '@/common/util';
import { useRealData } from "@/hooks";
import { ref, onMounted, toRefs, watch, getCurrentInstance, onBeforeUnmount, nextTick } from 'vue';
export default {
    name: 'md-federated',
    props: {
        info: {
            type: Object,
            default() {
                return {};
            }
        },
        mdData: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    components: {},
    setup(props, context) {
           
        const showComponent = ref(false);
        const dom = ref();
        const child = ref();
        const info = props.info;
        const state =  useRealData({
            blockid: info.blockid || info.appid || '',
            componentName: info.componentName || '',
            remoteUrl: info.remoteUrl || '', // remoteUrl  Invalid online environment
            scope: info.scope || '',
        });
        // allData
        const vm = getCurrentInstance();
        
        watch(() => {
            let result = {
                blockid: state.blockid || state.appid || '',
                componentName: state.componentName || '',
                remoteUrl: state.remoteUrl || '', // remoteUrl  Invalid online environment
                scope: state.scope || '',
            }
            return result
        }, (a) => {
            Object.assign(state, a);
            loadModule();
        });
        watch(
            () => {
                let info = props.info;
                return {
                    blockid: info.blockid || info.appid || '',
                    componentName: info.componentName || '',
                    remoteUrl: info.remoteUrl || '', // remoteUrl  Invalid online environment
                    scope: info.scope || '',
                }
            },
            a => {
                Object.assign(state, a);
            }
        );
        watch(() => {
            return vm.proxy.$attrs
        }, (a) => {
            child.value?.updateProps?.(a);
        }, {
            deep: true
        })
        onMounted(() => {
            loadModule();
        });
        onBeforeUnmount(() => {
            if (child.value) {
                child.value.unmount();
            }
        });
        const loadModule =  util.debounce(async() => {
            showComponent.value = false;
            let blockid = state.blockid || state.appid;
            if (!blockid || !state.componentName || !state.scope) {
                console.warn("loadModule empty:", blockid, state.componentName, state.scope);
                return;
            }
            let methods = await getFederatedComponentApp({
                remoteUrl: state.remoteUrl,
                scope: state.scope,
                componentName: state.componentName,
                blockid: state.blockid
            });
            if (!methods) {
                console.warn('load empty !!');
                return;
            }
            if (typeof methods == 'function') {
                child.value =  methods();
            } else {
                child.value = methods;
            }
            showComponent.value = true;
            await nextTick();
            if (!dom.value) {
                return;
            }
            if (child.value) {
                child.value.mount({
                    el: dom.value,
                    context: vm.appContext,
                    $pinia: vm.proxy.$pinia
                }, {
                    ...vm.proxy.$attrs
                });
            }
            
            // console.log(com, vm.appContext);
        });

        return {
            ...toRefs(state),
            dom,
            showComponent
        };
    },
};
</script>