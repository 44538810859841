import util from '@/common/util';
import usePageStore from '../page';

const pcDefaultPosition = {
    bottom: '30px',
    right: '30px'
};
const mobileDefaultPosition = {
    bottom: '15px',
    right: '15px',
};
const mobileDefaultChatWindowPosition = {
    bottom: '0px',
    top: '0px',
    left: '0px',
    right: '0px'
}
export default {
    state: () => ({
        pcChatPosition: { ...pcDefaultPosition },
        mobileChatPosition: { ...mobileDefaultPosition },
        mobileChatWindowPosition: { ...mobileDefaultChatWindowPosition }
    }),
    getters: {
        isHideChat() {
            let pageStore = usePageStore(this.$pinia);
            let moduleStore = pageStore.findFirstSearchBarModule();
            let globalData = pageStore.page;
            return (
                moduleStore &&
                (moduleStore.layout === 'mixed' ||
                    moduleStore.layout === 'map') &&
                (globalData.pageKey === 'listing' ||
                    globalData.pageKey === 'sold-listing') &&
                !util.inCmsSetting
            );
        }
    },
    actions: {
        // UPDATE_CHAT_POSITION
        updateChatPosition({ bottom, right, mBottom, mRight, mChatWindowBottom }) {
            this.$patch(state => {
                bottom && (state.pcChatPosition.bottom = bottom);
                right && (state.pcChatPosition.right = right);
                mBottom && (state.mobileChatPosition.bottom = mBottom);
                mRight && (state.mobileChatPosition.right = mRight);
                mChatWindowBottom && (state.mobileChatWindowPosition.bottom = mChatWindowBottom)
            });
        },
        // RESET_CHAT_POSITION
        resetChatPosition() {
            this.$patch(state => {
                state.mobileChatPosition = { ...mobileDefaultPosition };
                state.pcChatPosition = { ...pcDefaultPosition };
                state.mobileChatWindowPosition = { ...mobileDefaultChatWindowPosition }
            });
        }
    }
};
