export function sqftToAcre(val) {
    if (!val && val !== 0) {
        return ''; 
    }
    val = (+val / 43560).toFixed(2).match(/(\d{0,3}\.\d{0,2})/);
    return (val && val[0]) || '';
}
export function acreToSqft(val) {
    if (!val && val !== 0) {
        return ''; 
    }
    return (+val * 43560).toFixed(0);
}
