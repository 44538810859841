import BUSINESS_TYPE from '@/common/business-type.js';
import RESOURCES from '@/common/permission/resources.js';

const INVISIBLE_RESOURCES = [];

const _init = (resources = []) => {
    const permission = resources.reduce((prev, next) => {
        const { name, children } = next;

        prev = {
            ...prev,
            [name]: !INVISIBLE_RESOURCES.includes(name),
            ..._init(children)
        };

        return prev;
    }, {});
    return permission;
};

const init = (businessType = BUSINESS_TYPE.BUSINESS_TYPE) => {
    let resources = {};

    if (businessType == BUSINESS_TYPE.BUSINESS_TYPE) {
        resources = RESOURCES;
    }

    return _init(resources);
};

export default init;
