import { isSSRServer } from '@/config';
import { useUserStore, usePageStore, useCommonStore } from '@/store';
import global from '../common/global';

function initData(pinia, globalData, pageData) {
    let userStore = useUserStore(pinia);
    userStore.login({
        user: globalData.loginInfo || 0
    });

    let pageStore = usePageStore(pinia);
    let { page, moduleData } = pageData;
    pageStore.setPage({
        page,
        ...globalData
    });
    pageStore.setModuleData(moduleData);
    //  according to cookie initialization currentLanguage
    pageStore.initCurrentLang();
    pageStore.initCurrency();
    pageStore.handleGlobalLngData();
    pageStore.handleModuleLngData();
    pageStore.simplifyModuleData();
}

export default {
    install: function(app, { pinia, globalData, pageData, stateValue, platform, globalizationIns }) {
        app.use(pinia);
        // 把platform解构复制到每个store作为扩展属性
        pinia.use(({ store }) => {
            Object.assign(store, platform, { 
                $pinia: pinia,
                $t: globalizationIns.$t.bind(globalizationIns)
            });
        });

        if (stateValue) {
            pinia.state.value = stateValue;
        } else {
            initData(pinia, globalData, pageData);
        }

        if (!isSSRServer) {
            global.resize(() => {
                let commonStore = useCommonStore(pinia);
                commonStore.updateWindowSize();
            });
        }
    }
}