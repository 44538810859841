export default function(util, query) {
    // social  The parameter capture
    if (query['lang']) {
        util.setCookie('lang', query['lang'], 7);
    }
    util.setCookie('site-search-listings', true);
    // social  The parameter capture
    if (query['cpclid']) {
        util.setCookie('cpclid', query['cpclid'], 7);
    }
}
