import { findStore } from '../../helpers';

const searchBarModules = [
    'md-search-bar',
    'md-search',
    'md-snapshot-search',
    'md-search-grid-list',
    'md-search-big-map'
];

const actions = {
    /**
     *  find the first one searchbar moduleData
     */
    findFirstSearchBarData: function() {
        const searchBarList = this.moduleData.filter(
            m => searchBarModules.includes(m.name) && m.data
        );
        return searchBarList[0];
    },
    /**
     *  find the first one searchbar store
     */
    findFirstSearchBarModule: function() {
        const searchBarData = this.findFirstSearchBarData();
        if (searchBarData) {
            const storeName = searchBarData.data.storeName;
            return findStore(this.$pinia, storeName);
        }
    },
    /**
     *  goal based module look forward or backward searchbar modular moduleData
     * @param {*} mark 1 Indicates looking forward to the first 
     * @param {*} id  Target module of id
     * @returns
     */
    findSearchBarDataByDirection: function(mark, id) {
        var modules = this.moduleData;
        const index = modules.findIndex(m => m.id === id);
        let start = mark > 0 ? 0 : index;
        let end = mark > 0 ? index : modules.length;
        const arr = modules
            .slice(start, end)
            .filter(m => searchBarModules.includes(m.name));
        const searchModule =
            mark > 0 ? arr[arr.length - mark] : arr[Math.abs(mark) - 1];
        if (searchModule) {
            return { ...searchModule };
        } else {
            return false;
        }
    }
};

export default actions;
