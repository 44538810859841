import axios from 'axios';
import global from './global';
import importExt from './federated-share/index';
import { isSSRServer } from '@/config';
import * as loadRemote from './utils/load-remote';
import {  buildUrl, getRouteOptions } from "@/router/index.js"
import {
    utilTool,
    cmsEnv,
    getQueryField,
    setQueryField,
    inPopDetailPage,
    UploadFile,
    UploadImage,
} from './utils/index';
import { phone_regex } from "@/common/format/phone-format__US__.js";

let globalObserver;

// const IS_CHIME_URL = /^(cms-).*?chime\.me$/.test(window.location.hostname);
// const CMS_AJAX_PREFIX =
//     inCmsOrigin && !IS_CHIME_URL && !window.location.port ? '/cms-api' : '';

const util = {
    ...utilTool,
    ...loadRemote,
    ...cmsEnv,
    topFixedHeight: 0,
    mapPs: null,
    inPopDetailPage: inPopDetailPage,
    //  a simple Axios request to send ,  bypassed site inside the interceptor ,  Usually the interface to call the third party can use this
    simpleAxios: axios.create({}),
    UploadFile,
    UploadImage,
    importExt,
    CLICK: utilTool.isMobile ? 'touchstart.polyfill' : 'click',
    isMobileSize() {
        if (this.inCms) {
            return window.innerWidth <= 600 || utilTool.isMobile;
        } else {
            return utilTool.isMobile;
        }
        // return window.innerWidth <= size;
    },
    getHereMapPs() {
        if (!util.hereMapPs) {
            util.hereMapPs = Promise.all([
                axios.get("/listing-crm/here-api/systemSettings").then(res => res.data),
                // util.addScript('https://static.chimeroi.com/site/here-maps.js'),
                /**
                 * npm config set @here:registry https://repo.platform.here.com/artifactory/api/npm/maps-api-for-javascript/
                 * npm install @here/maps-api-for-javascript
                 */
                import('@here/maps-api-for-javascript/bin/mapsjs.bundle.harp.js')
            ]).then(([token, here]) => {
                window.siteAppVm.$EventBus.$emit('siteTrack', {
                    trackType: 'mapLoad'
                });
                window.H = here.default;
                // 示例代码: https://knowledge.here.com/csm_kb?id=public_kb_csm_details&number=KB0021525
                let platform = new window.H.service.Platform({
                    apikey: "-",
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                class CustomProvider extends H.map.provider.RemoteTileProvider {
                    constructor(options) {
                        super(options);
                        this.style = options.style;
                    }
                    requestInternal(x, y, level, onSuccess, onError) {
                        let url = `https://maps.hereapi.com/v3/base/mc/${level}/${x}/${y}/jpeg?lang=fr&lang2=en&style=${this.style}&size=512`;
                        const req = new XMLHttpRequest();
                        req.open("GET", url, true);
                        req.setRequestHeader('Authorization', `Bearer ${token}`);
                        req.responseType = "blob";
                        req.onload = (event) => {
                            const blob = req.response; // Note: not req.responseText
                            if (blob) {
                                const img = new Image();
                                var imgUrl = URL.createObjectURL(blob);
                                img.src = imgUrl;
                                onSuccess(img);
                            }
                        };
                        req.send(null);
                        return {
                            cancel: () => {
                                req.abort(); // Request cancel
                            }
                        }; 
                    }
                    providesRasters() {
                        return true;
                    }
                }
                function createMapLayer(style) {
                    var provider = new CustomProvider({
                        style: style,
                        engineType: window.H.Map.EngineType['HARP']
                    });
                    provider.setStyleInternal(new H.map.render.harp.Style({}));
                    return new H.map.layer.TileLayer(provider);
                }
                return {
                    platform,
                    H: window.H,
                    baseLayer: createMapLayer('explore.day'),
                    satelliteLayer: createMapLayer('explore.satellite.day')
                };
            });
        }
        return util.hereMapPs;
    },
    getMapPs() {
        if (!util.mapPs) {
            util.mapPs = util
                .addScript(
                    '//maps.googleapis.com/maps/api/js?key=' +
                        window.sitePrepareData().suggestionKey +
                        '&libraries=places'
                )
                .then(() => {
                    window.siteAppVm.$EventBus.$emit('siteTrack', {
                        trackType: 'mapLoad'
                    });
                    return window.google.maps;
                });
        }
        return util.mapPs;
    },
    /**
     *  execute function object
     * @param handler  function object
     * @param context  execution context
     *
     *  The so-called function object is that it can be a function ， can also be an object ， This object has the following properties ：
     * {
     *      handler:  function ， string （ The string is a method of the context object name）
     *      context:  the context ， optional
     *      args:  parameter ， optional
     * }
     *  Starting from the third parameter, the following parameters will be used as the execution parameters of the function object ， and placed inside the function object args later
     */
    call(handler, context) {
        if (!handler) {
            return; 
        }
        try {
            let args = [].slice.call(arguments, 2);
            if (this.isFunction(handler)) {
                return handler.apply(context, args);
            } else {
                context = handler.context || context;
                args = (handler.args || []).concat(args);
                if (
                    this.isString(handler) ||
                    this.isString((handler = handler.handler))
                ) {
                    handler = context ? context[handler] : null;
                }
                if (this.isFunction(handler)) {
                    return handler.apply(context, args);
                }
            }
        } catch (err) {
            console.error('util.call', err);
            util.error(err, {
                info: 'Callback'
            });
            return false;
        }
    },
    getQueryField: getQueryField,
    setQueryField: setQueryField,
    indexOf(arr, value, name) {
        for (let i = 0, len = arr.length; i < len; i++) {
            if (arr[i][name] === value) {
                return i; 
            }
        }
        return -1;
    },
    getObserver(targets, callback, options) {
        let observer; 
        let observerCb = function(entries) {
            for (let i = 0, len = entries.length; i < len; i++) {
                let entry = entries[i];
                if (entry.isIntersecting) {
                    let target = entry.target;
                    if (target._observeCb) {
                        target._observeCb(target);
                        target._observeCb = null;
                    }
                    observer.unobserve(target);
                }
            }
        };
        if (options) {
            observer = new IntersectionObserver(
                observerCb,
                options
            );
        } else if (!globalObserver) {
            observer = globalObserver = new IntersectionObserver(
                observerCb,
                { rootMargin: '0px' }
            );
        } else {
            observer = globalObserver;
        }

        let doObserve = function(target) {
            //1 为元素类型节点
            if (target && target.nodeType == 1) {
                target._observeCb = callback;
                observer.observe(target);
            }
        }
        if (targets.forEach) {
            targets.forEach(t => doObserve(t));
        } else {
            doObserve(targets);
        }
        
        return observer;
    },
    getStyle(el) {
        if (el.currentStyle) {
            return el.currentStyle;
        } else {
            return window.getComputedStyle(el, null);
        }
    },
    getListingTypeFromListingSource(listingSource) {
        let listingType = ''
        listingSource = listingSource?.toLowerCase() || ''
        if (listingSource === 'sold listings') {
            listingType = 'sold-listing';
        } else if (
            listingSource === 'all listings' ||
            !listingSource
        ) {
            listingType = undefined;
        } else if (
            listingSource === 'single property promotion'
        ) {
            listingType = 'single-property-promotion';
        } else {
            listingType = 'featured-listing';
        }
        return listingType
    },
    throttledRequest: () => {
        const CancelToken = axios.CancelToken;
        let source = null;
        let timer = null;
        return function(options) {
            if (source) {
                source.cancel();
            }
            if (timer) {
                clearTimeout(timer);
            }
            return new Promise((resolve, reject) => {
                timer = setTimeout(async() => {
                    source = CancelToken.source();
                    options.cancelToken = source.token;
                    if (typeof options === 'function') {
                        options = await options();
                    }
                    axios(options)
                        .then(res => resolve(res.data || res))
                        .catch(err => reject({ err, options }))
                        .finally(() => {
                            source = null;
                            timer = null;
                        });
                }, 200);
            });
        };
    },
    clickBlank: el => {
        (el || document).dispatchEvent(new Event('click'));
    },
    /**
     *  Unified Processing Timer ， Prevent the page from being filled with timers ， a messy code ， another impact on performance ， And it's not easy to troubleshoot
     * @param handler
     *   When adding a timer ，handler format is ： function or object
     * {
     *      handler:  function object ,
     *      context:  function execution context ,
     *      args:  Function execution parameters
     *      unique:  default false,  Is it unique ， Judgment is based on handler and context， Guaranteed that the same function will not create repeated timers
     *      once:  default true,  Is it only executed once
     *      onfinish:  The timer function is executed ， return value false or once for true hour ， will execute the end function ， Callback for handling recurring timers
     *      single:  This timer function will execute independently ， Not executed with other timer functions ， Timing functions for comparing time-consuming
     *      delay:  Set timer delay
     *      trigger:  Whether the timer function should be executed once immediately ， Mostly used for loop timer functions
     * }
     *
     * eg:
     * util.timeout(function(){}) =>  create timer
     * util.timeout('', true) =>  remove timer
     */
    timeout: global.timeout,
    resize: global.resize,
    scroll: global.scroll,
    winScrollTop: global.winScrollTop,
    destroy() {
        global.timeout(false);
        global.resize(false);
        global.scroll(false);
    },
    getScrollPelem(elem) {
        if (!elem || elem.nodeType !== 1 || elem === document.body) {
            return document.documentElement;
        }
        return util.getStyle(elem)['overflowY'] === 'auto'
            ? elem
            : util.getScrollPelem(elem.parentNode);
    },
    scrollLocalCount: 0,
    enableScroll(enable, reset) {
        if (util.isSSRServer) {
            return 
        }
        if (reset) {
            util.scrollLocalCount = 0;
        }

        if (enable) {
            --util.scrollLocalCount;
        } else {
            ++util.scrollLocalCount;
        }

        if (util.scrollLocalCount < 0) {
            util.scrollLocalCount = 0;
        }

        let $body = document.body;
        let pr;
        if (util.scrollLocalCount <= 0) {
            $body.classList.remove('overflow-hidden');
            pr = 0;
        } else {
            $body.classList.add('overflow-hidden');
            pr = window.innerWidth - document.body.clientWidth;
        }
        $body.style.paddingRight = pr + 'px';
        if (!utilTool.isMobile) {
            global.resize('trigger');
        }
       
    },
    scrollIntoView(ele, options) {
        ele.scrollIntoView(options);
    },
    isOnView(ele) {
        const topFixedHeight = util.topFixedHeight || 0;
        if (!ele?.getBoundingClientRect) {
            return;
        }
        const rect = ele.getBoundingClientRect();
        const winHeight = window.innerHeight;
        const eleHeight = rect.height;
        if (eleHeight === 0) {
            return false;
        }
        if ((winHeight - topFixedHeight) / 2 > eleHeight) {
            return rect.top - topFixedHeight >= 0 && rect.bottom <= winHeight;
        } else {
            return (
                rect.top <= (winHeight + topFixedHeight) / 2 &&
                rect.bottom > 0 &&
                rect.bottom > (winHeight + topFixedHeight) / 2
            );
        }
    },
    closest(ele, selector) {
        if (!ele) {
            return null; 
        }
        let parentEle = ele.parentNode;
        if (!parentEle) {
            return null; 
        }
        let selectorEles = [];
        while (!(selectorEles = parentEle.querySelectorAll(selector)).length) {
            ele = parentEle;
            parentEle = parentEle.parentNode;
            if (!parentEle) {
                return null; 
            }
        }
        if (selectorEles.length) {
            for (let i = 0, len = selectorEles.length; i < len; i++) {
                const selectorEle = selectorEles[i];
                if (ele === selectorEle) {
                    return ele;
                }
            }
            return null;
        } else {
            return null;
        }
    },
    parentVmHas(vm, key) {
        let parentVm = vm.$parent;
        let flag = false;
        while ((flag = parentVm[key]) === undefined) {
            parentVm = parentVm.$parent;
            if (!parentVm) {
                return false; 
            }
        }
        return flag;
    },
    toast(text, delay = 1500) {
        if (document) {
            let el = document.createElement('div');
            el.className = 'toast';
            el.innerHTML = text;
            document && document.body.appendChild(el);
            util.timeout({
                handler: () => {
                    document.body.removeChild(el);
                },
                delay: delay,
                unique: true
            });
        }
    },
    login(option) {
        return import('../components/sign-log').then(signLog => {
            signLog.default.login(option);
        });
    },
    register(option) {
        return import('../components/sign-log').then(signLog => {
            signLog.default.register(option);
        });
    },
    getUserActionPs() {
        if (!util.userActionPs) {
            util.userActionPs = new Promise(res => {
                const removeEvent = function() {
                    window.removeEventListener(
                        'mousemove',
                        onUserAction,
                        false
                    );
                    window.removeEventListener('touchstart', onUserAction, {
                        passive: true
                    });
                    window.removeEventListener('touchmove', onUserAction, {
                        passive: true
                    });
                    window.removeEventListener('scroll', onUserAction, false);
                    window.removeEventListener('wheel', onUserAction, {
                        passive: true
                    });
                };
                const onUserAction = function() {
                    removeEvent();
                    res();
                };
                window.addEventListener('mousemove', onUserAction, false);
                window.addEventListener('touchstart', onUserAction, {
                    passive: true
                });
                window.addEventListener('touchmove', onUserAction, {
                    passive: true
                });
                window.addEventListener('scroll', onUserAction, false);
                window.addEventListener('wheel', onUserAction, {
                    passive: true
                });
            });
        }
        return util.userActionPs;
    },
    isObjectSame(obj, compareObj, strit = false, excludeKeys = []) {
        if (strit) {
            return JSON.stringify(obj) === JSON.stringify(compareObj);
        } else {
            let flag = false;
            const keys = Object.keys(obj || {}).sort();
            const compareKeys = Object.keys(compareObj || {}).sort();
            if (JSON.stringify(keys) === JSON.stringify(compareKeys)) {
                let newFlag = true;
                keys.forEach(key => {
                    if (!excludeKeys.includes(key)) {
                        if (typeof obj[key] === 'object') {
                            if (
                                !util.isObjectSame(
                                    obj[key],
                                    compareObj[key],
                                    false,
                                    excludeKeys
                                )
                            ) {
                                newFlag = false;
                            }
                        } else {
                            if (obj[key] !== compareObj[key]) {
                                newFlag = false;
                            }
                        }
                    }
                });
                if (newFlag) {
                    flag = true;
                }
            }
            return flag;
        }
    },
    isObjectSameSafe(obj, compareObj, strit = false, excludeKeys = []) {
        try {
            return util.isObjectSame(obj, compareObj, strit, excludeKeys);
        } catch (e) {
            return false;
        }
    },
    getVisibilityChangeName() {
        let visibilityChange;
        if (typeof document.hidden !== 'undefined') {
            visibilityChange = 'visibilitychange';
        } else if (typeof document.msHidden !== 'undefined') {
            visibilityChange = 'msvisibilitychange';
        } else if (typeof document.webkitHidden !== 'undefined') {
            visibilityChange = 'webkitvisibilitychange';
        }
        return visibilityChange;
    },
    isPageHidden() {
        if ('hidden' in document) {
            return true;
        }
        var prefixes = ['webkit', 'moz', 'ms', 'o'];
        for (var i = 0; i < prefixes.length; i++) {
            if (prefixes[i] + 'Hidden' in document) {
                return true;
            }
        }
        return false;
    },
    // Calculate the four points of the rectangle
    // based on the latitude, longitude and radius
    getRectangle(lat, lon, radius = 10) {
        const distance = 0.0091 * radius;
        const tlLat = lat + distance;
        const tlLonDistance = distance / Math.cos((tlLat * Math.PI) / 180);
        const tlLon = lon + (lon > 0 ? tlLonDistance : -tlLonDistance);
        const brLat = lat - distance;
        const brLonDistance = distance / Math.cos((brLat * Math.PI) / 180);
        const brLon = lon - (lon > 0 ? brLonDistance : -brLonDistance);
        return [tlLat, tlLon, brLat, brLon];
    },
    parseJSON(str, def) {
        try {
            return JSON.parse(str);
        } catch (e) {
            console.error(e);
        }
        return def || null;
    },

    //  for  gtag  Not supported during initialization  Date  type
    initGtag() {
        let gtag = window.gtag;
        if (gtag) {
            gtag('js', new Date());
        }
    },
    transformLink2WordPress(link, wordPressSSR, noVow = false) {
        /*wordpress middle listing-detail need to remove md-header and md-footer*/
        if (wordPressSSR || utilTool.isBot) {
            // https://site6.chime.me/listing-detail/{id}/{name}
            let routeInfo = getRouteOptions({ name: 'listing-detail', url: link });
            if (routeInfo.name) {
                routeInfo.query = {};
                return buildUrl(routeInfo);
            }
            return link;
        } else {
            if (noVow) {
                return link.includes('?') && `${link}&vow=1` || `${link}?vow=0`
            }
            return link;
        }
    },
    formatMaxValue(val, type = 'max') {
        if (!val) {
            return val; 
        }
        let date = new Date(+val);
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        month = month > 9 ? `${month}` : `0${month}`;
        const day = date.getDate();
        const time = type === 'max' ? '23:59:59' : '00:00:00';
        return new Date(`${year}/${month}/${day} ${time}`).getTime() + '';
    },
    getTimeZone() {
        const zoneOffset = new Date().getTimezoneOffset() / -60;
        let zone;
        if (zoneOffset >= 0) {
            zone = zoneOffset >= 10 ? `+${zoneOffset}00` : `+0${zoneOffset}00`;
        } else {
            zone =
                zoneOffset <= -10
                    ? `-${Math.abs(zoneOffset)}00`
                    : `-0${Math.abs(zoneOffset)}00`;
        }
        return `GMT${zone}`;
    },
    transferPriceToDollar({ price, isInt, currencyRate = 1 }) {
        //  Convert current currency to base currency ， ie divided by the exchange rate
        if (!price) {
            return '';
        }
        if (isInt) {
            return parseInt(Number(price) / Number(currencyRate));
        }
        return Number(price) / Number(currencyRate);
    },
    transferDollarToCurrency({ price, isInt, currencyRate = 1 }) {
        //  Convert the base currency to the corresponding current currency ， i.e. multiplied by the exchange rate
        if (!price) {
            return '';
        }
        if (isInt) {
            return parseInt(Number(price) * Number(currencyRate));
        }
        return Number(price) * Number(currencyRate);
    },
    /**
     * Find the nearest root node with a given selector.
     * @param {HTMLElement} node - The DOM node to start the search from.
     * @param {string} selector - The element selector to match.
     * @returns {HTMLElement|null} - The matched root node or null if not found.
     */
    findRootNodeWithSelector(node, selector) {
        let currentNode = node; // Initial node is the provided DOM node
    
        // Iterate up the DOM tree until the root node is found (no parent nodes)
        while (currentNode.parentNode) {
            currentNode = currentNode.parentNode;
    
            // Check if the current node matches the selector
            if (currentNode.matches && currentNode.matches(selector)) {
                console.log('Root Node Found:', currentNode);
                // You can perform operations with the currentNode (root node) here
                return currentNode;
            }
        }
    
        console.warn('Root Node not found for selector:', selector);
        return node; // Return null or any other appropriate value if no matching root node is found
    },
    // From ChatGPT
    convertToHTMLLinks(inputString, { linkClassName = '' } = {}) {
        const isListingDetail = inputString.includes('listing-detail');
        // Regular expressions to match phone numbers, email addresses, website links, SSH links, and special case links
        const phoneRegex = new RegExp(phone_regex.region, 'g');
        const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;
        const websiteRegex = /(^|[^"]{0}|(https?:))(\/\/[^\s\(\)]+)/g;
        // Replace phone numbers with clickable links
        const stringWithPhoneLinks = inputString.replaceAll(phoneRegex, function(...params) {
            return `<a href="tel: ${params[0].replace(/[-. \(\)]/g, '')}" target="_blank"  class="${linkClassName}" rel="noopener noreferrer">${params[0]}</a>`;
        });
        // Replace email addresses with clickable links
        const stringWithEmailLinks = stringWithPhoneLinks.replace(emailRegex, `<a href="mailto:$1" target="_blank" class="${linkClassName}" rel="noopener noreferrer">$1</a>`);
        // Replace website links with clickable links
        const stringWithWebsiteLinks = stringWithEmailLinks.replace(websiteRegex, function(...params) {
            return `<a href="${params[0]}" target="_blank" class="${linkClassName}" rel="noopener noreferrer">${params[0]}</a>`
        });
        const webSiteLinks = inputString.replace(websiteRegex, function(...params) {
            return `<a href="${params[0]}" target="_blank" class="${linkClassName}" rel="noopener noreferrer">${params[0]}</a>`
        });
        
        return isListingDetail ? webSiteLinks : stringWithWebsiteLinks;
    } 
};
// window.Util = new Proxy(util, {
//     get: function (target, property) {
//         var raw = target[property];
//         if (property === 'isMobile') {
//             if (cmsEnv.inCmsPageEditor) {
//                 // cms in as long as the screen width is less than 600,  considered mobile
//                 return (
//                     raw ||
//                     (document.documentElement &&
//                         document.documentElement.clientWidth <= 600)
//                 );
//             }
//         }
//         return raw;
//     }
// });
if (!isSSRServer) {
    window.Util = util;
}

export default util;
