import { h } from 'vue';
import videos from './index.js';

export default {
    emits: ['loadPreview', 'click', 'setFacebook', 'loadedmetadata'],
    render() {
        let props = this.getVideoProps();
        let videoCom = videos.createVideoByUrl(this.src);

        return h(videoCom, {
            ref: 'videoCom',
            ...props
        });
    },
    props: {
        src: {
            type: String,
            default: ''
        },
        preview: {
            type: String,
            default: ''
        },
        autoplay: {
            type: Boolean,
            default: false
        },
        loop: {
            type: Boolean,
            default: false
        },
        muted: {
            type: Boolean,
            default: false
        },
        controls: {
            type: Boolean,
            default: false
        },
        playsinline: {
            type: Boolean,
            default: false
        },
        volume: {
            default: null
        },
        loadedmetadata: Function,
        loadeddata: Function,
        canplay: Function,
        pause: Function,
        play: Function,
        ended: Function,
        error: Function,
        seeked: Function
    },
    methods: {
        getVideoProps() {
            const emptyFun = () => {};
            let props = {
                src: this.src,
                muted: this.muted,
                playsinline: this.playsinline,
                preview: this.preview,
                onClick: () => this.$emit('click'),
                onLoadedmetadata: (videoIns) => this.$emit('loadedmetadata', videoIns),
                onLoadeddata: this.loadeddata || emptyFun,
                onCanplay: this.canplay || emptyFun,
                onPause: this.pause || emptyFun,
                onPlay: this.play || emptyFun,
                onEnded: this.ended || emptyFun,
                onSeeked: this.seeked || emptyFun,
                onError: this.error || emptyFun,
                onLoadPreview: (src) => this.$emit('loadPreview', src),
                onSetFacebook: () => this.$emit('setFacebook')
            };
            if (this.autoplay) {
                props.autoplay = 'autoplay';
            }
            if (this.loop) {
                props.loop = 'loop';
            }
            if (this.controls) {
                props.controls = 'controls';
            }
            if (this.volume) {
                props.volume = this.volume; // Add volume configuration item 
            }
            
            return props;
        }
    }
};
