<script>
import { h } from 'vue';
import util from '@/common/util';

export default {
    emits: ['hiddenItemsChanged'],
    name: 'site-wrap-box',
    props: {
        // wrap-box的宽度默认可以缩小到0
        minWidth: {
            type: Number,
            default: 0
        },
        // true表示从前往后开始隐藏, false表示从后往前开始隐藏
        hideFromBegin: { 
            type: Boolean,
            default: true
        },
        // 富余的最小间距
        spareSpacing: {
            type: Number,
            default: 10
        }
    },
    provide() {
        return {
            wrapItems: this.wrapItems
        }  
    },
    data: function() {
        return {
            wrapItems: {},
            itemIds: []
        };
    },
    render() {
        var slot = this.$slots.default ? this.$slots.default() : [];
        if (util.isArray(slot)) {
            this.checkWrapItemInSlots(slot);
        }
        return h('div', { class: 'site-wrap-box', style: `min-width:${this.minWidth}px;` }, slot);
    },
    mounted() {
        this.$nextTick(() => {
            this.refresh();
        });
        util.resize(this.refresh);
    },
    unmounted() {
        util.resize(this.refresh, true);
    },
    methods: {
        onUpdateWrapStatus: function(data) {
            if (!this.itemIds.includes(data.id)) {
                this.itemIds.push(data.id);
            }
            let target = this.wrapItems[data.id];
            if (target) {
                for (let attr in data) {
                    if (data[attr] !== target[attr]) {
                        target[attr] = data[attr];
                    }
                }
            } else {
                this.wrapItems[data.id] = data;
            }
        },
        checkWrapItemInSlots: function(slots) {
            for (let i = 0; i < slots.length; i++) {
                if (typeof slots[i].type === 'symbol' && slots[i].children && util.isArray(slots[i].children)) {
                    this.checkWrapItemInSlots(slots[i].children);
                } else if (slots[i].type.name === 'site-wrap-item') {
                    slots[i].props.onUpdateStatus = this.onUpdateWrapStatus;
                }
            }
        },
        refresh: function() {
            let lastVisibleChild = this.findLastVisibleChild();
            if (lastVisibleChild) {
                let distence = this.$el.offsetLeft + this.$el.clientWidth - (lastVisibleChild.offsetLeft + lastVisibleChild.clientWidth);
                if (this.handlerWrapTargets(distence)) {
                    let hiddenItmes = [];
                    for (let i = 0; i < this.itemIds.length; i++) {
                        let target = this.wrapItems[this.itemIds[i]];
                        if (target.hidden) {
                            hiddenItmes.push(target.dataItem);
                        }
                    }
                    this.$emit('hiddenItemsChanged', hiddenItmes);
                }
            }
        },
        findLastVisibleChild: function() {
            for (let i = this.$el.children.length - 1; i >= 0; i--) {
                let child = this.$el.children[i];
                if (getComputedStyle(child)['display'] !== 'none') {
                    return child;
                }
            }
            return this.$el.children[this.$el.children.length - 1];
        },
        handlerWrapTargets: function(distence) {
            // console.log(`----------distence: ${distence}-----------`);
            let ids = this.itemIds;
            if (!this.hideFromBegin) {
                ids = this.itemIds.reverse();
            }
            let forAdd = distence > this.spareSpacing;
            let changed = false;
            for (let i = 0; i < ids.length; i++) {
                let id = ids[i];
                /* 检查添加item */
                if (forAdd && this.wrapItems[id]?.hidden) {
                    let target = this.wrapItems[id];
                    if (target && target.width + this.spareSpacing < distence) {
                        target.hidden = false;
                        distence -= target.width;
                        changed = true;
                        // console.log(`show:${id},width:${target.width},distence:${distence}`);
                    } else {
                        break;
                    }
                } /* 检查隐藏item */ else if (!forAdd && !this.wrapItems[id]?.hidden) {
                    let target = this.wrapItems[id];
                    if (target && 0 < -distence + this.spareSpacing) {
                        target.hidden = true;
                        distence += target.width;
                        changed = true;
                        // console.log(`hide:${id},width:${target.width},distence:${distence}`);
                    } else {
                        break;
                    }
                }
            }
            return changed;
            // console.log(`----------distence: ${'end'}-----------`);
        }
    }
};
</script>

<style lang="scss">
    .site-wrap-box{
        display: flex;
    }
</style>
