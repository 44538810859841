<template>
    <div class="im-select" @click="toggle" ref="wrapDiv" >
        <slot name="place" v-bind:show="!visible">
            <im-input
                :ref="onRefInput"
                :readonly="true"
                customInputClass="customInputClass"
                class="ct-select"
                :theme="theme"
            >
                <template v-slot:prepend>
                    <div class="labels" :class="labelClass">
                        <div class="ellipse label-pd">
                            {{ label || placeholder }}
                        </div>
                    </div>
                </template>
                <template v-slot:append>
                    <div class="drop_icon">
                        <span
                            class="iconfont"
                            style="marginright: 0"
                            :class="[iconClass, dropdownIcon]"
                        ></span>
                    </div>
                </template>
            </im-input>
        </slot>
        <transition name="zoom-in-top">
            <im-select-menu v-show="visible" v-outsideclick="handleOutside" :dropStyle="dropStyle">
                <div
                    v-if="filterable"
                    class="searchInput"
                    :class="searchClass"
                >
                    <span class="searchIcon iconfont icon-search-bold"></span>
                    <input
                        class="input"
                        v-model="inputVal"
                        ref="searchInput"
                        type="text"
                        style="border: none; height: auto"
                        @keyup.enter="handleKeyUp"
                    />
                </div>
                <div class="help-item" v-show="loading">
                    {{ loadingText || $t('common:LoadingEllipsis') }}
                </div>
                <div class="help-item" v-show="!hasData && !isSuggestion">
                    {{ noDataText || $t('common:NoData') }}
                </div>
                <slot></slot>
            </im-select-menu>
        </transition>
    </div>
</template>

<script>
import ImInput from '@imComponent/input/input';
import SelectDropdown from './select-dropdown';
import classNames from 'classnames';
// import Tag from '../tag/tag';
import util from '@/common/util';
// import { COMMA_SEPARATOR } from '../../../commom/constants';
export const COMMA_SEPARATOR = ', ';

var valueChange = function(newVal, oldVal) {
    this.valueChanged = true;
    if (this.valueEquals(newVal, oldVal)) {
        return;
    }
    let result = [];
    if (Array.isArray(this.value)) {
        this.value.forEach(value => {
            const option = this.getOption(value);
            if (option) {
                result.push(option);
            }
        });
    }
    this.selectedOptions = result;
};

export default {
    name: 'im-select',
    componentName: 'im-select',
    emits: ['update:value'],
    props: {
        theme: {
            validator: value =>
                ['standard', 'template1', 'template4', 'broker'].indexOf(
                    value
                ) !== -1,
            default: 'standard'
        },
        value: {
            type: Array,
            required: true
        },
        valueKey: { default: 'value' },
        placeholder: { type: String, default: '' },
        clearable: { type: Boolean, default: true },
        multiple: {
            type: Boolean,
            default: false
        },
        filterable: {
            type: Boolean,
            default: false
        },
        filterMethod: {
            type: Function,
            default: () => {}
        },
        remote: {
            type: Boolean,
            default: false
        },
        remoteMethod: {
            type: Function,
            default: () => {}
        },
        loading: {
            type: Boolean,
            default: false
        },
        loadingText: {
            type: String,
            default: ''
        },
        noDataText: {
            type: String,
            default: ''
        },
        defaultFirstOption: {
            type: Boolean,
            default: false
        },
        // When inputType is suggestion, special processing logic when pressing enter
        // Business related
        isSuggestion: {
            type: Boolean,
            default: false
        },
        isExact: {
            type: Boolean,
            default: false
        },
        dropdownIcon: {
            type: String,
            default: 'icon-arrow-down'
        }
    },
    computed: {
        labelClass() {
            return classNames(this.theme);
        },
        searchClass() {
            return classNames(this.theme);
        },
        iconClass() {
            return classNames({ rotate: this.visible });
        },
        // Labels of selected options
        selectedLabel() {
            if (!this.isSuggestion) {
                return this.selectedOptions.map(o => o.currentLabel);
            } else {
                return this.value.map(v => v.split(':')[1] || v);
            }
        },
        hasData() {
            return this.options.length > 0;
        },
        label() {
            return (
                (this.selectedLabel &&
                    this.selectedLabel.length > 0 &&
                    this.selectedLabel.join(COMMA_SEPARATOR)) ||
                this.placeholder
            );
        }
    },
    data: function() {
        return {
            visible: false,
            options: [],
            cachedOptions: [],
            iptFocus: false,
            iptPlaceholder: 'select',
            inputVal: '',
            refInput: null,
            selectedOptions: [],
            dropStyle: "drop_left"
        };
    },
    watch: {
        inputVal(val) {
            if (this.remote) {
                this.debounceRemoteMethod(val);
            } else if (this.filterable) {
                this.filterMethod(val);
            }
        },
        visible(val) {
            if (!val && this.filterable) {
                this.inputVal = '';
            }
        },
        value: {
            handler: function(newVal, oldVal) {
                let setTO = setTimeout || window.setTimeout;
                this.valueChanged
                    ? valueChange.call(this, newVal, oldVal)
                    : setTO(
                        valueChange.bind(this, newVal, oldVal),
                        1000
                    );
            },
            immediate: true,
            deep: true
        }
    },
    provide() {
        return {
            theme: this.theme,
            select: this
        };
    },
    created() {
        this.debounceRemoteMethod = util.debounce(this.remoteMethod).bind(this);
    },
    components: {
        'im-input': ImInput,
        'im-select-menu': SelectDropdown
    },
    methods: {
        onRefInput(e) {
            this.refInput = e;
        },
        handleKeyUp() {
            if (this.loading) {
                return; 
            }
            if (this.isSuggestion) {
                // this.debounceRemoteMethod(this.inputVal);
                if (this.isExact && this.options.length > 0) {
                    const opt = this.options[0];
                    // already select
                    if (this.value.includes(opt.value)) {
                        return; 
                    }
                    if (!opt.disabled) {
                        this.handleOptionClick(opt);
                        // opt.dispatch('im-select', 'optionClick', opt);
                    }
                } else {
                    const value = this.value || [];
                    if (!this.inputVal) {
                        return; 
                    }
                    value.push(`none:${this.inputVal}`);
                    this.emitChange(value);
                }
            }
        },
        // insert children option instance when option created
        insertOption(optionIns) {
            this.options.push(optionIns);
            const cachedIndex = this.cachedOptions.indexOf(optionIns);
            if (cachedIndex < 0) {
                this.cachedOptions.push(optionIns);
            }
        },
        // remove children option instance when option destroy
        removeOption(optionIns) {
            const index = this.options.indexOf(optionIns);
            if (index > -1) {
                this.options.splice(index, 1); 
            }

            const selectedIndex = this.selectedOptions.indexOf(optionIns);
            const cachedIndex = this.cachedOptions.indexOf(optionIns);
            if (selectedIndex < 0 && cachedIndex > -1) {
                this.cachedOptions.splice(cachedIndex, 1);
            }
        },
        // Options Clicked callback
        handleOptionClick(option) {
            if (this.multiple) {
                let value = (this.value || []).slice();
                if (this.getTagKey(option.value) === '') {
                    value = [];
                } else {
                    value = value.filter(v => this.getTagKey(v) !== '');
                }
                const optionIndex = this.getValueIndex(value, option.value);
                if (optionIndex > -1) {
                    value.splice(optionIndex, 1);
                } else {
                    value.push(option.value);
                }
                this.emitChange(value);
                if (this.filterable && this.refInput) {
                    this.refInput.focus(); 
                }
            } else {
                this.emitChange([option.value]);
                this.hide();
            }
        },
        // delTag
        delTag(optionIns) {
            console.log(optionIns);
            this.handleOptionClick(optionIns);
            // optionIns.dispatch('im-select', 'optionClick', optionIns);
        },
        getOption(value) {
            let option;
            const isObject =
                Object.prototype.toString.call(value).toLowerCase() ===
                '[object object]';
            for (let i = this.cachedOptions.length - 1; i >= 0; i--) {
                const cachedOption = this.cachedOptions[i];
                const isEqual = isObject
                    ? this.getValueByPath(cachedOption.value, this.valueKey) ===
                      this.getValueByPath(value, this.valueKey)
                    : cachedOption.value === value;
                if (isEqual) {
                    option = cachedOption;
                    break;
                }
            }
            if (option) {
                return option; 
            }
        },
        emitChange(val) {
            if (!this.valueEquals(this.value, val)) {
                this.$emit('update:value', val);
                // this.$emit('input', val);
                // this.$emit('valueChange', val);
            }
        },
        //  click to show
        toggle(e) {
            e.outSideIgnore = this.handleOutside;
            if (e.target === this.$refs.searchInput) {
                return;
            }
            this.visible = !this.visible;
            this.$emit('toggle', this.visible);
            if (this.visible) {
                if (this.$refs.wrapDiv) {
                    let rect = this.$refs.wrapDiv.getBoundingClientRect();
                    if (rect.left + 300 > window.innerWidth) {
                        this.dropStyle = "drop_right";
                    } else {
                        this.dropStyle = "drop_left";
                    }
                }
            }
        },
        //  click to hide
        hide() {
            this.visible = false;
        },
        // out side click
        handleOutside() {
            this.hide();
        },
        // Can be proposed as a public method
        // Compare ordinary type and two arrays for equality
        valueEquals(a, b) {
            if (a === b) {
                return true; 
            }
            if (!Array.isArray(a) || !Array.isArray(b)) {
                return false; 
            }
            if (a.length !== b.length) {
                return false; 
            }
            for (let i = 0, len = a.length; i < len; i++) {
                if (a[i] !== b[i]) {
                    return false; 
                }
            }
            return true;
        },
        getValueIndex(arr = [], value) {
            const isObject =
                Object.prototype.toString.call(value).toLowerCase() ===
                '[object object]';
            if (!isObject) {
                return arr.indexOf(value);
            } else {
                const valueKey = this.valueKey;
                let index = -1;
                arr.some((item, i) => {
                    if (
                        this.getValueByPath(item, valueKey) ===
                        this.getValueByPath(value, valueKey)
                    ) {
                        index = i;
                        return true;
                    }
                    return false;
                });
                return index;
            }
        },
        getValueByPath(obj, path) {
            return path.split('.').reduce((obj, key) => obj[key], obj);
        },
        getTagKey(value) {
            const isObject =
                Object.prototype.toString.call(value).toLowerCase() ===
                '[object object]';
            if (!isObject) {
                return value;
            }
            return this.getValueByPath(value, this.valueKey);
        }
    }
};
</script>

<style lang="scss">
.im-select {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    .ct-select {
        .drop_icon {
            display: flex;
            align-content: center;
            justify-content: center;
            margin-right: 10px;
            @media screen and (max-width: 800px) {
                padding-right: 17px;
            }
            .iconfont {
                transition: transform 0.3s;
                &.rotate {
                    transform: rotate(180deg);
                }
            }
        }
        .labels {
            display: flex;
            justify-content: flex-start;
            overflow: hidden;
            max-width: 100%;
            .label-pd {
                padding-left: 10px;
            }
            .ellipse {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        &.standard {
            .iconfont {
                font-size: 16px;
                color: #6b7282;
            }
        }
        &.template4 {
            .iconfont {
                font-size: 18px;
                color: #8b93a7;
                font-weight: bold;
            }
        }
        &.template1 {
            .iconfont {
                color: #6b7282;
            }
        }
        &.broker {
            .iconfont {
                color: #6b7282;
            }
        }
    }
    // search
    .searchInput {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #e2e4ea;
        font-size: 16px;
        margin-bottom: 5px;
        &.standard {
            border-bottom-color: #e2e4ea;
            .searchIcon {
                font-size: inherit;
                color: #c6c8d1;
            }
        }
        &.template1 {
            border-bottom-color: #dcdcdc;
            .searchIcon {
                font-size: inherit;
                color: #505050;
            }
        }
        &.template4 {
            border-bottom-color: #e2e4ea;
            .searchIcon {
                font-size: inherit;
                color: #6b7282;
            }
        }

        .input {
            flex: 1 0 0;
            border: none;
            box-shadow: none;
            height: 100%;
            padding-left: 5px;
        }
    }
    .help-item {
        padding: 0;
        height: 40px;
        line-height: 40px;
        display: flex;
    }
    .customInputClass {
        cursor: pointer;
        &[readonly] {
            color: var(--color-text);
        }
    }
}

// dropdown  animation
$--im-fade-transition: transform 300ms cubic-bezier(0.23, 1, 0.32, 1),
    opacity 300ms cubic-bezier(0.23, 1, 0.32, 1) !default;
.zoom-in-top-enter {
    opacity: 0;
    transform: scaleY(0);
}
.zoom-in-top-enter-active,
.zoom-in-top-leave-active {
    transform: scaleY(1);
    opacity: 1;
    transform-origin: center top;
    transition: $--im-fade-transition;
}
.zoom-in-top-leave-active {
    transform: scaleY(0);
    opacity: 0;
}
/*  animation  */
$--im-fade-transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1),
    opacity 500ms cubic-bezier(0.23, 1, 0.32, 1) !default;
.fade-enter {
    opacity: 0;
    transform: scale(0, 0);
}
.fade-enter-active,
.fade-leave-active {
    opacity: 1;
    transform: scale(1, 1);
    transform-origin: center center;
    transition: $--im-fade-transition;
}
.fade-leave-active {
    opacity: 0;
    transform: scale(0, 0);
}
</style>
