import routers from  "lofty_region/site-route-config.js"


const ROUTE_MAP = routers.reduce((prev, curr) => {
    prev[curr.name] = curr;
    return prev;
}, {});

/**
 * 
 * @param {name: string, query:Object, params: Object} options 
 * @returns 
 */
export const buildUrl = (options = {}) => {
    let target = ROUTE_MAP[options.name];
    if (!target) {
        console.error("buildUrl error: ", options);
        target = {};
    }
    let query = options.query || {};
    let params = options.params || {};
    let url = target.url || '/';
    // 替换 URL 中的参数
    Object.keys(params).forEach(key => {
        url = url.replace(`:${key}`, encodeURIComponent(params[key]));
    });
    url = url.replaceAll('/:', '/');
    // 构建查询字符串
    let queryList = [];
    for (let key in query) {
        if (query.hasOwnProperty(key)) {
            let value = query[key];
            if (value && typeof value !== 'string') {
                value = JSON.stringify(value);
            }
            if (options.encode !== false) {
                value = encodeURIComponent(value);
            } 
            queryList.push(`${key}=${value}`)
        }
    }
    const queryString = queryList.join("&")
    url = queryString ? `${url}?${queryString}` : url;
    return  url
}

/**
 * 判断是否是某个页面， 例如： isUrl({ name:"listing", url: window.location.pathname })
 * @param {name: string, url:string} options 
 */
export const isUrl = (options) => {
    let target = ROUTE_MAP[options.name];
    if (!target) {
        console.error("isUrl error: ", options);
        return false;
    }
    let template = target.url;
    const regexPattern = template.replace(/:\w+/g, "([^/]+)");
    const regex = new RegExp(`^${regexPattern}$`); // 确保整个路径匹配
    let url = options.url || '';
    url = url.split('?')[0] || '';
    if (url.length > 1 && url[url.length - 1] == '/') {
        url = url.slice(0, -1);
    }
    return regex.test(url);
}

/**
 * 判断是否是某个页面， 例如： isUrl({ names:["listing"], url: window.location.pathname })
 * @param {names: Array<string>, url:string} options 
 */
export const isUrls = (options) => {
    let names = options.names || [];
    let url = options.url || '/';
    for (let i = 0; i < names.length; i++) {
        if (isUrl({ name: names[i], url })) {
            return true;
        }
    }
    return false;
}



/**
 * 从 url 反向提取参数
 * @param {name:String,url: String } param0 
 * @returns 
 */
export const getRouteOptions = function(options) {
    if (!isUrl(options)) {
        return {
            params: {},
            query: {}
        }
    }
    let target = ROUTE_MAP[options.name];
    if (!target) {
        console.error("buildUrl error: ", options);
        return {
            params: {},
            query: {}
        }
    }

    let pattern = target.url;
    let link = options.url;
    if (!link.startsWith('http')) {
        link = 'http://lofty.com' + (link.startsWith('/') ? '' : '/') + link;
    }
    
    const url = new URL(link);
    const pathSegments = url.pathname.split('/').filter(Boolean);
    const patternSegments = pattern.split('/').filter(Boolean);
    
    let params = {};
    let query = {};
    
    // params
    if (pathSegments.length === patternSegments.length) {
        patternSegments.forEach((segment, index) => {
            if (segment.startsWith(':')) {
                const key = segment.slice(1);
                params[key] = decodeURIComponent(pathSegments[index]);
            }
        });
    }
    
    // query
    url.searchParams.forEach((value, key) => {
        query[key] = value;
    });
    

    return {
        name: target.name,  // is matched 
        params,
        query
    }
}