import isomorphic from './isomorphic';
import asyncData from './asyncData';
import eventBus from './eventBus';
import util from '../common/util';
import formatFontSize from './formatFontSize';
import { initFormatFilter } from '../common/format';
import directive from '../common/directive';
import GlobalComponent from '../components/global';
import globalization from './globalization';
import globalStore from './globalStore';
import moduleStore from './moduleStore';
import moduleCache from './moduleCache';
import { getStore } from '../store';

export default {
    install: function(
        app,
        { pinia, globalData, pageData, stateValue, platform, globalizationIns }
    ) {
        app.use(isomorphic, platform);
        app.use(moduleCache);
        app.use(moduleStore);
        app.use(globalStore, {
            pinia,
            globalData,
            pageData,
            stateValue,
            platform,
            globalizationIns
        });

        app.use(globalization, globalizationIns);
        app.use(asyncData);
        app.use(eventBus);
        app.use(formatFontSize, globalData.themeRootVar);
        app.use(initFormatFilter);
        app.use(directive);

        // app.use(moduleStore);

        GlobalComponent(app);

        // app.config.warnHandler = (err, vm, info) => {
        //     console.warn(err);
        // };
        // app.config.errorHandler = (err, vm, info) => {
        //     console.error(err);
        // };
        app.config.devtools = process.env.NODE_ENV !== 'production';
        return app;
    },
    // 弹框或者需要挂载到body上的小部件, 可以注册这些基本的plugin, 这个方法只可能在客户端调用, SSR不调
    baseInstall: function(app, needStore, selector = 'body') {
        let pageStore = getStore('page');
        app.use(isomorphic);
        if (needStore) {
            app.use(window.siteAppVm.$pinia);
        }
        app.use(eventBus);
        app.use(formatFontSize, pageStore.page.themeRootVar);
        app.use(initFormatFilter);
        app.use(directive);
        app.use(globalization, window.siteGlobalization);

        var parent = selector;
        if (util.isString(selector)) {
            parent = document.querySelector(selector);
        }

        GlobalComponent(app);
        var node;

        app.mountSelf = function() {
            node = document.createElement('div');
            parent.appendChild(node);
            app.ins = app.mount(node);
            return app.ins;
        };
        app.unmountSelf = function() {
            app.unmount();
            app.ins = null;
            if (node) {
                parent.removeChild(node);
            }
        };
        return app;
    }
};
