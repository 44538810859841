import { isSSRServer } from '@/config';
import { findStore } from './helpers';
import useBuildingStore from './modules/building';
import useChatStore from './modules/chat';
import useCommonStore from './modules/common';
import usePageStore from './modules/page';
import useTrackStore from './modules/track';
import useUserStore from './modules/user';

let useStoreMap = {
    building: useBuildingStore,
    chat: useChatStore,
    common: useCommonStore,
    page: usePageStore,
    track: useTrackStore,
    user: useUserStore
}

export const getStore = function(storeName, pinia) {
    pinia = pinia || this?.$pinia || window.$pinia;
    return findStore(pinia, storeName, key => useStoreMap[key]);
}

if (!isSSRServer) {
    // siteStore.state.page 要改成 getStore('page')
    window.getStore = getStore;
}

export { useBuildingStore, useChatStore, useCommonStore, usePageStore, useTrackStore, useUserStore }

