import global from '@/common/global';
export default Vue => {
    /**
     * sticky  for ceiling elements
     * @params { class }  When the top of the original position of the element reaches the top of the viewport , Additional class, on the contrary , remove this class.
     * eg: v-sticky={class:'fixed'} .fixed{position:fixed;top:0;left:0;}
     *  When the top of the element's original position reaches the top of the viewport ， elements will suck ， on the contrary ， The element returns to its original position
     */
    function mounted(el, binding) {
        if (binding.value) {
            let cls = binding.value.class;
            //  leave a place ， Used to determine whether the position of the original element exceeds the viewport
            let holder = document.createElement('div');
            holder.style.height = `0px`;
            holder.style.border = 'none';
            el.before(holder);
            el.holder = holder;
            let initClassName = el.className; //  Save the style of the original element
            el.__vueScrollVisible__ = function() {
                //  If the element has been capped   Then judge whether it exceeds the viewport according to the position element
                let elem = el.isSticky ? el.holder : el,
                    res = elem.getBoundingClientRect();
                try {
                    //  The top of the element reaches the top of the viewport
                    if (res.top < 0) {
                        el.holder.style.paddingTop = el.offsetHeight + 'px';
                        el.holder.className = initClassName;
                        el.classList.add(cls);
                        el.isSticky = true;
                    } else {
                        el.holder.style.paddingTop = '0px';
                        el.holder.className = '';
                        el.classList.remove(cls);
                        el.isSticky = false;
                    }
                } catch (e) {}
            };
            global.scroll(el.__vueScrollVisible__);
        }
    }
    Vue.directive('sticky', {
        mounted,
        unmounted(el, binding) {
            if (binding.value) {
                global.scroll(el.__vueScrollVisible__, true);
                delete el.__vueScrollVisible__;
            }
        }
        // update(el, binding) {
        //     el.holder && el.holder?.remove?.() && (el.holder = null)
        //     if (el.__vueScrollVisible__) {
        //         global.scroll(el.__vueScrollVisible__, true);
        //         delete el.__vueScrollVisible__;
        //     }
        //     mounted(el, binding)
        // },
    });
};
