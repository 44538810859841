const reg_youtube_link = /(www\.)?youtube\.com\/watch\?.*v=([^&]+)/;
const reg_youtube_share_link = /youtu\.be\/([^\/\?]+)/;
const reg_youtube_embed_link = /(www\.)?youtube\.com\/embed\/([^\/\?]+)/;
const reg_youtube_short_link = /(www\.)?youtube\.com\/shorts\/([^\/\?]+)/;

const reg_vimeo_link = /https?\:\/\/vimeo\.com\/([^?]+)\?*.*/;
const reg_vimeo_embed_link = /player\.vimeo\.com\/video\/([^?]+)\?*.*/;

const reg_facebook_link =
    /(https|http)\:\/\/www\.facebook\.com.*\/videos\/([^?^\/]+).*/;
const reg_facebook_share_link = /(https|http)\:\/\/fb\.watch\/([^?^\/]+).*/;
const reg_facebook_watch_link =
    /(https|http)\:\/\/www\.facebook\.com.*\/watch.*v\=([^?^\/^&]+).*/;

export default {
    getYoutubeId(src) {
        let videoId = '';
        if (reg_youtube_link.test(src)) {
            videoId = src.match(reg_youtube_link)[2];
        }
        if (reg_youtube_share_link.test(src)) {
            videoId = src.match(reg_youtube_share_link)[1];
        }
        if (reg_youtube_embed_link.test(src)) {
            videoId = src.match(reg_youtube_embed_link)[2];
        }
        if (reg_youtube_short_link.test(src)) {
            videoId = src.match(reg_youtube_short_link)[2];
        }

        return videoId;
    },
    getVimeoId(src) {
        let videoId = '';
        if (reg_vimeo_link.test(src)) {
            videoId = src.match(reg_vimeo_link)[1];
        }

        if (reg_vimeo_embed_link.test(src)) {
            videoId = src.match(reg_vimeo_embed_link)[1];
        }

        return videoId;
    },
    getFacebookId(src) {
        let videoId = '';
        if (
            reg_facebook_link.test(src) &&
            src.indexOf('www.facebook.com/plugins/video.php') == -1
        ) {
            videoId = src.match(reg_facebook_link)[2];
        }

        if (reg_facebook_share_link.test(src)) {
            videoId = src.match(reg_facebook_share_link)[2];
        }
        
        if (reg_facebook_watch_link.test(src)) {
            videoId = src.match(reg_facebook_watch_link)[2];
        }
        return videoId;
    },
    facebookIframeLink(src) {
        let decodeUrl = decodeURIComponent(src);
        return (
            reg_facebook_link.test(decodeUrl) &&
            decodeUrl.indexOf('www.facebook.com/plugins/video.php') !== -1
        );
    }
};
