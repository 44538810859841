export const leadTypes = {
    Buyer: '2',
    Seller: '1',
    Renter: '5',
    Investor: '6',
    Agent: '7',
    Homeowner: '8',
    landlord: '9',
    Other: '-1',
}
export const roleNameMap = {
    '2': 'Buyer',
    '1': 'Seller',
    '5': 'Renter',
    '6': 'Investor',
    '7': 'Agent',
    '8': 'Homeowner',
    '9': 'landlord',
    '-1': 'Other'
}

export const getLeadTypeLabel = (role) => {
    let roles = role?.toString().split(',') || [];
    let arr = [];
    roles.forEach(role => {
        for (let key in leadTypes) {
            if (role === leadTypes[key]) {
                arr.push(key);
            }
        }
    })
    return arr.join(',');
}

export const isSeller = function(role) {
    let roles = role?.toString().split(',');
    
    return !roles?.includes(leadTypes.Buyer) && roles?.includes(leadTypes.Seller + '');
}

export const isBuyer = function(role) {
    let roles = role?.toString().split(',');
    return roles?.includes(leadTypes.Buyer + '');
}

export const isAgent = function(role) {
    return role == leadTypes.Agent;
}