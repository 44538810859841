import types from '../type';
import { isSSRServer } from '@/config';
/**
 * Iterate over an Array or an Object invoking a function for each item.
 *
 * If `obj` is an Array callback will be called passing
 * the value, index, and complete array for each item.
 *
 * If 'obj' is an Object callback will be called passing
 * the value, key, and complete object for each property.
 *
 * @param {Object|Array} obj The object to iterate
 * @param {Function} fn The callback to invoke for each item
 */
function forEach(obj, fn) {
    // Don't bother if no value provided
    if (obj === null || typeof obj === 'undefined') {
        return;
    }

    // Force an array if not already something iterable
    if (typeof obj !== 'object') {
        /*eslint no-param-reassign:0*/
        obj = [obj];
    }

    if (types.isArray(obj)) {
        // Iterate over array values
        for (var i = 0, l = obj.length; i < l; i++) {
            // eslint-disable-next-line no-useless-call
            fn.call(null, obj[i], i, obj);
        }
    } else {
        // Iterate over object keys
        for (var key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                // eslint-disable-next-line no-useless-call
                fn.call(null, obj[key], key, obj);
            }
        }
    }
}

function encode(val) {
    return encodeURIComponent(val)
        .replace(/%3A/gi, ':')
        .replace(/%24/g, '$')
        .replace(/%2C/gi, ',')
        .replace(/%5B/gi, '[')
        .replace(/%5D/gi, ']');
}
function commonSerializer(params, options = {}) {
    let serializedParams;
    var parts = [];

    forEach(params, function serialize(val, key) {
        if (val === null || typeof val === 'undefined') {
            return;
        }
        if (types.isArray(val)) {
            key = key + (options.arraySameKey ? '' : '[]');
        } else {
            val = [val];
        }

        forEach(val, function parseValue(v) {
            if (types.isDate(v)) {
                v = v.toISOString();
            } else if (types.isObject(v)) {
                v = JSON.stringify(v);
            }
            parts.push(encode(key) + '=' + encode(v));
        });
    });

    serializedParams = parts.join('&');
    return serializedParams;
}

function parseQuery(query) {
    query = query.replace(/\+/g, ' ');
    return query.split('&').reduce((prev, cur) => {
        let [key, value] = cur.split('=');
        try {
            key = decodeURIComponent(key);
            if (!key || value === 'null') {
                return prev; 
            }
            if (value) {
                value = decodeURIComponent(value);
            } else {
                value = '';
            }
            prev[key] = value;
        } catch (e) {
            console.log(key, value);
            console.error(e);
        }
        return prev;
    }, {});
}
export function decodeURIComponentSafe(key, defaultValue = '') {
    try {
        return decodeURIComponent(key);
    } catch (e) {
        console.error('decodeURIComponent', e);
    }
    return defaultValue;
}
/**
 *  Get address bar query parameters 
 * @param name  parameter name ,  do not pass return all 
 * @param url url,  does not fetch the current window.location.search
 */
export function getQueryField(name, url = '') {
    if (!isSSRServer) {
        url = url || window.location.search;
    }
    url = url.substr(url.indexOf('?') + 1);
    if (name) {
        const reg = new RegExp(
            '(^|&)' + decodeURIComponentSafe(name) + '=([^&]*)(&|$)'
        );
        const r = url.match(reg);
        return r !== null ? decodeURIComponentSafe(r[2].replace(/\+/g, ' ')) : '';
    } else {
        let map = {};
        if (url) {
            map = parseQuery(url);
        }
        return map;
    }
}

let queryKeyWhiteList = ['global_domain', 'chime-format'];
export function setQueryField(fields, url, replace, encode = true) {
    if (url === true) {
        replace = true;
        url = window.location.href;
    } else if (!url) {
        url = '';
        replace = false;
    }
    if (fields) {
        let param = '';
        for (let field in fields) {
            if (queryKeyWhiteList.includes(field)) {
                continue 
            }
            if (fields.hasOwnProperty(field)) {
                let value = fields[field];
                //  Convert the data type ， Prevent the belt from being lost 
                if (value && typeof value !== 'string') {
                    value = JSON.stringify(value); 
                }
                if (encode) {
                    field = encodeURIComponent(field);
                }
                if (!value) {
                    url = url.replace(
                        new RegExp(
                            '([?&]' +
                            field +
                            '=[^&]+$)|((?![?&])' +
                            field +
                            '=[^&]+&)'
                        ),
                        ''
                    );
                } else {
                    if (encode) {
                        value = encodeURIComponent(value);
                    }
                    const reg = new RegExp('([?&]' + field + '=)([^&]+)');
                    if (reg.test(url)) {
                        url = url.replace(reg, '$1' + value);
                    } else {
                        param = param + '&' + field + '=' + value;
                    }
                }
            }
        }
        url = param
            ? url + (url.indexOf('?') === -1 ? '?' : '&') + param.substr(1)
            : url;
    }
    if (replace) {
        window.history.replaceState(null, null, url);
    }
    return url;
}
export function paramsSerializer(params) {
    return commonSerializer(params);
}
export function paramsSerializerV2(params) {
    return commonSerializer(params, { arraySameKey: true });
}
