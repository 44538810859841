(function(win) {
    let serverUrl = 'https://ren-debug.chime.me/event';
    let APPKEY = '40ea8096-ec4d-4a91-9b76-0a790c63b6ea';
    let isOn = false;
    function isString(obj) {
        return Object.prototype.toString.call(obj) === '[object String]';
    }
    function isObject(obj) {
        return Object.prototype.toString.call(obj) === '[object Object]';
    }
    function isFunction(obj) {
        return Object.prototype.toString.call(obj) === '[object Function]';
    }
    function deepCopy(obj) {
        var result = Array.isArray(obj) ? [] : {};
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (isObject(obj[key]) === 'object') {
                    result[key] = deepCopy(obj[key]); // recursive copy 
                } else if (isFunction(obj[key])) {
                    continue;
                } else {
                    result[key] = obj[key];
                }
            }
        }
        return result;
    }
    function simpleCopy(obj) {
        // Prevent too many parameters from causing url too long 
        if (typeof obj != 'object') {
            return obj;
        }
        let objIsArray,
            item,
            result = (objIsArray = Array.isArray(obj)) ? [] : {};
        for (var k in obj) {
            if (!obj.hasOwnProperty(k) || isFunction((item = obj[k]))) {
                continue;
            }
            if (typeof item != 'object') {
                result[k] = item;
                continue;
            }
            result[k] =
                objIsArray && k > 0
                    ? Array.isArray(item)
                        ? []
                        : {}
                    : simpleCopy(item);
        }
        return result;
    }

    /**
     * uncaught JS error or  console.error
     */
    const onerrorFn = function(
        message,
        fileName,
        lineNumber,
        columnNumber,
        error
    ) {
        if (columnNumber === undefined && win.event) {
            columnNumber = win.event.errorCharacter;
        }
        fileName = fileName || win.location.href;
        let errorInfo = {
            message,
            lineNumber,
            columnNumber,
            fileName,
            name: 'Uncaught Error',
            stacktrace:
                (error && error.stack) ||
                (function() {
                    let e,
                        t,
                        r = [];
                    try {
                        // eslint-disable-next-line no-caller
                        t = arguments.callee.caller.caller;
                    } catch (i) {
                        t = '';
                    }
                    for (; t && r.length < 10;) {
                        let n = t
                            .toString()
                            .match(/function\s*([\w\_$]+)?\s*\(/i);
                        (e = (n && n[1]) || '[anonymous]'),
                        r.push(e),
                        (t = t.caller);
                    }
                    return 'generated-stack:' + r.join('\n');
                })(),
            severity: 'Error',
            type: 'Uncaught'
        };
        sendError('Uncaught', errorInfo);
    };

    /**
     * ResourceError
     */
    const errorListenerCallback = function(event) {
        let target = event.target ? event.target : event.srcElement;
        let outerHTML = target && target.outerHTML;
        outerHTML =
            outerHTML && outerHTML.length > 200
                ? outerHTML.slice(0, 200)
                : outerHTML;
        let errorInfo = {
            target: {
                outerHTML,
                src: target && target.src,
                tagName: target && target.tagName,
                id: target && target.id,
                name: target && target.name,
                className: target && target.className,
                type: target && target.type,
                XPath: (function(e) {
                    let t = [];
                    for (
                        ;
                        e && e.nodeType == Node.ELEMENT_NODE;
                        e = e.parentNode
                    ) {
                        let r,
                            n = 0,
                            flag = false;
                        for (r = e.previousSibling; r; r = r.previousSibling) {
                            r.nodeType != Node.DOCUMENT_TYPE_NODE &&
                                r.nodeName == e.nodeName &&
                                ++n; 
                        }
                        for (r = e.nextSibling; r && !flag; r = r.nextSibling) {
                            if (r.nodeName == e.nodeName) {
                                flag = true;
                            }
                        }
                        let o = (e.prefix ? e.prefix + ':' : '') + e.localName,
                            x = n || flag ? '[' + (n + 1) + ']' : '';
                        t.splice(0, 0, o + x);
                    }
                    return t.length ? '/' + t.join('/') : null;
                })(target)
            },
            message: event
        };
        Object.assign(errorInfo, formateError(event.error));
        sendError('ResourceError', errorInfo);
    };

    //  formatting error 
    function formateError(error) {
        let errorInfo = {};
        if (Object.prototype.toString.call(error) === '[object Error]') {
            errorInfo = {
                name: error.name,
                message: error.message,
                stack: error.stack
            };
            var reg = /(http\S*):(\d+):(\d+)/g;
            var matcher = reg.exec(error.stack);
            if (matcher) {
                Object.assign(errorInfo, {
                    fileName: matcher[1],
                    lineNumber: +matcher[2],
                    columnNumber: +matcher[3]
                });
            }
        } else {
            errorInfo = deepCopy(error);
        }
        Object.keys(errorInfo).forEach(key => {
            if (isObject(errorInfo[key])) {
                errorInfo[key] = JSON.stringify(errorInfo[key], null, 4);
            }
        });
        return errorInfo;
    }

    //  behavior record 
    const Action = {
        limit: 6,
        list: [],
        eventCount: 0,
        setAction(action) {
            Action.list.push(action);
            if (Action.list.length > Action.limit) {
                Action.list.splice(0, 1);
            }
            ++Action.eventCount;
        }
    };

    //  Click event binding   for behavior monitoring 
    function clickEvent(event) {
        let target = event.target ? event.target : event.srcElement;
        let outerHTML = target && target.outerHTML;
        outerHTML =
            outerHTML && outerHTML.length > 200
                ? outerHTML.slice(0, 200)
                : outerHTML;
        let eventInfo = {
            type: 'click',
            page: {
                url: win.location.href,
                title: document.title
            },
            detail: {
                outerHTML: outerHTML,
                tagName: target && target.tagName,
                id: target && target.id,
                name: target && target.name,
                className: target && target.className,
                type: target && target.type
            },
            time: new Date().getTime()
        };
        Action.setAction(eventInfo);
    }
    win.addEventListener
        ? win.addEventListener('click', clickEvent, true)
        : document.attachEvent('onclick', clickEvent);

    //  Listen for route changes   for behavior monitoring 
    (function routerChange() {
        let router = {
            url: win.location.href
        };
        let initRouter = function() {
            router = {
                url: win.location.href,
                title: document.title
            };
        };
        let logRouter = function(from, to) {
            let info = {
                type: 'Navigation',
                detail: {
                    from,
                    to: (router = to)
                },
                time: new Date().getTime()
            };
            Action.setAction(info);
        };
        document.addEventListener
            ? document.addEventListener('DOMContentLoaded', initRouter)
            : document.attachEvent('onreadystatechange', initRouter);
        let onpopstate = win.onpopstate;
        //  Add and modify entries in history 
        win.onpopstate = function() {
            let r = {
                url: win.location.href,
                title: document.title
            };
            if (
                (router.title || (router.title = document.title),
                router.url !== r.url && logRouter(router, r),
                onpopstate)
            ) {
                return onpopstate.apply(this, arguments);
            }
        };
        let pushState = win.history.pushState;
        win.history.pushState = function() {
            router = {
                url: win.location.href,
                title: document.title
            };
            var e = {};
            if (
                (arguments.length === 3 && (e.url = arguments[2]),
                router.url !== e.url && logRouter(router, e),
                pushState)
            ) {
                return pushState.apply(this, arguments);
            }
        };
        let onhashchange = win.onhashchange;
        // onhashchange  event in the current  URL  the anchor part of ( by  '#'  number to start )  fires when a change occurs 
        win.onhashchange = function() {
            var e = {
                url: win.location.href,
                title: document.title
            };
            if ((router.url !== e.url && logRouter(router, e), onhashchange)) {
                return onhashchange.apply(this, arguments);
            }
        };
    })();

    function sendError(type, detail) {
        let info = {
            version: '1.0',
            userAgent: win.navigator.userAgent,
            locale: win.navigator.language || win.navigator.userLanguage,
            url: win.location.href,
            title: document.title,
            appVersion: '',
            apiKey: APPKEY,
            time: new Date().getTime(),
            type: type,
            detail: detail,
            actionInfo: Action.list,
            eventCount: Action.eventCount,
            custom: {}
        };

        //  user defined field 
        if (isObject(win.__rendebugCustomFields)) {
            const fields = win.__rendebugCustomFields;
            Object.keys(fields).forEach(key => {
                if (fields[key] && isString(fields[key])) {
                    const keys = fields[key].split('.');
                    let value = win;
                    keys.forEach(k => {
                        if (value) {
                            value = value[k];
                        }
                    });
                    if (
                        ['String', 'Array', 'Number', 'Date'].indexOf(
                            Object.prototype.toString.call(value).slice(8, -1)
                        ) > -1
                    ) {
                        info.custom[key] = value;
                    }
                }
            });
        }
        // console.log(info);
        // return;
        new Image().src =
            serverUrl + '?event=' + encodeURIComponent(JSON.stringify(info));
    }
    //VUE ERROR&WARN
    function formatComponentName(vm) {
        if (vm.$root === vm) {
            return 'root'; 
        }
        let name = vm._isVue
            ? (vm.$options && vm.$options.name) ||
            (vm.$options && vm.$options._componentTag)
            : vm.name;
        return (
            (name ? 'component <' + name + '>' : 'anonymous component') +
            (vm._isVue && vm.$options && vm.$options.__file
                ? ' at ' + (vm.$options && vm.$options.__file)
                : '')
        );
    }
    function getVueBasicInfo(vm) {
        return {
            componentName: formatComponentName(vm),
            propsData: {
                ...simpleCopy(vm.$options && vm.$options.propsData),
                theme: vm.theme
            }
        };
    }
    win.rendebug = {
        sendError: function(error, info) {
            if (!isOn) {
                return;
            }
            let errorInfo = Object.assign(formateError(error), info);
            errorInfo.type = 'Caught';
            errorInfo.severity = 'serious';
            sendError('Caught', errorInfo);
        },
        sendVueError: function(vm, err, info = {}) {
            isOn &&
                sendError(
                    'Caught',
                    Object.assign(
                        formateError(err),
                        getVueBasicInfo(vm),
                        info,
                        { severity: 'serious' }
                    )
                );
        },
        sendVueWarn: function(msg, vm, trace) {
            console.error('[Vue warn]: ' + msg + trace);
            isOn &&
                sendError(
                    'Caught',
                    Object.assign(getVueBasicInfo(vm), {
                        message: msg,
                        stack: trace,
                        severity: 'serious'
                    })
                );
        },
        setOn(flag) {
            isOn = !!flag;
            window.removeEventListener('error', errorListenerCallback);
            if (isOn) {
                win.onerror = onerrorFn;
                win.addEventListener('error', errorListenerCallback);
                return;
            }
            if (win.onerror === onerrorFn) {
                win.onerror = null;
            }
        }
    };
})(window);
