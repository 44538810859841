<template>
    <div class="video-box ytb" :style="videoStyle">
        <div ref="video" class="video-wrap" v-html="iframeEmbed"></div>
    </div>
</template>

<script>
import util from '@/common/util';
import common from '../common';

let youtubePs = null;
export default {
    props: {
        src: {
            type: String,
            default: ''
        },
        autoplay: {
            type: Boolean,
            default: false
        },
        muted: {
            type: Boolean,
            default: false
        },
        controls: {
            type: Boolean,
            default: false
        },
        playsinline: {
            type: Boolean,
            default: false
        },
        preview: {
            type: String,
            default: ''
        }
    },
    data: function() {
        return {
            videoStyle: {},
            iframeEmbed: ''
        };
    },
    created() {
        this.prepare();
    },
    mounted() {
        this.$video = this.$refs.video;
        this.youtubeInit();
    },
    computed: {
        youtubeId() {
            return common.getYoutubeId(this.src);
        }
    },
    watch: {
        src() {
            this.youtubeInit();
        }
    },
    methods: {
        prepare() {
            if (!youtubePs) {
                youtubePs = new Promise(resolve => {
                    util.getUserActionPs().then(() => {
                        util.addScript('https://www.youtube.com/iframe_api');
                        window.onYouTubeIframeAPIReady = () => {
                            resolve();
                        };
                    });
                });
            }
            return youtubePs;
        },
        youtubeInit() {
            if (this.youtubePlayer) {
                //src When changing, you need to clear the original video
                this.youtubePlayer.destroy();
            }
            this.iframeEmbed = '<div id="iframe-embed" rel="nofollow"></div>';
            this.prepare().then(() => {
                const playerVars = {
                    autoplay: this.autoplay ? 1 : 0,
                    controls:
                        this.controls || (this.platform.isIos && !this.autoplay) ? 1 : 0,
                    loop: this.autoplay ? 1 : 0,
                    playsinline: 1,
                    modestbranding: 1,
                    mute: this.muted ? 1 : 0
                };

                let iframeBox = this.$video.querySelector('#iframe-embed');
                this.youtubePlayer = new window.YT.Player(iframeBox, {
                    videoId: this.youtubeId,
                    width: '100%',
                    height: '100%',
                    playerVars: playerVars,
                    events: {
                        onReady: () => {
                            if (window.innerWidth >= 1920) {
                                this.youtubePlayer.setPlaybackQuality('hd1080');
                            } else if (window.innerWidth >= 1280) {
                                this.youtubePlayer.setPlaybackQuality('hd720');
                            } else {
                                this.youtubePlayer.setPlaybackQuality(
                                    'default'
                                );
                            }
                            this.$emit('loadedmetadata', this);
                            this.$emit('canplay');
                            console.log('youtube ===>>>', this.preview)
                            //  After the video has loaded ， Get the first frame of the video as the cover image
                            if (!this.preview) {
                                this.$emit('loadPreview', `https://img.youtube.com/vi/${this.youtubeId}/0.jpg`);
                            } else {
                                this.$emit('loadPreview', this.preview);
                            }
                            if (
                                this.platform.isMobile &&
                                this.autoplay &&
                                this.youtubePlayer.playVideo
                            ) {
                                this.youtubePlayer.playVideo();
                            }
                        },
                        onStateChange: event => {
                            const playerStatus = event.data;
                            if (playerStatus === 0) {
                                if (this.autoplay) {
                                    this.youtubePlayer.playVideo();
                                } else {
                                    this.$emit('ended');
                                }
                            } else if (playerStatus === 2) {
                                this.$emit('pause');
                            } else if (playerStatus === 1) {
                                this.$emit('play');
                            }
                        },
                        onError: event => {
                            console.log('errorStatus', event.data);
                            this.$emit('error');
                        }
                    }
                });
            });
        },
        calcVideoStyle(option) {
            let {  ratio, height, autoHeight, playOnPop } = option;
            let bannerVideo = this.$el.parentElement;
            let videoRatio = 9 / 16;
            if (
                (autoHeight && this.playsinline && !this.autoplay) ||
                (!playOnPop && ratio < videoRatio) ||
                (playOnPop && ratio > videoRatio)
            ) {
                this.videoStyle = {
                    height:
                        (playOnPop
                            ? window.innerWidth
                            : bannerVideo.offsetWidth) *
                            videoRatio +
                        'px',
                    width: '100%'
                };
            } else {
                this.videoStyle = {
                    width: height / videoRatio + 'px',
                    height: '100%'
                };
            }

            this.youtubePlayer.setSize &&
                this.youtubePlayer.setSize(
                    bannerVideo.offsetWidth,
                    videoRatio * bannerVideo.offsetWidth
                );

            return videoRatio;
        },
        playVideo() {
            this.youtubePlayer &&
                this.youtubePlayer.playVideo &&
                this.youtubePlayer.playVideo();
            util.timeout(() => {
                this.youtubePlayer &&
                    this.youtubePlayer.playVideo &&
                    this.youtubePlayer.playVideo();
            });
        },
        pauseVideo() {
            this.youtubePlayer.pauseVideo();
        },
        toggleMuted(muted) {
            if (muted) {
                this.youtubePlayer.mute();
            } else {
                this.youtubePlayer.unMute();
            }
        }
    }
};
</script>

<style lang="scss">
.video-box.ytb {
    position: relative;
    width: 100%;
    height: 100%;
    .video-wrap {
        width: 100%;
        height: 100%;
        iframe {
            max-width: none;
            width: 100%;
            height: 100%;
        }
    }
}
</style>
