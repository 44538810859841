function updateEventParam(el, clickEvs) {
    el.clickEvs = clickEvs
        ? Array.isArray(clickEvs)
            ? clickEvs
            : [clickEvs]
        : undefined;
}

function isClickTarget(targetEl, selector, rootEl) {
    if (targetEl === rootEl) {
        return false;
    }
    const closetSelectorEl = targetEl.closest(selector);
    if (!closetSelectorEl) {
        return false;
    }
    return rootEl.contains(closetSelectorEl);
}

var directive = {
    mounted(el, binding) {
        updateEventParam(el, binding.value);
        if (!el.clickEvs) {
            return;
        }
        el.clickCb = function(event) {
            for (let i = 0; i < el.clickEvs.length; i++) {
                const { selector, callback, modifier } = el.clickEvs[i];
                if (!isClickTarget(event.target, selector, el) || !callback) {
                    continue;
                }
                if (modifier?.prevent) {
                    event.preventDefault();
                }
                if (modifier?.stop) {
                    event.stopPropagation();
                }
                callback({ event });
                break;
            }
        };
        el.addEventListener('click', el.clickCb, false);
    },
    updated(el, binding) {
        directive.unmounted(el);
        directive.mounted(el, binding);
    },
    unmounted(el) {
        if (el.clickCb) {
            el.removeEventListener('click', el.clickCb);
        }
    }
};
/**
 *  exist dom on the control child node's click event registration 
 * v-click="{ selector: '.selector', callback: func }"
 * v-click="[{ selector: '.selector1', callback: func1 }, { selector: '.selector2', callback: func2}]"
 */
export default function(Vue) {
    Vue.directive('click', directive);
}
