import util from './util';
import axios from 'axios';
import global from '../common/global';
import { getStore } from '../store';

function getAnchor(target) {
    while (target) {
        if (target.tagName && target.tagName.toLowerCase() === 'a') {
            return target;
        }
        target = target.parentNode;
    }
}
function getWidgetUrl(url, domainName) {
    if (/global_domain/.test(window.location.search)) {
        url =
            url.indexOf('?') > -1
                ? `${url}&global_domain=${domainName}`
                : `${url}?global_domain=${domainName}`;
        return `https://${window.location.host}${url}`;
    } else {
        return url;
    }
}

export default {
    init(pageInit, siteAppVm) {
        var globalData = getStore('page').page;
        function forwardPage(url, pos) {
            if (globalData.widget) {
                return window.open(getWidgetUrl(url, globalData.domainName));
                // return window.parent.postMessage(
                //     JSON.stringify({
                //         from: 'chimeSite',
                //         event: 'navigate',
                //         data: getWidgetUrl(url, globalData.domainName)
                //     }),
                //     '*'
                // );
            }
            (
                util.call(window.globalFetchPage, null, url) ||
                axios
                    .post('/api-graphql', {
                        query: `{page(url: "${window.location.pathname + window.location.search
                            }"){content,data,css,settings}}`
                    })
                    .then(({ data }) => {
                        let page = (data && data.page) || {};
                        let content = page.content;
                        if (!content) {
                            //  Manually throw an exception into catch
                            throw 0;
                        }
                        let pageData = {};

                        const fragment = document.createDocumentFragment();
                        let div = document.createElement('div');
                        div.innerHTML = content;

                        while (div.childNodes[0]) {
                            fragment.appendChild(div.childNodes[0]);
                        }

                        let pageJson = content.match(
                            /<script>window.sitePageJSON=([\s\S]+)<\/script>/m
                        );
                        if (pageJson) {
                            pageData = new Function('return ' + pageJson[1])();
                        } else {
                            pageData = JSON.parse(
                                fragment.getElementById('page-data').value
                            );
                        }

                        pageData.css = page.css;
                        window.GLOBAL_DATA = JSON.parse(page.data || '{}');
                        return {
                            pageData,
                            settings: page.settings
                        };
                    })
                    .catch((e) => {
                        console.log(e)
                        if (util.inPopDetailPage) {
                            window.parent.__curDetailPop.setLoading(false);
                        }
                        window.location.reload();
                    })
            ).then(pageData => {
                pageData && pageInit(pageData, pos);
                try {
                    let settings = JSON.parse(pageData.settings);
                    if (settings.seo && settings.seo.title) {
                        document.title = settings.seo.title;
                    }
                } catch (e) { }
            });
        }
        window.addEventListener('popstate', evt => {
            let state = evt.state;
            forwardPage(state.url, state.pos);
        });

        document.addEventListener('click', evt => {
            let anchor = getAnchor(evt.target);
            if (anchor) {
                if (anchor.hasAttribute('disabled')) {
                    evt.preventDefault();
                    return;
                }
                let title = anchor.textContent,
                    target = anchor.getAttribute('target'),
                    url = anchor.getAttribute('href');
                //  if in iframe middle ( At present, this is only the case of the pop-up box on the details page ),  Need to manually control the link jump of the parent window  ( if <a> label has self attribute is still there iframe middle jump )
                if (util.inPopDetailPage) {
                    if (anchor.hasAttribute('self')) {
                        window.parent.__curDetailPop.updateLink(url);
                    } else if (/^https?:\/\/|^\//.test(url)) {
                        evt.preventDefault();
                        window.parent.location.href = url;
                        return;
                    }
                }
                if (
                    /^\//.test(url) &&
                    !/^#/.test(url) &&
                    target !== '_blank' &&
                    url.indexOf('/forget-password') === -1
                ) {
                    if (
                        window.location.pathname + window.location.search !==
                        url
                    ) {
                        anchor.setAttribute('disabled', true);
                        let state = window.history.state;
                        state.pos = global.scroll('fetch') || 0;
                        window.history.replaceState(state, state.title);
                        url =
                            util.call(window.globalFormatUrl, window, url) ||
                            url;
                        window.history.pushState({ title, url }, title, url);
                        forwardPage(url, 0);
                    }
                    evt.preventDefault();
                } else if (globalData.preDomain && /^\/[^/]+/.test(url)) {
                    anchor.setAttribute('href', globalData.preDomain + url);
                }
            }
        });
        siteAppVm.$EventBus.$on('change-url', (url, title) => {
            let state = window.history.state;
            state.pos = global.scroll('fetch') || 0;
            window.history.replaceState(state, state.title);
            url = util.call(window.globalFormatUrl, window, url) || url;
            window.history.pushState({ title, url }, title, url);
            forwardPage(url, 0);
        });
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        let url =
            window.location.pathname +
            window.location.search +
            window.location.hash,
            title = document.title;
        url = util.call(window.globalFormatUrl, window, url) || url;
        window.history.replaceState({ title, url }, title, url);
    },
    multiPageInit(siteAppVm, globalData) {
        //  Compatible before disabled disabled a label jump 
        document.addEventListener('click', evt => {
            let anchor = getAnchor(evt.target);
            if (anchor) {
                if (anchor.hasAttribute('disabled') === 'true') {
                    evt.preventDefault();
                    return;
                }
                if (globalData.widget) {
                    url = anchor.getAttribute('href');
                    if (!url || evt.defaultPrevented) {
                        return; 
                    }
                    evt.preventDefault();
                    return window.open(
                        getWidgetUrl(url, globalData.domainName)
                    );
                }
            }
        });
        siteAppVm.$EventBus.$on('change-url', (url, title) => {
            if (globalData.widget) {
                return window.open(getWidgetUrl(url, globalData.domainName));
            }
            window.location.href = url;
        });
        let url =
            window.location.pathname +
            window.location.search +
            window.location.hash,
            title = document.title;
        window.history.replaceState({ title, url }, title, url);
    }
};
