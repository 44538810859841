import { isSSRServer, isSSRClient, isClient } from '@/config';
import types from '../type';
import { getQueryField } from './url-params';
import { cookieMethods, getPlatform } from '../../clientEnv.js';
import axios from 'axios';
import { GET_SCRIPT_CONTENT } from '../regexp';
import { observeElementInViewport } from "./intersection-observer-dom"

const prefixMethods = (function() {
    const methods = {},
        prefix = ['', 'webkit', 'moz', 'ms', 'o'];

    function getMethod(elem, method) {
        for (let i = 0, len = prefix.length; i < len; i++) {
            const md = elem[prefix[i] + method];
            if (!types.isNull(md)) {
                return prefix[i] + method;
            }
            if (i === 0) {
                method = method.charAt(0).toUpperCase() + method.slice(1);
            }
        }
        return false;
    }

    return {
        run(elem, method) {
            method = method.charAt(0).toLowerCase() + method.slice(1);
            let md = methods[method];
            if (types.isNull(md)) {
                md = methods[method] = getMethod(elem, method);
            }
            if (md !== false) {
                return types.isFunction(elem[md]) ? elem[md]() : elem[md];
            }
        }
    };
})();
const isUndef = obj => obj === null || obj === undefined;
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
function generateUploadOption(type, uuid = uuidv4()) {
    let business = 'cmsbuild',
        date = new Date();
    return {
        business,
        path: `/image/fs/${business}/${date.getFullYear()}${
            date.getMonth() + 1
        }${date.getDate()}/${date.getHours()}/original_${uuid}.${type}`
    };
}
/**
 *
 * @param {*} file {id,file,ext,filename}
 * @returns
 */
function upload(file) {
    return new Promise((resolve, reject) => {
        let ext = file.ext;
        if (!ext) {
            const r = (file.filename || '').match(/[^\.]+\.(.+)$/);
            if (r) {
                ext = r[1];
            } else {
                console.error('none ext');
            }
        }
        const { path, business } = generateUploadOption(ext, file.id);
        axios
            .get(
                '/api-site/aws/token/presignurl',
                {
                    params: {
                        business,
                        imageType: ext,
                        path
                    }
                },
                { timeout: 60000 }
            )
            .then(rt => {
                axios({
                    url: rt.data.url,
                    method: 'put',
                    timeout: 60000,
                    data: file.file,
                    headers: { 'Content-Type': 'image/' + ext }
                })
                    .then(() => {
                        resolve(`https://cdn.chime.me${path}`);
                        // resolve(['https://cdn.chime.me' + path, rt.data.url]);
                    })
                    .catch(e => {
                        console.error(e);
                        reject('Upload error.');
                    });
            });
    });
}

// holds all asynchronously loaded js of promise
const scripts = {};
const styles = {};
const merageObj = (object, sources, weak = new WeakSet()) => {
    for (let i in sources) {
        let source = sources[i];
        let obj = object[i];
        if (types.isObject(source) && types.isObject(obj) && !weak.has(source)) {
            weak.add(source);
            merageObj(obj, source, weak);
        } else {
            object[i] = source;
        }
    }
} 


const jsonParse = function(str, def) {
    if (!str) {
        return def;
    }
    try {
        return JSON.parse(str);
    } catch (e) {
        console.log("util jsonParse error", e);
    }
    return def;
}

const setScript = function(url, crossorigin = false, props = {}) {
    const script = document.createElement('script');
    if (crossorigin === true && window.globalAlowCrossOrigin) {
        script.setAttribute('crossorigin', 'anonymous');
    } else if (util.isObject(crossorigin)) {
        for (let name in crossorigin) {
            if (crossorigin.hasOwnProperty(name)) {
                script.setAttribute(
                    name,
                    crossorigin[name]
                );
            }
        }
    }
    if (util.isObject(props)) {
        for (let name in props) {
            if (props.hasOwnProperty(name)) {
                script.setAttribute(
                    name,
                    props[name]
                );
            }
        }
    }
    if (url) {
        script.async = true;
        script.src = url;
    }
    return script;
}

const util = {
    jsonParse,
    upload,
    merageObj,
    isClient,
    isSSRServer,
    isSSRClient,
    ...types,
    isUndef,
    uuidv4,
    ...cookieMethods(),
    observeElementInViewport,
    isFullScreen() {
        let isFullScreen = prefixMethods.run(document, 'isFullScreen');
        if (types.isNull(isFullScreen)) {
            isFullScreen = prefixMethods.run(document, 'fullScreen');
        }
        return !!isFullScreen;
    },
    getStorageItemExpires(key) {
        try {
            if (isSSRServer) {
                return '';
            } else {
                const item = window.localStorage.getItem(key);
                const { value, expires } = JSON.parse(item);
                if (expires > Date.now()) {
                    return value;
                } else {
                    return '';
                }
            }
        } catch (err) {
            return '';
        }
    },
    setStorageItemExpires(key, value, days = 1) {
        let item = JSON.stringify({
            value,
            expires: Date.now() + days * 86400000
        });
        try {
            if (!isSSRServer) {
                window.localStorage.setItem(key, item);
            }
        } catch (err) {}
    },
    getStorageItem(storage, key) {
        try {
            if (isSSRServer) {
                return '';
            } else {
                const value = window[storage].getItem(key);
                return value;
            }
        } catch (err) {
            return '';
        }
    },
    setStorageItem(storage, key, value) {
        try {
            if (!isSSRServer) {
                window[storage].setItem(key, value);
            }
        } catch (err) {}
    },
    removeStorageItem(storage, key) {
        try {
            window[storage].removeItem(key);
        } catch (err) {}
    },
    colorToRgba(sHex, alpha = 1) {
        //  Regular expression for hexadecimal color values
        const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
        /* 16 The binary color is converted to RGB Format  */
        let sColor = sHex.toLowerCase();
        sColor = sColor.replaceAll(' ', '');
        if (sColor && reg.test(sColor)) {
            if (sColor.length === 4) {
                let sColorNew = '#';
                for (let i = 1; i < 4; i += 1) {
                    sColorNew += sColor
                        .slice(i, i + 1)
                        .concat(sColor.slice(i, i + 1));
                }
                sColor = sColorNew;
            }
            //   Handles six-bit color values
            const sColorChange = [];
            for (let i = 1; i < 7; i += 2) {
                sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)));
            }
            return 'rgba(' + sColorChange.join(',') + ',' + alpha + ')';
        } else if (sColor.indexOf('rgb(') !== -1) {
            return sColor
                .replace('rgb', 'rgba')
                .replace(')', ',' + alpha + ')');
        } else if (sColor.indexOf('rgba') !== -1) {
            return sColor.replace(/,[^,]+\)$/, ',' + alpha + ')');
        } else {
            return '';
        }
    },
    debounce(fn, delay = 200) {
        let timer;
        return function() {
            let context = this;
            let args = arguments;

            clearTimeout(timer);
            timer = setTimeout(function() {
                fn.apply(context, args);
            }, delay);
        };
    },
    throttle(fn, delay) {
        let last;
        let timer;
        delay || (delay = 250);
        return function() {
            let context = this;
            let args = arguments;
            let now = +new Date();
            if (last && now < last + delay) {
                clearTimeout(timer);
                const callback = function() {
                    last = now;
                    fn.apply(context, args);
                };
                timer = setTimeout(callback, delay);
            } else {
                last = now;
                fn.apply(context, args);
            }
        };
    },
    //  Prevent triggering of multiple button clicks
    debounceAsync(fn) {
        let loading = false;
        return async function() {
            if (loading) {
                return;
            }
            loading = true;
            try {
                return await fn.apply(this, arguments);
            } finally {
                loading = false;
            }
        };
    },
    //  singleton timer
    singleSetTimeOut(fn, time = 500) {
        let timer;
        return function() {
            if (timer) {
                return;
            }
            timer = setTimeout(fn.bind(this), time);
        };
    },

    //  Get the asynchronously loaded  dom
    /**
     *
     * @param {*}  selector   acquired   Selector
     *             doc        The default is  document
     *             all        is to use  querySelectorAll
     *             time      300          Every  300  try to get once
     *             max       2000        300 * 2000 / 1000 / 60    default  10  minute ， Otherwise timeout
     */
    async getAsyncDom(params) {
        let {
            selector,
            doc = document,
            all = false,
            time = 300,
            max = 2000
        } = params;
        if (max <= 0) {
            return Promise.reject(`getAsyncDom  exceed max times: ${selector}`);
        }
        if (all) {
            let dom = doc.querySelectorAll(selector);
            if (dom.length > 0) {
                return dom;
            }
        } else {
            let dom = doc.querySelector(selector);
            if (dom) {
                return dom;
            }
        }

        await this.sleep(time);
        return this.getAsyncDom({ ...params, max: max - 1 });
    },
    async sleep(time = 500) {
        return new Promise(resolve => {
            setTimeout(resolve, time);
        });
    },
    /**
     *  Get the value of a property of an object ， If not, return to the default value
     * @param {Object} obj  parsed object
     * @param {string} path  looking for someone key path of
     * @param {Any} def  no default value found for return
     */
    getValueByPath(obj, path, def) {
        path = path || '';
        const paths = path.split('.');
        let current = obj;
        let result;
        for (let i = 0, j = paths.length; i < j; i++) {
            const key = paths[i];
            if (!current) {
                break; 
            }

            if (i === j - 1) {
                result = current[key];
                break;
            }
            current = current[key];
        }
        return isUndef(result) ? def : result;
    },
    //  Obtain  js  script
    getScriptContent(code) {
        let result = code.match(GET_SCRIPT_CONTENT);
        console.log(result);
    },
    /**
     *  Generally used for async/await
     * @param {promise} promise  need packaging promise
     * @returns promise
     */
    awaitWrap(promise) {
        return promise.then(data => [null, data]).catch(err => [err, null]);
    },
    cloneData(data) {
        if (!data) {
            return data;
        }
        const newData = Array.isArray(data) ? [] : {};
        for (let keys in data) {
            if (data.hasOwnProperty(keys)) {
                if (data[keys] && typeof data[keys] === 'object') {
                    newData[keys] = util.cloneData(data[keys]);
                } else {
                    newData[keys] = data[keys];
                }
            }
        }
        return newData;
    },
    deepClone(origin, copied = new WeakMap()) {
        if (typeof origin !== 'object' || !origin) {
            return origin; 
        }
        let copy = {};
        if (types.isRegExp(origin)) {
            copy = new RegExp(origin);
            copy.lastIndex = 0;
            return copy;
        }
        if (types.isDate(origin)) {
            return new Date(origin.valueOf()); 
        }
        if (copied.has(origin)) {
            return copied.get(origin); 
        }
        if (types.isArray(origin)) {
            copy = [];
        }
        copied.set(origin, copy);
        Object.keys(origin).forEach(key => {
            copy[key] = util.deepClone(origin[key], copied);
        });
        return copy;
    },
    isEmptyRichtext(content) {
        let html = content?.value || content || ''
        try {
            return !html.replace(/(<([^>]+)>)/ig, '').replace(/\s+/g, '').replace(/&nbsp;/g, '').trim().length;
        } catch (e) {
            console.error(e);
        }
        return false;
    },
    /**
     *  execute function object
     * @param handler  function object
     * @param context  execution context
     *
     *  The so-called function object is that it can be a function ， can also be an object ， This object has the following properties ：
     * {
     *      handler:  function ， string （ The string is a method of the context object name）
     *      context:  the context ， optional
     *      args:  parameter ， optional
     * }
     *  Starting from the third parameter, the following parameters will be used as the execution parameters of the function object ， and placed inside the function object args later
     */
    call(handler, context) {
        if (!handler) {
            return; 
        }
        try {
            let args = [].slice.call(arguments, 2);
            if (this.isFunction(handler)) {
                return handler.apply(context, args);
            } else {
                context = handler.context || context;
                args = (handler.args || []).concat(args);
                if (
                    this.isString(handler) ||
                    this.isString((handler = handler.handler))
                ) {
                    handler = context ? context[handler] : null;
                }
                if (this.isFunction(handler)) {
                    return handler.apply(context, args);
                }
            }
        } catch (err) {
            console.error('util.call', err);
        }
    },
    /**
     *  asynchronous loading js script ( Generally suitable for loading some third-party libraries js,  If this project js the code ,  Please use require.ensure load )
     *
     * @param {string} url  Script link address
     * @returns  return a promise,  pass .then The method can get the callback after the loading is completed
     */
    addScript(url, async = true, crossorigin = false, props = {}, mountScriptCallback) {
        if (url && async) {
            if (scripts[url]) {
                // If asynchronous loading has started , just return the loaded promise
                return scripts[url];
            } else {
                // Otherwise start loading
                scripts[url] = new Promise(function(resolve) {
                    const script = setScript(url, crossorigin, props);
                    if (script.readyState) {
                        //IE
                        script.onreadystatechange = function() {
                            if (
                                script.readyState === 'loaded' ||
                                script.readyState === 'complete'
                            ) {
                                script.onreadystatechange = null;
                                resolve();
                            }
                        };
                    } else {
                        //Others
                        script.onload = function() {
                            resolve();
                        };
                    }
                    if (mountScriptCallback) {
                        mountScriptCallback(script)
                    } else {
                        document.body.appendChild(script);
                    }
                });
                return scripts[url];
            }
        }
        const script = setScript(url, crossorigin, props);
        if (mountScriptCallback) {
            mountScriptCallback(script)
        } else {
            document.body.appendChild(script);
        }
    },
    addStyle(css, async = true) {
        try {
            if (css) {
                let elem;
                if (css.indexOf('.css') !== -1) {
                    css = css.trim();
                    if (styles[css] && async) {
                        return styles[css];
                    } else if (
                        document.querySelector(`link[href="${css}"]`) &&
                        async
                    ) {
                        return (styles[css] = new Promise(res => res()));
                    } else {
                        elem = document.createElement('div');
                        elem.innerHTML =
                            '_<link rel="stylesheet" type="text/css" href="' +
                            css +
                            '"/>';
                    }
                } else {
                    elem = document.createElement('div');
                    elem.innerHTML = '_<style type="text/css">' + css + '</style>';
                }
                elem = elem.lastChild;
                const head = document.getElementsByTagName('head')[0];
                if (async) {
                    styles[css] = new Promise(function(resolve, reject) {
                        elem.onload = function() {
                            resolve();
                        };
                        elem.onerror = function() {
                            reject()
                        }
                        if (/trident/i.test(navigator.userAgent)) {
                            head.appendChild(elem);
                        } else {
                            window.setTimeout(() => {
                                head.appendChild(elem);
                            }, 0);
                        }
                    });
                    return styles[css];
                } else {
                    head.appendChild(elem);
                    return elem;
                }
            }
        } catch (e) {
            console.error(e);
        }
    },
    /**
     * Execute a set of asynchronous tasks sequentially, waiting for each task to complete before executing the next task.
     * @param {Array<function(): Promise>} popPsList
     */
    async asyncTaskSequenceExecutor(psList = []) {
        for (let ps of psList) {
            try {
                await ps();
            } catch (error) {
                console.error(error) 
            }
        }
    }
};
if (!isSSRServer) {
    Object.assign(util, getPlatform(getQueryField()))
}
export default util;
