import axios from 'axios';
import i18next from 'i18next';
import { reactive } from 'vue';
import { defaultLngCode, defaultNs } from './constant.js';
import util from '@/common/util';

const instance = axios.create({
    timeout: API_TIMEOUT || (util.isSSRServer ? 2000 : 20000),
});

const requestMap = {};
const cdnMapPromise = {};
const lng_locales = process.env.LNG_LOCALES && !util.isSSRServer;


export const getNsUrl = async function(lng, ns) {
    var nsArr = await cdnMapPromise[lng];
    if (nsArr) {
        return nsArr[ns];
    }
};

// export const commonNs = ['common'];

function getCdnUrl(lng, ns) {
    if (
        !util.isSSRServer &&
        window.lngNsDetail &&
        window.lngNsDetail.lng === lng &&
        window.lngNsDetail.ns &&
        window.lngNsDetail.ns[ns]
    ) {
        return Promise.resolve(window.lngNsDetail.ns[ns]);
    }
    let url = `/api/international/publish/cdn/Site/${lng}`;
    if (util.isSSRServer) {
        if (process.env.NODE_ENV == 'development' || !SSR_LNG_SVC) {
            url = `${SSR_DOMAIN}/api/international/publish/cdn/Site/${lng}`;
        } else {
            url = `${SSR_LNG_SVC}/api/publish/cdn/Site/${lng}`;
        }
    }
    if (!cdnMapPromise[lng]) {
        cdnMapPromise[lng] = instance.get(url).then(res => {
            var result = res.data.reduce(function(pre, cur) {
                pre[cur.module] = cur.fileUrl;
                return pre;
            }, {});
            return result;
        }).catch(() => {
            console.log(`${url} => ${lng}: ${ns} lack in admin(cdn)`)
            cdnMapPromise[lng] = null;
        });
    }
    return cdnMapPromise[lng].then(
        cdnMap => {
            const url = cdnMap?.[ns];
            if (url) {
                return url;
            }
            throw `${lng}: ${ns} lack in admin(cdn)`;
        }
    );
}

function getTranJson(lng, ns) {
    //can be replaced with http request
    //or find resource in module data
    let key = `${lng}/${ns}`;
    if (requestMap[key]) {
        return requestMap[key];
    }
    let urlPs = lng_locales
        ? Promise.resolve(`/lng/locales/${lng}/${ns}.json`)
        : getCdnUrl(lng, ns);
    requestMap[key] = urlPs
        .then(url => {
            return (instance.get(url).then(res => {
                if (!util.isSSRServer) {
                    util.setStorageItem('localStorage', `_chime_lng_${key}`, JSON.stringify(res.data));
                }
                return res.data;
            })).catch((error) => {
                console.log(error)
                throw `load ${url} fail`
            });
        })
        .catch(err => {
            if (!util.isSSRServer) {
                let res = util.getStorageItem('localStorage', `_chime_lng_${key}`);
                if (res) {
                    res = JSON.parse(res);
                    return res;
                }
            }
            requestMap[key] = null
        });
    return  requestMap[key]
}

function genOptions(options, returnEmptyWhenNotFound) {
    if (!returnEmptyWhenNotFound) {
        return options;
    }
    if (!options) {
        return { defaultValue: '' };
    }
    if (typeof options == 'object' && !options.defaultValue) {
        return { ...options, defaultValue: '' };
    }
    return options;
}

class globalization {
    constructor(lng, replaceCb) {
        this.prefix = util.getStorageItem('localStorage', 'langPrefix') || '';
        this.lng = lng;
        this.langKey = {};
        this.i18next = i18next.createInstance();
        this.i18next.init({
            lng: lng,
            fallbackLng: defaultLngCode,
            fallbackNS: [defaultNs],
            initImmediate: false,
            resources: {}
        });
        this.resetNs();
        if (!util.isSSRServer) {
            window.i18next = this.i18next;
        }

        let oldT = this.i18next.t;
        let _this = this;
        this.i18next.t = function() {
            let result = oldT.apply(this, arguments);
            if (result && replaceCb) {
                result = replaceCb(result);
            }
            return result ? _this.prefix + result : result;
        };
        this.psNsMap = {};
        this.nsLoadedMap = {};
        this.loadNs([defaultNs]);
    }
    resetNs() {
        this.loadedNsList = new Set();
    }
    onLngNsLoaded(ns = '') {
        // if (!ns){
        //     this.psNsMap[''] = new Promise((res) => {
        //         this.nsLoadedMap[''] = res;
        //     });
        // }
        if (ns && this.i18next.hasResourceBundle(this.lng, ns)) {
            return Promise.resolve();
        } else {
            if (!this.psNsMap[ns]) {
                this.psNsMap[ns] = new Promise((res) => {
                    this.nsLoadedMap[ns] = res;
                });
            }
            return this.psNsMap[ns];
        }
    }
    checkInitLangKey(ns) {
        if (!this.langKey[ns]) {
            this.langKey[ns] = reactive({ id: 0 });
        }
    }
    loadNs(nsArray) {
        nsArray = nsArray.filter(item => (item && !this.loadedNsList.has(item)))
        let ps = nsArray.map(ns => {
            this.checkInitLangKey(ns);
            if (this.i18next.hasResourceBundle(this.lng, ns)) {
                return Promise.resolve(this.i18next.getResourceBundle(this.lng, ns));
            } else {
                return getTranJson(this.lng, ns).then(resources => {
                    if (resources) {
                        this.loadedNsList.add(ns);
                        this.i18next.addResourceBundle(this.lng, ns, resources);
                        // update langKey[ns].id
                        this.langKey[ns].id = Date.now();
                        if (this.nsLoadedMap[ns]) {
                            this.nsLoadedMap[ns]();
                        }
                        if (this.nsLoadedMap['']) {
                            this.nsLoadedMap['']();
                        }
                    }
                });
            }
        });
        return Promise.all(ps);
    }
    changeLanguage(lng) {
        this.i18next.changeLanguage(lng);
        this.lng = lng;
        var nsList = Array.from(this.loadedNsList);
        this.resetNs();
        // Multiple instances of data sources ， It is likely to refresh the page uniformly location.reload()
        // return window.location.reload();
        return this.loadNs(nsList);
    }
    // TODO  Modify the way the method passes parameters ， make js and vue The method used in the same way
    t(key, params) {
        return this.i18next.t(key, params);
    }
    $t(key, options) {
        const nsKey = key.split(':');
        if (nsKey.length <= 1) {
            //no ns
            return this.i18next.t(key, genOptions(options, !this.prefix));
        }
        const [ns, inKey] = nsKey;
        const lng = this.i18next.language;
        this.checkInitLangKey(ns);
        this.langKey[ns].id;
        //ns count > 1
        const fixedT = this.i18next.getFixedT(lng, ns);
        return fixedT(inKey, genOptions(options, !this.prefix));
    }
    $st(ns, key, options) {
        if (!Array.isArray(ns)) {
            ns = [ns];
        }
        ns.forEach(item => {
            this.checkInitLangKey(item);
            this.langKey[item].id;
        });
        const fixedT = this.i18next.getFixedT(this.lng, ns);
        return fixedT.call(this, key, genOptions(options, !this.prefix));
    }
    // lng() {
    //     return this.i18next.language;
    // }
    resetPre(_prefix) {
        this.prefix = _prefix;
        util.setStorageItem('localStorage', 'langPrefix', _prefix);
        window.location.reload();
    }
}
globalization.lngMap = {};
globalization.create = function(lng, replaceCb) {
    if (globalization.lngMap[lng]) {
        return globalization.lngMap[lng];
    } else {
        let ins = new globalization(lng, replaceCb);
        globalization.lngMap[lng] = ins;
        return ins;
    }
}
export default globalization;
