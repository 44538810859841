import { createApp } from 'vue';
import plugin from '../../plugins';

const sliderBarMap = {
    broker: () => import(/* webpackChunkName: "modules/md-sidebar/broker"*/"./broker/index.vue"),
    classic: () => import(/* webpackChunkName: "modules/md-sidebar/classic"*/"./classic/index.vue"),
    fashion: () => import(/* webpackChunkName: "modules/md-sidebar/fashion"*/"./fashion/index.vue"),
    standard: () => import(/* webpackChunkName: "modules/md-sidebar/standard"*/"./standard/index.vue"),
}



export default  async function initCopyRight(globalData = {}) {
    let Com = sliderBarMap[globalData.mdSliderBar];
    if (Com) {
        let com = await Com();
        var app = createApp(com.default || com, {});
        plugin.baseInstall(app, true).mountSelf();
    }
}