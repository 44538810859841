import numberFormat from './number-format';
import dateFormat from './date-format';
import types from '../type';
import { phoneFormat, phoneInputEvent } from "@/common/format/phone-format__US__.js";
const g_regexp = {
    unnum: /[^\d]/g,
    unnum_dot: /[^\d\.]/g,
    match_num: /[\d,]+(?:\.\d+)?/g,
    match_dollar: /(?:\$ ?)?[\d,]+(?:\.\d+)?/g,
    number: /\d{1,3}(?=(\d{3})+(\.\d+)?$)/g,
    text: /[,.?;](?=[A-Za-z])/g,
    trend: /\-?\d+(?:\.\d+)?/,
    prev_zero: /^0+(?=\d)/,
    suffix_zero: /\.0+$/,
    search: /[^\w\d\s,#.*+@'"?\-]/g
};
var g_input_event = {
    number: {
        split: g_regexp.unnum_dot,
        reg_ignore: g_regexp.prev_zero,
        reg_backspace: /\d(\d{3})(?=(,|$))/,
        rep_backspace: '$1',
        reg_format: g_regexp.number,
        rep_format: '$&,'
    },
    dollar: {
        split: g_regexp.unnum,
        reg_ignore: g_regexp.prev_zero,
        reg_backspace: /\d(\d{3})(?=(,|$))/,
        rep_backspace: '$1',
        reg_format: g_regexp.number,
        rep_format: '$&,'
    },
    dollar_no_space: {
        split: g_regexp.unnum,
        reg_ignore: g_regexp.prev_zero,
        reg_backspace: /\d(\d{3})(?=(,|$))/,
        rep_backspace: '$1',
        reg_format: g_regexp.number,
        rep_format: '$&,'
    },
    phone: phoneInputEvent(g_regexp),
    year: {
        length: 4,
        split: g_regexp.unnum,
        reg_ignore: g_regexp.prev_zero
    },
    int: {
        split: g_regexp.unnum,
        reg_ignore: g_regexp.prev_zero
    },
    one_decimal: {
        split: g_regexp.unnum_dot,
        reg_ignore: g_regexp.prev_zero,
        reg_format: /(\.\d?).*/,
        rep_format: '$1'
    },
    search: {
        split: g_regexp.search
    }
};

g_input_event.money = g_input_event.dollar;

export { numberFormat };
export { dateFormat };

export const currencyFormat = (num, units, fixed, currencySymbol) => {
    return numberFormat(num, units, fixed, currencySymbol);
};

/**
 *  formatted data ( add K or M and other units )
 * num<1e4， one decimal place   1e4<= num < 1e6  reserve 0  decimal places 
 * 1e6 <= num < 1e7, reserve   two decimal places 
 *  The rest keep one decimal place 
 * TODO  have bug?  deal with   Exceed  999M  when the number of ， abandoned , the following number 
 * @param {number} num  data that needs to be formatted 
 * eg: formatUnitsNumber(9100) -> 9.1K
 * eg: formatUnitsNumber(154300) -> 154K
 * eg: formatUnitsNumber(1543000) -> 1.54M
 * eg: formatUnitsNumber(999100000) -> 999.1M
 * eg: formatUnitsNumber(1000000000) -> 1M  bug ？？？？
 */
export const formatUnitsNumber = num => {
    let fixed = 2;
    num = parseFloat(num);
    if (num < 1e4) {
        fixed = 1;
    } else if (num < 1e6) {
        fixed = 0;
    } else {
        fixed = 2;
    }
    let formated = numberFormat(num, true, fixed),
        // TODO ???
        unit =
            formated.search('K') !== -1
                ? 'K'
                : formated.search('M') !== -1
                    ? 'M'
                    : formated.search('B') !== -1
                        ? 'B'
                        : '';
    return parseFloat(formated) + unit;
};

// export const formatNumber = numberFormat;

export const formats = {
    /**
     *  Will   The data becomes three bits  ,  separate 
     * @param {number} value  data that needs to be formatted 
     * @param {string} blank  less than 0  or the character displayed if it is not a number 
     * @param {boolean} flag  Is it necessary for less than  0  number of   Pick  blank
     * eg number(-1 ,'-' ,true) -> '-'
     * eg number(1000) -> 1,000
     */
    number(value, blank = '-', flag) {
        if (Number(value) && Number(value) < 0 && flag) {
            return blank;
        } else if (!Number(value) && !flag) {
            return blank;
        }
        return numberFormat(value, false);
    },
    /**
     *  Add to the formatted data  $  prefix 
     * @param {number} value  the number being formatted 
     * @param {boolean} noSpace $  Do you need to add a space between the number and ,true for no spaces 
     * @param {boolean} units  Do you need to add when formatting numbers   unit  (K,M)
     * @param {string} blank  The character to display when the number is invalid 
     * @param {string} fixedLength  keep decimal places 
     * eg: dollar(10000) -> $ 10,000, dollar(10000, true)  -> $10,000
     * eg: dollar(10000,false,true) -> $ 10K
     */
    currency({ value, noSpace, units = false, blank = '-', fixedLength = 2, currencySymbol }) {
        const space = noSpace ? '' : ' ';
        if (!Number(value)) {
            return blank;
        }
        return (
            currencySymbol +
            space +
            numberFormat(value, units, fixedLength)
        );
    },
    dollar(value, noSpace, units = false, blank = '-', fixedLength = 2) {
        const space = noSpace ? '' : ' ';
        if (!Number(value)) {
            return blank;
        }
        return '$' + space + numberFormat(value, units, fixedLength);
    },
    date: dateFormat
};

export const initFormatFilter = app => {
    app.config.globalProperties.$ft = {
        formatUnitNum: function(value, units, fixed, prefix) {
            return numberFormat(value, units, fixed, prefix);
        },
        formatNum: function(value, blank, flag) {
            return formats.number(value, blank, flag);
        },
        formatCurrency: function({ value, noSpace, units, blank, fixedLength, currencySymbol }) {
            return formats.currency({ value, noSpace, units, blank, fixedLength, currencySymbol });
        },
        formatDollar: function formatDollar(
            value,
            noSpace,
            unit,
            blank,
            fixedLength
        ) {
            return formats.dollar(value, noSpace, unit, blank, fixedLength);
        },
        formatPhone: phoneFormat,
        formatDate: function(value, fmt) {
            if (!value) {
                return '';
            }
            if (value instanceof Date) {
                return dateFormat(fmt, value);
            } else {
                return dateFormat(fmt, new Date(value));
            }
        },
        uppercase: function(value) {
            if (typeof value === 'string') {
                return value.toUpperCase();
            }
            return value;
        },
        capitalize: function(value) {
            if (typeof value === 'string') {
                return value.slice(0, 1).toUpperCase() + value.slice(1);
            }
            return value;
        },
        formatAddr: function(value) {
            if (typeof value === 'string') {
                return value.replace(/,/g, ', ');
            }
            return value;
        },
        formatStyle: function(style, size = 14) {
            let fontSize = style.fontSize;
            if (fontSize) {
                fontSize = parseInt(fontSize) / size + 'em';
            }
            return Object.assign({}, style, {
                fontSize
            });
        },
        url: function(value) {
            if (!value) {
                return 'javascript:void(0)';
            }
            if (value.indexOf('//') > 0) {
                return value;
            }
            return 'https://' + value;
        },
        number: function(value, fixed = 1) {
            if (isNaN(value)) {
                return '-'; 
            }
            if (value < 0) {
                return '-'; 
            }
            let res = +(+value).toFixed(fixed);
            return formats.number(res);
        },
        first: function(value) {
            if (!value) {
                return ''; 
            }
            let parts = value.split(',');
            return parts[0] || '';
        },
        trim: function(val) {
            let reg = /\s/g;
            if (reg.test(val)) {
                return `${val}`.replace(reg, '');
            } else {
                return val;
            }
        }
    };
};

export const formatValue = (inp, data) => {
    let event = g_input_event[data.mask];
    if (!event) {
        return;
    }
    let prefix
    if (data.mask === 'dollar') {
        prefix = data.currencySymbol + ' '
    } else if (data.mask === 'dollar_no_space') {
        prefix = data.currencySymbol
    }
    let ovalue = data.oldValue;
    let value = data.value;
    let caret = getCaret(inp);
    let split = event.split;
    if (event.reg_backspace && value.length + 1 === ovalue.length) {
        var spv = value.split(split),
            spov = ovalue.split(split);
        if (spv.length === spov.length - 1 && spv.join('') === spov.join('')) {
            value = value.replace(event.reg_backspace, event.rep_backspace);
            caret -= 1;
        }
    }
    value = String(value);
    caret = caret - value.substr(0, caret).split(split).length + 1;
    value = value.replace(split, '');
    if (event.reg_ignore) {
        value = value.replace(event.reg_ignore, '');
    }
    if (event.length) {
        value = value.substr(0, event.length); 
    }
    if (event.reg_format) {
        value = value.replace(event.reg_format, event.rep_format);
    }
    var idx = 0,
        count;
    while ((count = value.substring(idx, caret).split(split).length - 1) > 0) {
        idx = caret;
        caret += count;
    }
    if (value && prefix) {
        value = prefix + value;
        caret += prefix.length;
    }
    return {
        value: value,
        caret: caret
    };
};

function getCaret(inp) {
    if (isNaN(inp.selectionStart)) {
        // IE Support
        var srng = document.selection.createRange();
        if (inp.tagName.toLocaleLowerCase() === 'textarea') {
            var rng = srng.duplicate();
            rng.moveToElementText(inp);
            var pos = -1;
            while (rng.inRange(srng)) {
                rng.moveStart('character');
                pos++;
            }
            return arguments[1] === true
                ? srng.text.replace(/\r\n/g, '\n').length + pos
                : pos;
        } else {
            var len = arguments[1] === true ? srng.text.length : 0;
            srng.moveStart('character', -inp.value.length);
            return len + srng.text.length;
        }
    } else {
        return arguments[1] === true ? inp.selectionEnd : inp.selectionStart;
    }
}

function setCaretTimeout(inp, caret) {
    inp.setSelectionRange(caret, caret);
}

export const setCaret = (inp, caret) => {
    if (inp.setSelectionRange) {
        inp.focus();
        inp.setSelectionRange(caret, caret);
        setTimeout(setCaretTimeout, 0, inp, caret); // cannot be replaced here util.timeout， will delay 
    } else if (inp.createTextRange) {
        var range = inp.createTextRange();
        range.collapse(true);
        range.moveEnd('character', caret);
        range.moveStart('character', caret);
        range.select();
    }
};

export const unformats = {
    number(text, blank = '') {
        return blank && !text
            ? blank
            : types.isString(text)
                ? text.replace(g_regexp.unnum_dot, '')
                : isNaN(text)
                    ? ''
                    : text;
    }
};
