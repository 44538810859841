function getPopTip() {
    if (!getPopTip.tip) {
        getPopTip.tip = document.createElement('div');
    }
    return getPopTip.tip;
}
function handlerTip(el, binding) {
    let popTip = getPopTip();
    let value;
    if (Object.prototype.toString.call(binding.value) === '[object Function]') {
        value = binding.value();
    } else {
        value = binding.value;
    }
    let { content, direct = 'top', show } = value,
        { x, y, width, height } = el.getClientRects()[0];
    if (!show) {
        popTip.remove();
        return;
    }
    document.body.append(popTip);
    popTip.innerHTML = content;
    popTip.style.position = 'fixed';
    let left, top;
    switch (direct) {
    case 'top': {
        top = y - popTip.offsetHeight - 5;
        left = x + (width - popTip.offsetWidth) / 2;
        break;
    }
    case 'right': {
        left = x + width + 5;
        top = y + (height - popTip.offsetHeight) / 2;
        break;
    }
    case 'bottom': {
        left = x + (width - popTip.offsetWidth) / 2;
        top = y + height + 5;
        break;
    }
    case 'left': {
        left = x - 5;
        top = y + (height - popTip.offsetHeight) / 2;
        break;
    }
    }
    popTip.style.top = top + 'px';
    popTip.style.left = left + 'px';
    popTip.className = `pop-tip pop-${direct}`;
}
export default Vue => {
    Vue.directive('tip', {
        bind(el, binding) {
            let popTip = getPopTip();
            el.addEventListener('mouseover', () => {
                handlerTip(el, binding);
            });
            el.addEventListener('mouseout', () => {
                popTip.remove();
            });
            el.addEventListener('click', () => {
                let value;
                if (
                    Object.prototype.toString.call(binding.value) ===
                    '[object Function]'
                ) {
                    value = binding.value();
                } else {
                    value = binding.value;
                }
                let { clickDisappear } = value;
                if (clickDisappear) {
                    popTip.remove();
                }
            });
        }
    });
};
