<template>
    <div class="md-lazy" :class="moduleName">
        <div class="loading-box" role="status" aria-label="loading">
            <div class="loading-anim" aria-hidden="true"></div>
            <div
                class="loading-text"
                aria-hidden="true"
                :label="$t('common:loading')"
            ></div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'pinia';
import { usePageStore } from '@/store';
import util from '../common/util';

// 纯客户端走之前的逻辑, ssr相关暂时直接渲染
const onVisible = util.isClient
    ? {
        mounted() {
            var onVisible = () => {
                this.$emit('visible');
            };
            if (this.noObserve) {
                //  Under normal circumstances ,  on page load ,  Only load the above-the-fold module ,  Slide the scroll bar to load the visible modules 
                //  global closed lazyMode,  It is to load the remaining modules as long as the user has an operation ( even if you can't see )
                //  global moduleNoLazy for true,  Indicates that all modules are loaded when the page is accessed 
                (this.page.moduleNoLazy || this.platform.isScreenshot
                    ? Promise.resolve()
                    : util.getUserActionPs()
                ).then(onVisible);
            }
            util.getObserver(this.$el, onVisible);
        }
    }
    : {
        created() {
            !this.csr && this.$emit('visible');
        },
        mounted() {
            this.csr && this.$emit('visible');
        }
    };

export default Object.assign(
    {   
        i18next: {
            ns: 'common'
        },
        emits: ['visible'],
        props: ['moduleName', 'noObserve', 'hasLoadingPs', 'csr'],
        computed: {
            ...mapState(usePageStore, ['page'])
        },
        watch: {
            noObserve(value) {
                !this.csr && value && this.$emit('visible');
            },
            hasLoadingPs(value) {
                !this.csr && !value && this.$emit('visible');
            }
        }
    },
    onVisible
);
</script>

<style lang="scss">
.md-lazy {
    width: 100%;
    height: 300px;
    position: relative;

    &.md-header {
        height: 60px;

        .loading-text {
            display: none;
        }
    }

    &.md-search,
    &.md-hero {
        height: 100vh;
    }
}
</style>
