class Terminology {
    constructor(terminologyMap) {
        this.terminologyMap = terminologyMap;
        let words = Object.keys(terminologyMap);
        this.reg = new RegExp(words.map(word => `\\b${word}\\b`).join('|'), 'g');
    }
    replace(sentense) {
        if (!this.reg) {
            return sentense;
        }
        return sentense.replaceAll(this.reg, (matcheStr) => {
            return this.terminologyMap[matcheStr] || matcheStr;
        });
    }
}

export default function(terminologyMap) {
    if (terminologyMap && Object.keys(terminologyMap).length) {
        return new Terminology(terminologyMap);
    }
}
