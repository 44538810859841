export default (Vue) => {
    let fallbackImg = '';
    let errorCallback = null;
    const totalTimes = 3;
    Vue.directive('error', {
        mounted(el, binding) {
            let errorTimes = 0;
            errorCallback = () => {
                if (errorTimes >= totalTimes) {
                    return; 
                }
                errorTimes++;
                el.setAttribute('src', binding.value || fallbackImg);
                if (errorTimes >= totalTimes) {
                    el.removeEventListener('error', errorCallback, false);
                }
            };
            el.addEventListener('error', errorCallback, false);
        },
        unmounted(el) {
            el.removeEventListener('error', errorCallback, false);
        }
    });
};
