import util from '../common/util';
import { toggleDebugCache } from '@/common/debug';

export default function() {
    util.setCookie('site-search-listings', true);

    // Search optimization
    toggleDebugCache();

    //  set time zone
    util.setCookie('timezone', util.getTimeZone());
}
