<template>
    <div class="video-box vimeo" :style="videoStyle">
        <div class="video-wrap" ref="video" v-html="iframeEmbed"></div>
    </div>
</template>

<script>
import util from '@/common/util';

let vimeoPs = null;
export default {
    props: {
        src: {
            type: String,
            default: ''
        },
        autoplay: {
            type: Boolean,
            default: false
        },
        muted: {
            type: Boolean,
            default: false
        },
        controls: {
            type: Boolean,
            default: false
        },
        playsinline: {
            type: Boolean,
            default: false
        },
        preview: {
            type: String,
            default: ''
        }
    },
    data: function() {
        return {
            videoStyle: {},
            iframeEmbed: ''
        };
    },
    created() {
        this.prepare();
    },
    mounted() {
        this.videoInit();
    },
    watch: {
        src() {
            this.videoInit();
        }
    },
    methods: {
        prepare() {
            if (!vimeoPs) {
                vimeoPs = new Promise(res => {
                    util.getUserActionPs().then(() => {
                        res();
                    });
                });
            }
            return vimeoPs;
        },
        videoInit() {
            let _this = this;
            if (this.videoPlayer) {
                this.videoPlayer.destroy();
            }
            this.iframeEmbed = '<div id="iframe-embed" rel="nofollow"></div>';
            let isControls =
                this.controls || (this.platform.isIos && !this.autoplay);
            const playerOptions = {
                url: _this.src,
                width: '100%',
                height: '100%',
                autoplay: _this.autoplay,
                background: _this.autoplay && !isControls,
                controls: isControls,
                loop: _this.autoplay,
                playsinline: _this.playsinline,
                muted: _this.muted,
                portrait: false,
                byline: false,
                autopause: false
            };

            util.addScript('https://player.vimeo.com/api/player.js').then(() => {
                let iframeBox = _this.$refs.video.querySelector('#iframe-embed');
                _this.videoPlayer = new window.Vimeo.Player(
                    iframeBox,
                    playerOptions
                );

                _this.videoPlayer.on('ended', function() {
                    _this.$emit('ended');
                });

                _this.videoPlayer.ready().then(() => {
                    _this.$emit('loadedmetadata', _this);
                    let encodeUrl = encodeURI(_this.src);
                    _this.axiosIns
                        .get(
                            'https://vimeo.com/api/oembed.json?url=' +
                                encodeUrl
                        )
                        .then(data => {
                            // After the video has loaded ， Get the first frame of the video as the cover image , Thumbnail default size is small ， enlarge it here
                            if (data && data.thumbnail_url) {
                                let thumbnail_url =
                                    data.thumbnail_url.replace(
                                        /(\d+\x\d+)/,
                                        '1280x720'
                                    );
                                _this.$emit(
                                    'loadPreview',
                                    _this.preview || thumbnail_url
                                );
                            }
                        });

                    if (_this.autoplay) {
                        _this.$emit('canplay');
                    }
                    _this.prepare().then(() => {
                        _this.$emit('canplay');
                    })
                }).catch(_this.catchError);
            })
        },
        calcVideoStyle(option) {
            let { el, ratio, height, autoHeight, playOnPop } = option;
            let bannerVideo = el;
            let videoRatio = 9 / 16;
            if (
                (autoHeight && this.playsinline && !this.autoplay) ||
                (!playOnPop && ratio < videoRatio) ||
                (playOnPop && ratio > videoRatio)
            ) {
                this.videoStyle = {
                    height:
                        (playOnPop
                            ? window.innerWidth
                            : bannerVideo.offsetWidth) *
                            videoRatio +
                        'px',
                    width: '100%'
                };
            } else {
                this.videoStyle = {
                    width: height / videoRatio + 'px',
                    height: '100%'
                };
            }

            return videoRatio;
        },
        togglePlay() {
            let _this = this;
            this.videoPlayer.getPaused().then(paused => {
                if (paused) {
                    _this.playVideo();
                } else {
                    _this.pauseVideo();
                }
            });
        },
        catchError(error) {
            console.log('errorStatus', error.message);
            this.$emit('error', error);
        },
        playVideo() {
            this.videoPlayer.play().catch(this.catchError);
        },
        pauseVideo() {
            this.videoPlayer.pause().catch(this.catchError);
        },
        toggleMuted(muted) {
            if (muted) {
                this.videoPlayer.setMuted(true);
            } else {
                this.videoPlayer.setMuted(false);
            }
        }
    }
};
</script>

<style lang="scss">
.video-box.vimeo {
    position: relative;
    width: 100%;
    height: 100%;
    .video-wrap {
        height: 100%;
        width: 100%;
         #iframe-embed {
            height: 100%;
            width: 100%;
            iframe {
                max-width: none;
                width: 100%;
                height: 100%;
            }
        }
    }
    .video-action {
        position: absolute;
        .iconfont {
            position: absolute;
            width: 36px;
            height: 36px;
            left: 20px;
            bottom: 20px;
            font-size: 16px;
            color: #fff;
            border-radius: 4px;
            z-index: 9;
            cursor: pointer;
            text-align: center;
            vertical-align: middle;
            line-height: 36px;
            &::after {
                background: #282828;
                opacity: 0.3;
                border-radius: 4px;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                content: '';
                z-index: -1;
            }
        }
    }
}
</style>
