
export default [
    {
        name: "featured-listing",
        url: "/featured-listing"
    },
    {
        name: "house-listing",
        url: "/house-listing",
    },
    // {
    //     name: "listingSitemap",
    //     url: "/property-listings/sitemap",
    // },
    {
        name: "listing",
        url: "/listing"
    },
    {
        name: "listing-detail",
        url: "/listing-detail/:id/:address"
    },
    {
        name: "sold-listing",
        url: "/sold-listing"
    },
    {
        name: "sold-detail",
        url: "/sold-listing/detail/:id/:address"
    }
];





