<script>
import { h } from 'vue';
export default {
    props: {
        type: {
            type: String,
            default: 'normal'
        },
        html: {
            type: String,
            default: ''
        },
        bgColor: {
            type: String,
            default: ''
        },
        textColor: {
            type: String,
            default: ''
        }
    },
    render() {
        let tag = this.$attrs.href ? 'a' : 'button';
        let classname = [this.$attrs.class, 'site-button', this.type];
        return h(
            tag,
            {
                ...this.$attrs,
                class: classname.join(' '),
                style: this.comStyle,
                innerHTML: this.html
            }
        );
    },
    computed: {
        tag() {
            return this.$attrs.href ? 'a' : 'button';
        },
        comStyle({ bgColor, textColor }) {
            let ret = {};
            bgColor && (ret['--g-btn-background'] = bgColor);
            textColor && (ret['--g-btn-color'] = textColor);
            return ret;
        }
    }
};
</script>
<style lang="scss">
.site-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 2px 20px 0;
    border: 1px solid;
    position: relative;
    font-family: var(--mg-button-font);
    box-sizing: border-box;
    &.normal {
        border-color: var(--g-btn-background);
        background-color: var(--g-btn-background);
        color: var(--g-btn-color);
    }
    &.plain {
        background-color: transparent;
        border-color: var(--g-btn-background);
        color: var(--g-btn-background);
    }
    &:hover {
        &::after {
            content: '';
            background: rgba(25, 25, 25, 0.1);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
</style>
