import { isSSRServer } from '@/config';
const localCurrencyKey = 'chime_currency';
const localCurrencySymbol = 'chime_currency_symbol';
export const defaultCurrencyCode = 'USD';
export const defaultCurrencySymbol = '$';

const localCurrency = {
    get() {
        return this.getCookie(localCurrencyKey);
    },
    getSymbol() {
        let currencySymbol = this.getCookie(localCurrencySymbol);
        return (currencySymbol && decodeURIComponent(currencySymbol)) || '$';
    },
    set({ abbr, symbol }) {
        this.setCookie(localCurrencyKey, abbr);
        this.setCookie(
            localCurrencySymbol,
            symbol && encodeURIComponent(symbol)
        );
        // const curDomain = window.location.hostname;
        // document.cookie = `${localCurrencyKey}=${abbr};domain=${curDomain};path=/`;
        // document.cookie = `${localCurrencySymbol}=${symbol && encodeURIComponent(symbol)};domain=${curDomain};path=/`;
    },
    reset() {
        this.setCookie(localCurrencyKey, defaultCurrencyCode);
        this.setCookie(
            localCurrencySymbol,
            encodeURIComponent(defaultCurrencySymbol)
        );
        // const curDomain = window.location.hostname;
        // document.cookie = `${localCurrencyKey}=${defaultCurrencyCode};domain=${curDomain};path=/`;
        // document.cookie = `${localCurrencySymbol}=${encodeURIComponent(defaultCurrencySymbol)};domain=${curDomain};path=/`;
    }
};
if (!isSSRServer) {
    window.localCurrency = localCurrency;
}

export { localCurrency };
