const replaceFontSize = (node, size, baseSize) => {
    for (let i = 0, len = node.childNodes.length; i < len; i++) {
        const nodeItem = node.childNodes[i];
        if (nodeItem.nodeType === 1) {
            const fontSize = nodeItem.style.fontSize || baseSize;
            if (nodeItem.style.fontSize) {
                nodeItem.style.fontSize = parseInt(fontSize) / size + 'em';
            }
            // if (nodeItem.tagName === 'SPAN') {
            //     // span默认是inline, 行高是按父元素来计算的, 所以这里改成inline-block, 默认行高1.5, 就可以按本身的字体来算行高了
            //     nodeItem.style.display = 'inline-block';
            // }
            replaceFontSize(nodeItem, parseInt(fontSize), fontSize);
        }
    }
};



window.formatFontSize = function(
    rootVar,
    value,
    baseSize = '--mg-title-size'
) {
    const div = document.createElement('div');
    div.innerHTML = value;
    if (typeof baseSize === 'number') {
        replaceFontSize(div, baseSize, baseSize);
    } else {
        let varSize = rootVar[baseSize] || 14;
        replaceFontSize(div, varSize, varSize);
    }
    return div.innerHTML;
};
