// Microsoft Increased demand for advertising ， now need to put Bing Access to advertising platform ， Use the system to automatically bury points instead of the previous stage DMA Manually embed 
import util from '../util';
import { registerSyncFun, COOKIE_LEVEL } from '@/components/cookieAuthority/cookieStatus.js';
export default async() => {
    if (util.inCmsPageEditor || !util.isReallyUser) {
        return;
    }
    try {
        var globalData = window.sitePrepareData();
        var trackData = globalData.track || {};
        // UET tag ID
        //  Data brushed in from the advertising platform 
        let uetTagId = trackData.uetTagId;
        let uetHostName = trackData.uetHostName;
        if (!uetTagId) {
            return;
        }
        if (uetHostName && uetHostName !== window.location.hostname) {
            return '';
        }
        
        registerSyncFun(() => {
            (function(w, d, t, r, u) {
                var f, n, i;
                (w[u] = w[u] || []),
                (f = function() {
                    var o = {
                        ti: uetTagId
                    };
                    (o.q = w[u]),
                    (w[u] = new window.UET(o)),
                    w[u].push('pageLoad');
                }),
                (n = d.createElement(t)),
                (n.src = r),
                (n.async = 1),
                (n.onload = n.onreadystatechange =
                        function() {
                            var s = this.readyState;
                            (s && s !== 'loaded' && s !== 'complete') ||
                                (f(), (n.onload = n.onreadystatechange = null));
                        }),
                (i = d.getElementsByTagName(t)[0]),
                i.parentNode.insertBefore(n, i);
            })(window, document, 'script', '//bat.bing.com/bat.js', 'uetq');
    
            const uet_report_conversion = function() {
                window.uetq = window.uetq || [];
                window.uetq.push('event', 'submit_lead_form', {});
            };
    
            window.siteAppVm.$EventBus.$on('siteTrack', function(data) {
                if (data.trackType === 'register') {
                    //gtag event 
                    uet_report_conversion();
                }
            });
        }, {
            util,
            level: COOKIE_LEVEL.targeting
        });
        
    } catch (e) {
        console.error('bing ad  error', e);
    }
};
