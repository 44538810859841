const OPERATION_LIST = function() {
    const self = this;
    const operationList = [
        {
            icon: 'Up',
            allowOperation: self.showUP,
            title: 'Move Up'
        },
        {
            icon: 'down',
            allowOperation: self.showDown,
            title: 'Move Down'
        },
        {
            icon: 'handle',
            notAllowClassName: 'disable-draggable',
            title: 'Drag'
        },
        {
            icon: 'edit1',
            notAllowClassName: 'disable-edit',
            title: 'Edit'
        },
        {
            icon: 'delete',
            notAllowClassName: 'disable-delete',
            title: 'Delete'
        },
        {
            //Notice: Header/Footer/Hero have an edit button on the subsite, but it is still not editable, although copy is not currently supported in footer/header/hero
            icon: 'copy_02',
            allowOperation: COPYABLE_MODULES.includes(
                self.mdData['config_group'] || self.mdData.name
            ) && self.showAdd,
            notAllowClassName: 'disable-edit',
            text: 'Copy',
            className: 'copy-module',
            title: 'Copy'
        },
        {
            icon: 'Vector',
            allowOperation: self.showAdd && self.showPaste,
            text: 'Paste',
            className: 'paste-module',
            title: 'Paste'
        },
        {
            icon: 'design_01',
            allowOperation: REPRODUCIBLE_MODULES.includes(
                self.mdData['config_group'] || self.mdData.name
            ) && !!self.SiteInfo.isTemplateSite,
            className: 'custom-module',
            text: 'Custom',
            title: 'Custom'
        },
        {
            icon: 'code',
            notAllowClassName: 'disable-codeable',
            text: 'Code',
            className: 'code-module',
            title: 'Code'
        }
    ];
    return operationList.filter(item => {
        Object.assign(item, {
            allowOperation:
                !(self.ctx._cls || []).includes(item.notAllowClassName) &&
                (item.allowOperation ?? true)
        });
        return item.allowOperation;
    });
};
//module name or module group name. such as CTA
const COPYABLE_MODULES = [
    'md-team',
    'md-agent-search',
    'md-single-agent',
    'md-banner',
    'md-content',
    'CTA',
    'md-custom-code',
    'Form',
    'md-gallery',
    'md-video',
    'md-team-desc',
    'Video',
    'Form'
];
const REPRODUCIBLE_MODULES = [
    'md-team',
    'md-agent-search',
    'md-single-agent',
    'md-banner',
    'md-content',
    'CTA',
    'md-custom-code',
    'Form',
    'md-gallery',
    'md-video',
    'md-team-desc',
    'Video',
    'Form'
];
export { OPERATION_LIST, COPYABLE_MODULES, REPRODUCIBLE_MODULES };
