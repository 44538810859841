import init from "lofty_region/permission.js";

let permission = {};

const initPermission = businessType => {
    permission = init(businessType);
};

const hasPermission = resource => {
    return permission[resource];
};

export { initPermission, hasPermission };
