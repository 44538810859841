import { h } from 'vue';

var id = 1;
function genId() {
    return `wrap-item${id++}`;
}

export default {
    name: 'site-wrap-item',
    emits: ['updateStatus'],
    inject: ['wrapItems'],
    props: ['dataItem'],
    data: function() {
        return {
            id: genId()
        }
    },
    render() {
        var slot = this.$slots.default ? this.$slots.default() : [];
        var wrapItem = this.wrapItems[this.id];
        if (!wrapItem?.hidden) {
            this.$nextTick(() => {
                this.$emit('updateStatus', {
                    id: this.id,
                    width: this.$el.clientWidth,
                    dataItem: this.dataItem
                });
            });
        }
        return h('div', {  
            class: `site-wrap-item ${this.id}`, 
            style: wrapItem?.hidden ? 'display:none;' : ''
        }, slot);
    }
};
