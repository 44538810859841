<!-- <template>
    <div class="md-empty cms-use" :class="[theme,blockName]">
        cms-use md-empty md-team new-style4
        is empty
    </div>
</template> -->
<script>
import { h } from "vue";
export default {
    name: "md-empty",
    render(ctx) {
        const mdData = ctx.mdData || {};
        const blockname = mdData.name;
        let iconname = 'icon-empty';
        const siteType = false;
        const noDataTip = siteType === 'SubSite' ? ''
            : ` Please double-click to configure the ${blockname} block.`;
        return h('div', {
            class: `cms-use md-empty ${blockname} ${mdData.theme}`,
        }, [
            h('span', {
                class: `iconfont ${iconname} empty-img`
            }),
            h('i', `No Data.${noDataTip}`)
        ]);
    }
}
</script>