/**
 * 正常情况下vue3会忽略mixins里面的setup, 这个方法会递归mixins里面的setup, 并为vueConfig生成setup方法
 * @param {*} vueConfig
 * @returns
 */
const setupMerge = function(vueConfig) {
    if (vueConfig.mixins) {
        var setupForMixins = vueConfig.mixins
            .map(mixin => setupMerge(mixin))
            .filter(mixin => mixin.setup);
        if (setupForMixins.length > 0) {
            var rawSetup = vueConfig.setup;
            vueConfig.setup = function(...args) {
                var res = {};
                setupForMixins.forEach(element => {
                    let eleRes = element.setup.apply(this, args);
                    if (typeof eleRes === "function") {
                        if (!element.render) {
                            element.render = eleRes;
                        }
                        if (!element.ssrRender) {
                            element.ssrRender = eleRes;
                        }
                    } else {
                        Object.assign(res, eleRes);
                    }
                });
                if (rawSetup) {
                    // mixins里面的setup返回值都往rawRes里合并, 但不去覆盖rawRes里面的数据
                    var rawRes = rawSetup.apply(this, args);
                    if (typeof rawRes === "function") {
                        if (!vueConfig.render) {
                            vueConfig.render = rawRes;
                        }
                        if (!vueConfig.ssrRender) {
                            vueConfig.ssrRender = rawRes;
                        }
                        return res;
                    }
                    Object.keys(res).forEach(key => {
                        if (rawRes[key] === undefined) {
                            rawRes[key] = res[key];
                        }
                    });
                    return rawRes;
                } else {
                    return res;
                }
            };
        }
    }
    return vueConfig;
};

export default setupMerge;
