import util from '../common/util';
import getCssElement from './getCssElement';
function formatCustomCss(style) {
    let cssLinkReg = /https?:\/\/\S+\.css(\?\S*|#\S*|\s|$)/g;
    let links = style.match(cssLinkReg);
    let index = 0;
    let ret = [];
    if (links) {
        links.forEach(link => {
            let start = style.indexOf(link, index);
            if (start > -1) {
                ret.push(style.slice(index, start));
                let end = start + link.length;
                index = end;
                ret.push(link);
            }
        });
    }
    if (index < style.length) {
        ret.push(style.slice(index));
    }
    return ret;
}
export default function createRunner(globalData, pageData) {
    return {
        // 仅在ssr-server端调用, 生成<style>
        injectCss: function(excludePageStyle) {
            var css = '';
            if (globalData.customCode?.style) {
                css += formatCustomCss(globalData.customCode.style)
                    .map(v => getCssElement(v))
                    .join('');
                delete globalData.customCode.style;
            }
            if (pageData.css) {
                if (!excludePageStyle) {
                    css += formatCustomCss(pageData.style)
                        .map(v => getCssElement(v))
                        .join('');
                    css += getCssElement(pageData.css);
                }
                delete pageData.css;
            }
            return css;
        },
        // 仅在client端执行css
        runCss: function() {
            if (globalData.customCode?.style) {
                formatCustomCss(globalData.customCode.style).forEach(v => {
                    util.addStyle(v);
                });
            }
            if (pageData.css) {
                formatCustomCss(pageData.css).forEach(v =>
                    util.addStyle(v, false));
            }
        },
        // 仅在ssr-server端执行, 生成<script>, 并用runGlobalScript,runPageScript方法包裹定制代码, 交给ssr-client执行
        injectScript: function() {
            var code = '';
            if (globalData.customCode?.script) {
                code += `function runGlobalScript(){
                    ${globalData.customCode.script}
                };`;
                delete globalData.customCode.script;
            }
            if (pageData.script) {
                code += `function runPageScript(){
                    ${pageData.script}
                };`;
                delete pageData.script;
            }
            return code ? `<script>${code}</script>` : '';
        },
        // 仅在ssr-client和client端执行
        runScript: function() {
            try {
                if (util.isClient) {
                    if (globalData.customCode?.script) {
                        try {
                            new Function(globalData.customCode.script)();
                        } catch (e) {
                            console.error('Global Script Error: ' + e);
                        }
                    }
                    if (pageData.script) {
                        try {
                            new Function(pageData.script).call(
                                window.siteAppVm
                            );
                        } catch (e) {
                            console.error('Page Script Error: ' + e);
                        }
                    }
                } else {
                    window.runGlobalScript?.();
                    window.runPageScript?.();
                }
            } catch (e) {}
        }
    };
}
