<template>
    <div class="img-box">
        <div v-if="loading && showLoadingImg" class="loading-box">
            <div class="mg-loading"></div>
        </div>
        <div class="img-content" :style="{ zIndex: loading ? '-1' : '0' }">
            <site-img
                :src="src"
                type="img"
                :dir="dir"
                :size="size"
                :alt="alt"
                :rate="rate"
                :persent="persent"
                :errorImg="errorImg"
                @update="imgUpdate"
                @load="imgLoad($event)"
                @error="imgError"
                :lazy="lazy"
            ></site-img>
        </div>
        <slot></slot>
    </div>
</template>

<script>
import util from '@/common/util';
export default {
    emits: ['load', 'error'],
    props: {
        src: {
            validator: function(value) {
                return (
                    typeof value === 'string' ||
                    typeof value === 'object' ||
                    !value
                );
            },
            required: true,
            default: ''
        },
        dir: {
            type: String,
            default: 'width'
        },
        size: {
            type: Number,
            default: 0
        },
        rate: {
            type: Number,
            default: 2
        },
        persent: {
            type: Number,
            default: 0
        },
        alt: {
            type: String,
            default: ''
        },
        lazy: {
            type: Boolean,
            default: undefined
        },
        showLoadingImg: {
            type: Boolean,
            default: true
        },
        errorImg: {
            type: String,
            default: ''
        }
    },
    data: function() {
        return {
            loading: util.isClient
        };
    },
    inject: {
        noImgLazy: { default: false }
    },
    created() {
        if (this.noImgLazy || this.platform.isScreenshot || !util.isClient) {
            this.loading = false;
        }
    },
    methods: {
        imgUpdate() {
            if (util.isClient && !this.platform.isScreenshot) {
                this.loading = true;
            }
        },
        imgLoad($event) {
            this.loading = false;
            this.$emit('load', $event);
        },
        imgError($event) {
            this.$emit('error', $event);
        }
    }
};
</script>

<style lang="scss">
@import '../../theme/mixin.scss';

.img-box {
    position: relative;
    overflow: hidden;

    .loading-box {
        position: absolute;
        background-color: #ffffff;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .img-content {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        > img {
            width: 100%;
            height: 100%;
            @include object-fit(cover);
        }
    }
}
</style>
