import AllLang from '../common/translate/googleTranslatePlugin/data';
const customLangKey = 'lang';
const languageCookieNameMap = Object.freeze({
    'chime': 'chimetrans',
    'googlePlugin': 'chimetrans_gt'
})

export const getLanguageMenus = function(globalData) {
    switch (globalData.translatorType) {
    case 'chime':
        return globalData.sysLanguageList.map(l => {
            return {
                name: l.language,
                label: l.nativeName,
                img: l.icon
            };
        });
    case 'googlePlugin':
        return globalData.language.options.map(name => {
            let r = AllLang.find(v => v.name === name);
            return {
                name: name,
                label: r.label.toUpperCase(),
                img: r.img
            };
        });
    default:
        return [];
    }
}

export const getCurrentLanguage = function(globalData) {
    let languageMenus = getLanguageMenus(globalData);
    let lang;
    if (globalData.translatorType === 'chime') {
        lang = this.getCookie(customLangKey) || this.getCookie(languageCookieNameMap.chime) || 'en';
    } else {
        // google translator
        lang = this.getCookie(languageCookieNameMap.googlePlugin) || globalData.language?.default || 'en';
    }
    const langInMenus = languageMenus.find(item => item.name === lang);
    return langInMenus?.name || 'en';
}