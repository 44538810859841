import { mapState } from 'pinia';
import { useUserStore, usePageStore } from '@/store';
import util from '@/common/util';
import {  setCookieAll } from "../cookieStatus";
import PreferencePop from "./PreferencePop";

export const COOKIE_AUTHORITY_KEY = 'COOKIE_AUTHORITY_QUERY_V2';  //  0 开启， 1 开启过了


export default {
    i18next: {
        ns: 'common'
    },
    data: function() {
        return {
            show: false,
        };
    },
    props: {
        onDestroy: {
            type: Function,
            default: () => {}
        }
    },
    watch: {
        show(v, oldValue) {
            if (!v && oldValue) {
                localStorage.setItem(COOKIE_AUTHORITY_KEY, '1');
                this.destroy();
            }
        },
        // user() {
        //     if (localStorage.getItem(COOKIE_AUTHORITY_KEY) != '1') {
        //         localStorage.removeItem(COOKIE_AUTHORITY_KEY);
        //         this.setVisible(false);
        //         this.destroy();
        //     }
        // }
    },
    computed: {
        ...mapState(usePageStore, ['page']),
        ...mapState(useUserStore, ['user']),
        wordPressSSR({ page }) {
            return page.wordPressSSR;
        },
        description() {
            return this.$t('cookie.desc');
        },
        moreInfo() {
            return this.$t('cookie.more');
        },
        okText() {
            return this.$t('cookie.acceptAll');
        },
        rejectText() {
            return this.$t('cookie.declineAll');
        },
        preferences() {
            return this.$t('cookie.preferences');
        }
    },
    mounted() {
        if (localStorage.getItem(COOKIE_AUTHORITY_KEY) != '1') {
            this.setVisible(true);
        }
        this.$EventBus.$on("cookie_setting_has_set", this.setClose);
        // PreferencePop();
    },
    methods: {
        setClose() {
            this.show = false;
            localStorage.setItem(COOKIE_AUTHORITY_KEY, '1');
        },
        setVisible(v) {
            this.show = !!v;
        },
        destroy() {
            // 动画执行之后再移除
            setTimeout(() => {
                this.onDestroy();
            }, 1000);
        },
        setCookieAll(flag) {
            setCookieAll(util, flag).then(() => {
                this.setClose();
            });
        },
        onPreferences() {
            PreferencePop();
        }
    },
    destroy() {
        this.$EventBus.$off("cookie_setting_has_set", this.setClose);
    }
};
