<template>
    <Teleport to="body">
        <Transition
            :name="transition"
            :duration="{ enter: 300, leave: 300 }"
        >
            <div
                v-if="isShow"
                class="an-layer">
                <div class="mask" @click="maskClick"></div>
                <div class="container" :class="transition">
                    <slot :action="{ hide }"></slot>
                </div> 
            </div>
        </Transition>
    </Teleport>
</template>

<script>
export default {
    emits: ['displayChange', 'after-enter', 'after-leave'],
    props: {
        // 动画形式
        transition: {
            type: String,
            default: 'fade-in'
        },
        // 是否展示, 修改这个值会触发显示隐藏
        isShow: {
            type: Boolean
        },
        // 当点击遮罩时是否隐藏, 默认true
        hideOnClickMask: {
            type: Boolean,
            default: () => true
        },
    },
    methods: {
        show() {
            this.$emit('displayChange', true)
        },
        hide() {
            this.$emit('displayChange', false)
        },
        maskClick() {
            console.log('asdasdmaskclick')
            if (this.hideOnClickMask) {
                this.hide();
            }
        },
        handleAfterEnter() {
            this.$emit('after-enter');
        },
        handleAfterLeave() {
            // this.doDestroy();
            this.$emit('after-leave');
        }
    }
};
</script>

<style lang="scss">
.an-layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .mask{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(25, 25, 25, 0.6);
        z-index: var(--level-subhigh);
        transition: opacity 0.3s ease;
    }
    .container{
        position: absolute;
        transition: all 0.3s ease;
        display: inline-block;
        z-index: calc(var(--level-subhigh) + 1);
    }
}

.fade-in-enter-from .mask,
.fade-in-leave-to .mask,
.right-in-enter-from .mask,
.right-in-leave-to .mask,
.fade-in-enter-from .container,
.fade-in-leave-to .container {
    opacity: 0;
}


.right-in.container{
    right: 0;
}
.right-in-enter-from .container,
.right-in-leave-to .container {
    transform: translate3d(100%, 0, 0);
}


</style>
