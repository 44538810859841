/* eslint-disable */
import './swiper/swiper.scss';
import './swiper/modules/navigation.scss';
import './swiper/modules/pagination.scss';
import './swiper/modules/autoplay.scss';
import './swiper/modules/effect-fade.scss';
import './swiper/modules/effect-coverflow.scss'
import './swiper/modules/free-mode.scss';
import './swiper/modules/effect-creative.scss';
import util from '@/common/util';

import { isSSRServer } from '@/config';
import {
    Navigation,
    Pagination,
    Autoplay,
    Virtual,
    EffectFade,
    EffectCoverflow,
    Mousewheel,
    FreeMode,
    EffectCreative
} from './swiper/modules/index.mjs';

export function fixSwiperOptions(options) {
    if (isSSRServer) {
        return options;
    }
    if (!options.modules) {
        options.modules = [];
    }
    if (options.navigation) {
        options.modules.push(Navigation);
    }
    if (options.pagination) {
        options.modules.push(Pagination);
    }
    if (options.autoplay) {
        options.modules.push(Autoplay);
    }
    if (options.virtual) {
        options.modules.push(Virtual);
    }
    if (options.effect) {
        switch(options.effect) {
            case 'fade': options.modules.push(EffectFade); break;
            case 'coverflow':  options.modules.push(EffectCoverflow); break;
            case "creative": options.modules.push(EffectCreative); break;
            case "slide": break;
            default: console.error(`Unknown effect please add plugin: ${options.effect}`)
        }
    }
    if(options.mousewheel){
        options.modules.push(Mousewheel);
    }
    if(options.freeMode){
        options.modules.push(FreeMode);
    }

    if(util.inCms){
        // fixed CHIME-34810
        options.observeSlideChildren = true;
        options.observer = true;
    }

    return options;
}
export { Swiper, SwiperSlide } from './swiper/swiper-vue.mjs';
