import axios from 'axios';
import util from '../common/util';
import { normal } from './defaultSpacing';
import * as Vue from 'vue';
import { isClient } from '@/config';
import * as useChime from '@/hooks';
// import * as runtime from '@vue/runtime-dom';
import setupMerge from '../mixinSetup';
import modulesMap from '../modules/module-map';
import cmsPreview from '../components/cms-preview';
import MDError from '../components/cms-preview/md-error';
const titleSizeMap = {
    template4: 25,
    template2: 24
};
const { useRealData } = useChime;



async function loadModuleConfig(name, theme, searchCenter, isMobile) {
    let component,
        ps = [],
        moduleMap = modulesMap[name] || {};
    let base = null;
    if ((component = moduleMap[theme])) {
        ps.push(component(searchCenter, isMobile));
    } else {
        let moduleBase = await getModuleBase(name, theme);
        base =
            (moduleBase.data &&
                moduleBase.data.theme &&
                moduleBase.data.theme.base) ||
            null;
        if (base && (component = moduleMap[base])) {
            ps.push(
                component(searchCenter, isMobile),
                getModuleStyle(name, theme)
            );
        } else if ((component = moduleMap.config)) {
            ps.push(
                component(searchCenter, isMobile),
                getModuleStyle(name, theme)
            );
        } else {
            ps.push(getModuleTemplate(name, theme));
        }
    }
    return Promise.all(ps).then(([component, componentStyle]) => {
        let customMixins = componentStyle?.mixins || [];
        let rawConfig = component.default || component;
        if (componentStyle?.template) {
            rawConfig = { ...rawConfig };
            rawConfig.render = new Function('Vue', componentStyle?.template)(
                Vue
            );
            // mark is runtime function
            rawConfig.render._rc = true;
            // var r = rawConfig.render;
            // rawConfig.render = function(a,b,c,d,e,f){
            //     return r.call(this, a,b,c,d,e,f);
            // }
        }

        return {
            rawConfig,
            customMixins,
            base
        };
    });
}

function setAllModuleMounted(instance) {
    let rootIns = instance.root.proxy;
    if (!rootIns.mountedComponentNum) {
        rootIns.mountedComponentNum = 0;
    }
    if (++rootIns.mountedComponentNum === rootIns.moduleData.length) {
        rootIns.allModuleMounted = true;
        rootIns.$EventBus.$emit('all-module-mounted');
    }
}
util.loadModuleConfig = loadModuleConfig;

export default function(name, theme, globalData = {}, isMobile) {
    // let Module = components[moduleName];
    // if (!Module) {
    return loadModuleConfig(name, theme, globalData.searchCenter, isMobile)
        .then(({ rawConfig, customMixins, base }) => {
            const config = {
                props: ['mdData', 'id'],
                setup: props => {
                    const instance = Vue.getCurrentInstance();
                    Vue.provide('getMdData', function() {
                        return props.mdData 
                    });
                    Vue.onBeforeMount(() => {
                        props.mdData.vm = instance.proxy;
                    });
                    Vue.onBeforeUnmount(() => {
                        props.mdData.vm = null;
                    });
                    var state = useRealData();
                    Vue.onMounted(() => {
                        setAllModuleMounted(instance);

                        props.mdData.moduleMounted = true;
                        if (instance.proxy.$el?.nodeName !== '#comment') {
                            props.mdData.moduleRender = true;
                        }
                        instance.proxy.$EventBus.$emit('module-mounted', {
                            $el: instance.proxy.$el,
                            moduleName: state.blockName,
                            state: state
                        });
                    });
                    Vue.onUpdated(() => {
                        props.mdData.moduleRender =
                            instance.proxy.$el?.nodeName !== '#comment';
                    });
                    if (util.inCms) {
                        Vue.watch(
                            () => {
                                return props.mdData?.data;
                            },
                            v => {
                                // util.merageObj(instance.proxy,v);
                                // CHIME-30873
                                // CHIME-35606
                                Object.assign(instance.proxy, v);
                            },
                            {
                                deep: true
                            }
                        );
                    }
                    return state;
                }
            };
            if (isClient) {
                // cms preview 处理
                // console.log("rawConfig", rawConfig);
                cmsPreview(rawConfig);
            }

            // mixins的覆盖逻辑是: 后面覆盖前面, 浅层覆盖深层, 后面深层覆盖前面浅层
            let mixins = [
                // 模块代码初始数据
                rawConfig,
                // cms模块配置数据
                {
                    setup: function(props) {
                        var state = useRealData(props.mdData.data);
                        return state;
                    }
                },
                // 模块定制数据
                ...customMixins
            ];
            // 所有模块的默认数据
            let baseTheme = base || theme;
            if (!baseTheme.startsWith('layout')) {
                mixins.unshift({
                    data: function() {
                        return {
                            fullColumn: false,
                            ...normal,
                            mobileNoMargin: false,
                            titleSize: titleSizeMap[globalData.template] || 18
                        };
                    }
                });
            }
            config.mixins = mixins;
            config.ssrRender = rawConfig.ssrRender;

            var res = setupMerge(config);
            return res;
        })
        .catch(err => {
            console.error(err);
            if (isClient) {
                if (util.inCms) {
                    // cms preview 处理
                    cmsPreview(MDError);
                    return MDError;
                }
            }
            return Promise.reject(err);
        });
}

function getModuleBase(name, theme) {
    return axios.post('/api-graphql', {
        query: `{theme(name:"${name}",theme:"${theme}"){base}}`
    });
}

function customRequire(name) {
    switch (name) {
    case 'vue':
        return Vue;
    case '@/hooks':
        return useChime;
    default:
        return undefined;
    }
}
function getModuleStyle(name, theme) {
    return axios
        .post('/api-graphql', {
            query: `{theme(name:"${name}",theme:"${theme}"){code}}`
        })
        .then(async res => {
            let data = res.data || {};
            let resTheme = data.theme || {};

            let code = resTheme.code;
            if (!code) {
                console.error(res, name, theme);
                return Promise.reject('no data');
            }
            if (typeof code === 'string') {
                code = JSON.parse(code);
            }
            if (!util.isSSRServer) {
                util.addStyle(code.css, false);
            }

            return {
                mixins: code.js
                    ? [new Function('require', code.js)(customRequire)]
                    : [],
                // 定制模板单独导出
                template: code.template
            };
        });
}

function getModuleTemplate(name, theme) {
    return axios
        .post('/api-graphql', {
            query: `{module(name:"${name}"){js}theme(name:"${name}",theme:"${theme}"){code}}`
        })
        .then(res => {
            let code = res.data && res.data.theme && res.data.theme.code;
            let js = res.data && res.data.module && res.data.module.js;
            if (!code || !js) {
                return Promise.reject('no data');
            }
            if (typeof code === 'string') {
                code = JSON.parse(code);
            }
            if (!util.isSSRServer) {
                util.addStyle(code.css, false);
            }
            return {
                mixins: [
                    new Function('require', js)(customRequire),
                    code.js
                        ? new Function('require', code.js)(customRequire)
                        : {},
                    new Function('Vue', 'return ' + code.template)(Vue)
                ]
            };
        });
}
