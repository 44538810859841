import './theme/common.scss';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import getAppConfig from './getAppConfig';
import { initPermission } from './common/permission/utils';
import util from './common/util';
import initCookiesClient from './init/initCookiesClient';
import initCookies from './init/initCookies';
import { getOtherData, getGlobalData, getPageData } from './init/data/getDataFromJsp';
import handlerData, { generateThemeRootVar } from './init/data/handlerData';
import getPlatform from './isomorphic/client';
import getContainer from './init/getContainer';
import globalization from './globalization';
import createTerminology from './common/terminology';
import googleTranslateAutoPage from './common/translate/googleTranslatePlugin/auto-page';
import { getCurrentLanguage } from './globalization/tool';

import plugin from './plugins';
import Router from './common/router';
import initCookieAuthority from './components/cookieAuthority';
import { init as initRequirePhone } from './components/require-phone';
import initCopyRight from './components/copyRight';
import initGotoTop from './components/gotoTop';
import initSidebar from './components/mdSidebar';
import createRunner from './customCode';
import handlePopup from './handlePopup';
import checkTimeoutSession from './common/timeoutSession';
import otherProcessing from './otherProcessing';
import chat from './components/chat';
import termOfUse from './components/term-of-use';

import { createDownloadPlugin } from './components/download-closely/pop';
import './loadFont';
import './common/rendebug';
import gaConversion from './common/ga-conversion';
import trackBingAd from './common/track/bing-ad';
import Track from '@/common/track';
import adwerx from '@/common/track/adwerx';
import fub from '@/common/track/fub';
import './fastClick';

initPermission();

function checkRedirectUrl(globalData) {
    // if redirectUrl has value
    if (globalData.redirectUrl) {
        let mts = globalData.redirectUrl.match(/(?:\/\/([^/]+))?(\/[^?]+)/);
        if (
            mts &&
            ((mts[1] && mts[1] !== window.location.host) ||
                mts[2] !== window.location.pathname)
        ) {
            window.location = globalData.redirectUrl;
            return true;
        }
    }
}
const query = util.getQueryField();
initCookiesClient();
initCookies(util, query);

var { globalData, pageData } = handlerData({
    rawGlobalData: Object.assign({}, getGlobalData(), getOtherData()),
    rawPageData: getPageData(),
    themeRootVar: generateThemeRootVar(),
    query
});

if (!checkRedirectUrl(globalData)) {
    // init custom code runner
    var runner = createRunner(globalData, pageData);
    runner.runCss();
    if (
        util.isReallyUser &&
        !util.inCmsPageEditor &&
        process.env.CHIME_ENV == 'production'
    ) {
        // rendebug load
        window.rendebug.setOn(Date.now() % 10 === 0);
    }
    // sitePrepareData method is ready
    window.sitePrepareData = function() {
        return globalData;
    };

    let platform = getPlatform(globalData);
    let lng = getCurrentLanguage.call(platform, globalData);
    let terminology = createTerminology(globalData.terminologyMap);
    let globalizationIns = globalization.create(
        lng,
        terminology && (content => terminology.replace(content))
    );
    window.siteGlobalization = globalizationIns;

    if (globalData.translatorType == 'chime') {
        googleTranslateAutoPage(lng, globalData);
    }

    window.$pinia = createPinia();
    let appConfig = getAppConfig();
    // console.log("appConfig",appConfig);
    var app = createApp(appConfig);
    // global extension of vue
    plugin.install(app, {
        pinia: window.$pinia,
        platform,
        globalData,
        pageData,
        globalizationIns
    });
    window.siteAppVm = app.mount(getContainer(globalData.template));

    Router.multiPageInit(window.siteAppVm, globalData);

    //  execute sequence : cookie、[closely,chat]
    chat.aiConnect({ globalData }).finally(() => {
        util.asyncTaskSequenceExecutor([
            initCookieAuthority,
            () => Promise.all([chat.init({ globalData }), createDownloadPlugin()])
        ]);
    });
   

    initRequirePhone(window.siteAppVm);
    // copyright
    initCopyRight(globalData);
    initGotoTop(globalData);
    initSidebar(globalData);
    // one-time pop-up
    handlePopup(globalData, pageData.moduleData);
    checkTimeoutSession(globalData.mlsOrgs);
    // run custom script
    runner.runScript();
    // other processing in client
    otherProcessing({ globalData, platform });

    // ga conversion
    gaConversion();
    // bing  Buried
    trackBingAd();
    // adwerx
    adwerx(globalData);
    fub(globalData);
    if (util.getQueryField('enable-gtm') != '0') {
        Track.prepare();
        Track.resetEventHandler();
    }
    Track.prepareCustomGTM();
    termOfUse(globalData);
}

// eslint-disable-next-line no-undef
console.log('__BUILD_ID__', __BUILD_ID__);
// eslint-disable-next-line no-undef
console.log('__chime env__', process.env.CHIME_ENV);

if (
    document.currentScript?.src &&
    typeof document.currentScript.src === 'string'
) {
    console.log(
        'CODE CDN :',
        document.currentScript.src.startsWith('https://static.chimeroi.com')
    );
}
document.addEventListener('click', e => {
    if (e.target.nodeName === 'A' && /tel:/.test(e.target.href)) {
        let target = e.target;
        target.href = (target.href || '').replaceAll(' ', '');
    }
});
