/**
 * @description Element from invisible to visible callbacks
 * @param {*} element 
 * @param {*} cb 
 */
export function observeElementInViewport(element, cb) {
    const findInviewDom = (entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                cb()
            }
        });
    }
    let observer = new IntersectionObserver(findInviewDom, {
        root: null, 
        threshold: 0 
    });
    observer.observe(element);
}
  
  