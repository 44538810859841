import utilTool from './util-tool';
import axios from 'axios';

function uuid() {
    return utilTool.uuidv4().replace(/-/g, '').slice(0, 16)
}

function convertBase64UrlToBlob(data, type) {
    // remove url head of ， and convert to byte
    let bytes = window.atob(data.split(',')[1]);
    // handle exception , Will ascii size less than 0 is converted to greater than 0
    let ab = new ArrayBuffer(bytes.length);
    let ia = new Uint8Array(ab);
    for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/' + type });
}

const parseUploadImage = data => {
    // 如果是 File
    let suffix = '';
    if (data instanceof File) {
        suffix = data.type.split('/')[1];
        return [suffix, data];
    }
    if (typeof data === 'string') {
        suffix = data.match(/^data:image\/([^;]+);base64,/);
        if (suffix && suffix[1]) {
            return [suffix[1], convertBase64UrlToBlob(data)];
        }
    }
    console.error('file is invalid', data);
    return new Error('Invalid File ');
};

const getCurrentDate = () => {
    let date = new Date();
    return `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}`
}

const generateFileName = (fileName = '', encode) => {
    fileName = encode ? encodeURI(fileName) : fileName
    fileName = fileName ? `${uuid()}-${fileName}` : uuid()
    fileName = `${getCurrentDate()}_${fileName}`
    return fileName
}

export const uploadDocumentType = {
    system: 'SYSTEM',
    teamStatic: 'TEAM_STATIC',
    teamPublish: 'TEAM_PUBLISH',
    teamPrivate: 'TEAM_PRIVATE',
    teamSignature: 'TEAM_SIGNATURE',
}

/**
 * 
 * @param {*} data 
 * @param {*} business 
 * @param {*} documentType
 * 
 */
export const UploadImage = function(data, params = {}) {
    let { business = 'siteclient', documentType = uploadDocumentType.teamStatic, fileName } = params
    return new Promise((resolve, reject) => {
        const [type, file] = parseUploadImage(data);
        let path = `/${business}`
        fileName = `${generateFileName(fileName)}.${type}`
        axios
            .post('/api-site/aws/storage/sign', {
                imageType: true,
                path,
                fileName,
                documentType,
            })
            .then(rt => {
                axios({
                    url: rt.data.uploadUrl,
                    method: 'put',
                    timeout: 60000,
                    data: file,
                    headers: { 'Content-Type': rt.data.contentType }
                })
                    .then(() => {
                        resolve(rt.data.downloadUrl);
                    })
                    .catch(() => {
                        reject('Upload error.');
                    });
            });
    });
};

export const UploadFile = function(
    file,
    options = {}
) {
    const { encode = true,
        uploadProgress = null, business = "siteclient", documentType = uploadDocumentType.teamStatic } = options;
    return new Promise((resolve, reject) => {
        let fileName = file.name.replace(/ +/g, '_');
        fileName = generateFileName(fileName, encode)
        let path = `/${business}`
        axios
            .post('/api-site/aws/storage/sign', {
                imageType: false,
                path,
                fileName,
                documentType,
            })
            .then(rt => {
                axios({
                    url: rt.data.uploadUrl,
                    method: 'put',
                    timeout: 60000,
                    data: file,
                    headers: { 'Content-Type': rt.data.contentType },
                    onUploadProgress: uploadProgress || null,
                   
                })
                    .then(() => {
                        resolve(rt.data.downloadUrl);
                    })
                    .catch(() => {
                        reject();
                    });
            })
            .catch(_ => {
                reject();
            });
    });
};

