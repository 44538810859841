<template>
    <div class="intall-closely-plugin">
        <p class="closely-icon">
            <span
                class="iconfont icon-close-light"
                @click="onDestroy"
            ></span>
            <img
                src="https://cdn.chime.me/image/fs/sitebuild/20221227/22/original_87bc384e-2723-400f-bfb7-2e5fddbfbc54.png"
                alt="download closely"
            />
            <span class="app-name">Closely App</span>
        </p>
        <a
            class="install-plugin"
            :href="closelyDownloadLink"
        >
            {{$t('common:closelyDownload.openClosely')}}
        </a>
    </div>
</template>
<script>
import { usePageStore } from "@/store"
import { mapState } from "pinia"
export default {
    i18next: {
        ns: 'common'
    },
    props: {
        onDestroy: {
            type: Function
        } 
    },
    computed: {
        ...mapState(usePageStore, {
            closelyDownloadLink: store => store.page.closelyDownloadLink
        })
    },
    beforeUnmount() {
        this.$el.remove();
    }
}

</script>
<style lang="scss">
.intall-closely-plugin {
    height: 60px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px -4px 10px rgba(0, 10, 30, 0.1);
    z-index: calc(var(--level-subhigh) - 3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    .closely-icon {
        display: flex;
        align-items: center;
        .iconfont.icon-close-light {
            font-size: 16px;
            color: #a8a8a8;
            margin-right: 15px;
        }
        .app-name {
            color: #191919;
            font-size: 16px;
            line-height: 20px;
            font-family: var(--font-medium);
            margin-left: 8px;
            white-space: nowrap;
        }
        img {
            width: 17px;
            height: 20px;
        }
    }
    .install-plugin {
        background: #3b6ef1;
        font-family: var(--font-bold);
        border-radius: 5px;
        display: block;
        color: #fff;
        line-height: 40px;
        font-size: 14px;
        padding: 0 20px;
    }
}
</style>