<script>
import { h } from "vue";
export default {
    name: "md-error",
    render(ctx) {
        const mdData = ctx.mdData || {};
        const name = mdData.name || '';
        const  theme  = mdData.theme || ''
        return h(
            'div',
            {
                class: `md-error ${process.env.NODE_ENV}`
            },
            [
                h('span', {}, `module ${name}(theme:${theme}) load fail`)
            ]
        );
    }
}
</script>