import { resolveComponent, defineComponent, h } from 'vue'
import moduleRender from '../moduleRender';

function getItemStyle(layoutCfg = {}) {
    return (layoutCfg?.columns || []).map(col => {
        var m;
        if (/^[\d]+$/.test(col)) {
            return { width: `${col}px` }
        } else if (m = col.match(/^([\d]+)x$/i)) {
            return { flex: +m[1] }
        }
    })
}

/*
    {   
        "name": "ly-column",
        "layoutCfg": {
            "fullColumn": false,
            "columns": ['1x', '350']
        },
        "items": [{}, {}]
    }
 */
const lyColumn = defineComponent(() => {
    return (props) => {
        var modules = props.items || [];
        if (props.platform.isMobile) {
            return modules.map(md => moduleRender({
                module: md,
                globalData: props.globalData,
                mCache: props.mCache,
                platform: props.platform,
            }));
        } else {
            let itemStyle = getItemStyle(props.layoutCfg);
            return h(resolveComponent('module-box'), {
                class: 'ly-col',
                fullColumn: props.layoutCfg?.fullColumn,
                noPadding: true,
                noMargin: true,
            }, modules.map((md, idx) => moduleRender({
                module: md,
                globalData: props.globalData,
                mCache: props.mCache,
                platform: props.platform,
            }, {
                style: itemStyle[idx]
            })));
        }
    };
});
lyColumn.props = ['items', 'layoutCfg', 'globalData', 'mCache', 'platform'];

export default lyColumn;