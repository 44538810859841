import { createApp } from 'vue';
import plugin from '../../plugins';
import copyRight from './copy-right.vue';

export default function initCopyRight(globalData) {
    if (globalData.hasCopyRight) {
        var appCopyRight = createApp(copyRight, {
            showCopyright: true
        });
        plugin.baseInstall(appCopyRight, true).mountSelf();
    }
}