
export const phone_regex = {
    // 手机号匹配 区号
    region: /(?:^|\D)(?:\+\d{1,3}\s*)?(?:\(\d{3}\)\s*|\d{3}-?)\d{3}-?\d{4}(?!\d)/,
    // 格式化手机号展示
    show: /^(\d{3})(\d{3})/,
    // 常规校验
    normal: /^\d{3}-?\d{3}-?\d{4}$/,

}


// 格式化手机号展示 -> site/src/common/format/index.js
export const phoneFormat =  function(value, phoneCode) {
    value = (value || '').toString();
    if (value.includes('+')) {
        return value
    }
    return (phoneCode ? ('+' + phoneCode) : '') + value
        .replace(/^ +| +$/g, '')
        .replace(phone_regex.show, '($1) $2-');
}

// 格式化手机号展示，兼容区号比如 +1 开头  -> site/src/components/tcpaPop/completeMyProfile.vue
export const phoneFormatRegion = function(value) {
    value = (value || '').toString();
    if (!value) {
        return value;
    }
    if (value.startsWith('+1')) {
        value = value.replace(/(\+1)(\d{3})?(\d{3})?(\d{4})?/, (_, p1, p2, p3, p4) => {
            let result = p1;
            if (p2) {
                result += '-' + p2
            }
            if (p3) {
                result += '-' + p3
            }
            if (p4) {
                result += '-' + p4
            }
            return result;
        }).substring(0, 15);
    } else {
        value = value.replace(/(\d{3})?(\d{3})?(\d{4})?/, (_, p1, p2, p3) => {
            let result = p1 || '';
            if (p2) {
                result += '-' + p2
            }
            if (p3) {
                result += '-' + p3
            }
            return result;
        }).substring(0, 12);
    }
    return value;
}

// site/src/common/format/index.js
export const phoneInputEvent = (g_regexp) => {
    return {
        length: 10,
        split: g_regexp.unnum,
        reg_ignore: g_regexp.prev_zero,
        reg_backspace: /(\d{2})\d(\d)/,
        rep_backspace: '$1$2',
        reg_format: /(\d{3})(\d{3})?/,
        rep_format(_, $1, $2, idx, str) {
            return (
                $1 +
                (str.length > 3
                    ? '-' + ($2 ? $2 + (str.length > 6 ? '-' : '') : '')
                    : '')
            );
        }
    }
}


// 部分隐藏号码 site/src/components/registerPhoneValidPop/components/phoneValid.vue
export const phoneHidden = (phoneNumber) => {
    if (!phoneNumber || typeof phoneNumber != 'string') {
        return '';
    }
    const phone = phoneNumber.replace(
        /(\d{2})(\d{1})(\d{3})(\d{2})(\d{2})/g,
        '$1x xxx xx$5'
    );
    return '+1 ' + phone;
}

// site/src/components/sign-log/advertising-register/step/Phone.vue
export const phoneLoginFormat = (val) => {
    if (typeof val != 'string' || !val) {
        return ''
    }
    return   val
        .replace(/^ +| +$/g, '')
        .replace(/^(\d{3})(\d{3})/, '$1-$2-');
}


// 校验手机号 site/src/modules/md-calendar/broker/calendar-input.vue
export const validatePhone = function({ val, required }) {
    val = (val || '').trim();
    if (val.length) {
        const reg = phone_regex.normal;
        const error = this.$t('common:validate.phoneInvalid');
        if (!reg.test(val)) {
            return error; 
        }
        return this.axiosIns
            .get(`/api-site/phoneValidate?phoneNumber=${val}`)
            .then(res => {
                return res?.status?.code == 0
                    ? ''
                    : res?.status?.msg || error;
            })
            .catch(e => {
                return e.errorInfo;
            });
    } else {
        if (required) {
            return this.$t('common:validate.fieldRequired')
        }
    }
    return '';
}