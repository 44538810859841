import util from './util';
import { getStore } from '@/store';
import { watch } from 'vue';
let timeoutSessionDuration = parseInt(localStorage.getItem('mls_timeout_session_debug'));
let log = () => {}
if (!Number.isNaN(timeoutSessionDuration) && timeoutSessionDuration > 0) {
    timeoutSessionDuration = Math.min(Math.max(timeoutSessionDuration, 5), 60);
    log = (data) => {
        console.log('TIMEOUT_SESSION:', data); 
    }
} else {
    timeoutSessionDuration = 60
}
const maxTime =  timeoutSessionDuration * 60 * 1000;
const intervalTime =  60 * 1000;
const events = ['mousemove', 'touchstart', 'touchmove', 'scroll', 'wheel'];
const localKey = 'mls_timeout_session'

class TimeoutSession {
    constructor() {
        this.timer = 0;
        this.count = 0;
        this.isDoingTimeout = false;
        this.onUserAction = util.debounce(_ => {
            localStorage.setItem(localKey, Date.now());
        }, 500);
        const userStore = getStore('user');
        watch(_ => userStore.hasLoggedIn, (hasLoggedIn) => {
            if (!hasLoggedIn) {
                return this.stop();
            }
            this.start();
        }, {
            immediate: true
        })  
    } 
    logout() {
        log(`logout`);
        if (this.isDoingTimeout) {
            return;
        }
        this.isDoingTimeout = true;
        fetch('/api-site/logout').finally(_ => {
            this.stop();
            const globalData = getStore('page').page;
            window.siteAppVm.updateChat(null, true);
            const userStore = getStore('user');
            userStore.logout();
            const resizeEvent = new UIEvent('resize', { /* optional event options */ });
            window.dispatchEvent(resizeEvent);
            this.isDoingTimeout = false;
        })
    }
    start() {
        log('start');
        this.timer = setInterval(_ => {
            let lastTime = localStorage.getItem(localKey);
            if (!lastTime) {
                return;
            }
            const pastTime = Date.now() - lastTime;
            log('Last action is ' + pastTime + ' ago.');
            if (pastTime >= maxTime) {
                this.logout()
            }
        }, intervalTime);
        localStorage.setItem(localKey, Date.now());
        events.forEach(eventName => window.addEventListener(eventName, this.onUserAction, { passive: true }))
    }
    stop() {
        log('stop');
        clearInterval(this.timer);  
        this.timer = 0;
        events.forEach(eventName => window.removeEventListener(eventName, this.onUserAction));
    }
}

//触发时机：登陆 & 发现登陆
export default function checkTimeoutSession(mlsOrgs = []) {
    const timeoutSession = mlsOrgs.some(mls => mls.timeoutSession);
    if (!timeoutSession) {
        return;
    }
    const isPopup = util.getQueryField('isPopup') === '0';
    if (isPopup) {
        return;
    }
    return new TimeoutSession();
}