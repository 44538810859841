import util from '@/common/util';
import { initFingerprint, getDeviceId } from '@/common/fingerprint';
import { getFromDetailPageParam } from '@/common/business/agent-detail';

export const EXTENT_TYPE = {
    LISTING: 'LISTING',
    QUICKREPLY: 'QUICKREPLY'
};

// chime-chat  various services 
export default {
    /**
     *
     * @param {*} sessionInfo
     * sessionInfo.userId  for this station  agentId
     * sessionInfo.sessionId  conversation  Id  from  chatbox_visitor_session_id cookie  extract from 
     *  If any, store the  cookie  None or nothing 
     */
    async getVisitorToken(sessionInfo) {
        //@todo: if fingerprint needs to implement with other endpoints/interfaces
        //       then fingerprint.init() should move in site-init
        //       initially place inside the requestAdapter() but needs to convert to async/await
        await initFingerprint();

        const deviceId = getDeviceId();
        //  The return result is  {data,status}  format 
        let { data } = await window.siteAxios.get(
            '/api/chatbot/ai/assistant/visitors/enable',
            {
                params: { ...sessionInfo, ...deviceId, _t: Date.now() }
            }
        );
        // TODO:  Configure how to get from the database here , If configured  AI  mode returns
        return data;
    },
    //  third party  chatbox  Trigger login logic 
    async thirdPartySignLog(authData) {
        let res = await window.siteAxios.post('/api-site/third-party/login', authData);
        let { data } = res;
        return data;
    },
    async thirdPartyRegister(registerData) {
        let res = await window.siteAxios.post(
            '/api-site/third-party/register',
            getFromDetailPageParam(registerData)
        );
        let { data } = res;
        // TODO:  Here you must ensure that the registration is successful 
        return data;
    },
    async getAgentInfo(id = 0) {
        let res = await window.siteAxios.post('/api-graphql', {
            query: `{agent(id:${id}){fullName,image,headShots{photoFiveByThree,photoOneByOne,photoThreeByFour}}}`
        });

        return res;
    },
    async getCompanyInfo() {
        let {
            data: { logo }
        } = await window.siteAxios.get('/api-site/place-data', {
            params: {
                fetcher: 'data',
                source: 'logo'
            }
        });
        let {
            data: { company = {} }
        } = await window.siteAxios.post('/api-graphql', {
            query: `{company{name}}`
        });
        return {
            name: company.name,
            img: logo
        };
    },

    pushStatus(userInfo) {
        if (userInfo) {
            userInfo.t = Date.now();
        }
        return window.siteAxios.get('/api/chatbot/AI/assistant/lead/push/status', {
            params: userInfo
        });
    },
    //  The service triggered when the interface is sent successfully 
    addChat(msg) {
        return window.siteAxios.get('/api-site/addChat', {
            params: msg
        });
    },
    getQuickReply(pageType, agentId) {
        let url = `/api/chatbot/quick-reply-config/${pageType}`;
        if (agentId) {
            url += `?agentId=${agentId}`;
        }
        return window.siteAxios.get(url);
    },
    addChatToAgent(msg) {
        return window.siteAxios.post('/api/chatbot/visit/chat/agent', msg);
    },
    leaveMsg(data) {
        return window.siteAxios.post('/api-site/leaveMsg', data);
    },
    getChatToken() {
        return window.siteAxios.post('/api-site/getLeadAccid');
    },
    sendSiteEvent(data) {
        return window.siteAxios.post('/api/chatbot/visit/chat/site-event', data);
    },
    getVisitorsToken(visitLeadId) {
        return window.siteAxios.get(
            `/api/chatbot/ai/assistant/visitors/nimtoken?visitId=${visitLeadId}`
        );
    },

    // twilio start
    /**
         Obtain  twilio  Authorization of  token
     */
    async getTwilioTokenByAccid(agentAccid, leadAccid) {
        return window.siteAxios.get(
            `/api/chat/twilio/account?fromIdentity=${agentAccid}&toIdentity=${leadAccid}`
        );
    },
    /**
     *  Obtain  twilio  of  conversation
     * @param {*} data  {friendlyName:string,fromIdentity:string,toIdentityList:Array<string>, conversationType: 'group_chat'|'single_chat'|'notification',attributes: Map<string,Object> }
     * @returns
     */
    getTwilioConversation(data) {
        return window.siteAxios.post('/api/chat/twilio/conversation', data);
    },
    // twilio end
    registerNim(accids = []) {
        return window.siteAxios.post('/api/chat/registered/nimtoken/site', accids);
    },
    getYunxinAppKey(teamId) {
        return window.siteAxios.get('/api/chat/nimchat/yunxin/key/info/site', {
            params: { teamId }
        });
    },
    // Get avatar frame data 
    getFrameData(agentId) {
        let timezone = util.getCookie('timezone');
        return window.siteAxios.get(`/api-site/festival/info/${agentId}`, {
            params: {
                timezone
            }
        });
    }
};
