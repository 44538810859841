import popMgr from '../global/popMgr';
import util from '../../common/util.js';
const templateMap = {
    template1: 'classic',
    template2: 'classic',
    standard: 'standard',
    template4: 'fashion',
    broker: 'broker'
};

const themeMap = {
    classic: () => import('./classic/index.vue'),
    standard: () => import('./classic/index.vue'),
    fashion: () => import('./classic/index.vue'),
    broker: () => import('./classic/index.vue')
};

const requirePhone = {
    prepare(theme) {
        if (!theme || !themeMap[theme]) {
            const template = window.sitePrepareData().template;
            theme = templateMap[template] || 'classic';
        }
        if (typeof themeMap[theme] === 'function') {
            return themeMap[theme]()
                .then(f => f.default)
                .then(requirePhonePop => {
                    themeMap[theme] = requirePhonePop;
                    return requirePhonePop;
                });
        } else {
            return Promise.resolve(themeMap[theme]);
        }
    },

    open(theme, options = {}) {
        options.theme = theme;
        if (popMgr.currentPops.some(pop => pop.name === 'requirePhone')) {
            return;
        }
        this.prepare(theme).then(config => {
            popMgr.create(
                config,
                {
                    name: 'requirePhone',
                    theme,
                    options
                },
                'hideOtherPop'
            );
        });
    }
};
window._requirePhonePop = requirePhone;

export const init = siteAppVm => {
    //  Check if a pop-up window is required 
    siteAppVm.$EventBus.$on('userLogin', user => {
        let date = localStorage.getItem(
            `${user.emailAddress || user.account}-last-time-require-phone-date`
        );
        let today = new Date().toDateString();
        // _ads_source = 15  description from fb website registered 。 In theory, it should be given to the backend phoneNumber of ， Special treatment first 
        //  necessary  account 、 fixed  CHIME-19635
        let isThirdRegistered = util.getCookie('_ads_source') == '15';
        if (
            !isThirdRegistered &&
            !user.phoneNumber &&
            user.account &&
            ((date && today !== date) || !date)
        ) {
            localStorage.setItem(
                `${user.emailAddress || user.account
                }-last-time-require-phone-date`,
                today
            );
            setTimeout(() => {
                const cur = popMgr.getCurrent();
                if (cur) {
                    popMgr.$once(cur, 'onVnodeBeforeUnmount', () => {
                        onDestroyOpenRequire(cur, user);
                    });
                } else {
                    openRequirePhone(user);
                }
            }, 2000);
        }
    });
};

let hasRequire = false;
function onDestroyOpenRequire(popIns, user) {
    if (popIns.name === 'requirePhone') {
        hasRequire = false;
        return;
    }
    if (hasRequire) {
        return; 
    }
    openRequirePhone(user);
    hasRequire = true;
}

function openRequirePhone(user) {
    let registration = window.sitePrepareData().registration;
    requirePhone.open(
        window.sitePrepareData && window.sitePrepareData().template,
        {
            title:
                registration.requirePhoneTitle ||
                (user.firstLogin
                    ? window.siteGlobalization.$t('common:popup.title.default')
                    : window.siteGlobalization.$t('common:popup.title.other')),
            subTitle:
                registration.requirePhoneSubTitle ||
                window.siteGlobalization.$t('common:popup.subTitle'),
            allowClose: registration.requirePhoneAllowClose
        }
    );
}
export default requirePhone;
