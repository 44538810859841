import util from '@/common/util';
export function colorToRgba(color) {
    try {
        let hex = color.trim();
        let r,
            g,
            b,
            a = 1;
        if (hex.startsWith('#')) {
            hex = hex.replace('#', '');
            if (hex.length === 3) {
                hex = hex
                    .split('')
                    .map(char => char + char)
                    .join('');
            }
            r = parseInt(hex.substring(0, 2), 16);
            g = parseInt(hex.substring(2, 4), 16);
            b = parseInt(hex.substring(4, 6), 16);
        } else if (hex.startsWith('rgba')) {
            const parts = hex.match(
                /rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+\.?\d*)\)/
            );
            r = parseInt(parts[1], 10);
            g = parseInt(parts[2], 10);
            b = parseInt(parts[3], 10);
            a = parseFloat(parts[4]);
        } else if (hex.startsWith('rgb')) {
            const parts = hex.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
            r = parseInt(parts[1], 10);
            g = parseInt(parts[2], 10);
            b = parseInt(parts[3], 10);
        } else {
            return color;
        }
        return `rgba(${r}, ${g}, ${b}, ${a})`;
    } catch (error) {
        console.error("colorToRgba error ==", error, color);
        return color;
    }
}
export const parseRgba = function(rgba = '') {
    let rgbaValues = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/);
    if (!rgbaValues) {
        console.error('parseRgba error: invalid', rgba);
        return rgba;
    }
    return {
        r: parseInt(rgbaValues[1]),
        g: parseInt(rgbaValues[2]),
        b: parseInt(rgbaValues[3]),
        a: parseFloat(rgbaValues[4])
    };
};
export const blendRgba = function(rgba1, rgba2) {
    if (!rgba1 || !rgba2) {
        return ''; 
    }
    let c1 = parseRgba(colorToRgba(rgba1));
    let c2 = parseRgba(colorToRgba(rgba2));
    if (c1.a === 1 && c2.a !== 1) {
        return blendRgba(rgba2, rgba1);
    }
    let newAlpha = c1.a + c2.a * (1 - c1.a);
    let r = Math.round((c1.r * c1.a + c2.r * c2.a * (1 - c1.a)) / newAlpha);
    let g = Math.round((c1.g * c1.a + c2.g * c2.a * (1 - c1.a)) / newAlpha);
    let b = Math.round((c1.b * c1.a + c2.b * c2.a * (1 - c1.a)) / newAlpha);
    return `rgba(${r}, ${g}, ${b}, ${newAlpha.toFixed(2)})`;
};

export const colorToAlpha = (color, alpha) => {
    if (!color) {
        return ''; 
    }
    let { r, g, b } = parseRgba(colorToRgba(color));
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const colorVarMap = {
    '--g-primary-color': ['colorMap.title'],
    '--g-text-color': ['colorMap.text'],
    '--g-tip-color': ['colorMap.tip'],
    '--g-btn-background': ['colorMap.btnFill'],
    '--g-btn-color': ['colorMap.btnText'],
    '--g-bg-color': ['bgColor', 'backgroundColor']
};

export const generateColorMap = function(params, exclude) {
    let ret = {};
    let colorMap = params
        ? Object.assign({}, colorVarMap, params)
        : colorVarMap;
    if (exclude) {
        exclude.forEach(k => {
            colorMap[k] = null;
        });
    }
    for (let key in colorMap) {
        let paths = colorMap[key];
        for (let i = 0; i < paths?.length; i++) {
            let value = util.getValueByPath(this, paths[i]);
            if (value) {
                ret[key] = value;
                break;
            }
        }
    }
    return ret;
};
