<template>
    <div class="register-questions-container">
        <h2>{{ questionTitle }}</h2>
        <div class="register-questions-list">
            <template
                v-for="question in newQuestionsList"
                :key="question.behavior"
            >
                <div
                    class="register-questions-item"
                    v-for="item in question.item"
                    :key="item.label"
                    @click="handClick(item, question.behavior)"
                >
                    <div class="register-questions-icon">
                        <i class="iconfont" :class="item.icon"></i>
                    </div>
                    <div class="register-questions-label">{{ item.label }}</div>
                </div>
            </template>
        </div>
        <div
            class="previous-btn"
            v-if="showPreviousBtn"
            @click="handlePrevious"
        >
            {{ $t('adNewQuestion.previous') }}
        </div>
    </div>
</template>

<script>
export default {
    i18next: {
        ns: 'common'
    },
    data: function() {
        return {
            pageNum: 1,
            pageSize: 1,
            currentHouseType: '',
            canClick: true
        };
    },
    props: {
        questionConfigs: {
            type: Array,
            default: () => {
                return [];
            }
        },
        houseType: {
            type: String,
            default: ''
        },
        isBuyerRole: {
            type: Boolean,
            default: false
        },
        unAnswerIndex: {
            type: Number,
            default: 0
        }
    },
    computed: {
        questionTitle() {
            return this.$t('adNewQuestion.title');
        },
        newQuestionsList() {
            return this.questionConfigs.slice(
                (this.pageNum - 1) * this.pageSize,
                this.pageNum * this.pageSize
            );
        },
        showPreviousBtn() {
            return this.pageNum > 1;
        }
    },
    mounted() {
        if (this.unAnswerIndex > 0) {
            this.pageNum = this.unAnswerIndex + 1;
        }
    },
    methods: {
        handClick(item) {
            if (!this.canClick) {
                return;
            }
            this.canClick = false;
            this.axiosIns
                .post(item.ajax.url, {
                    ...item.ajax.data
                })
                .then(_ => {
                    if (item?.note?.desc) {
                        const params = {
                            note: item?.note?.desc
                        };
                        this.currentHouseType = item.type;
                        this.$emit('changeHouseType', item.type);
                        this.$emit('addNote', params);
                    }
                    if (
                        this.questionConfigs.length <
                        this.pageSize * (this.pageNum + 1)
                    ) {
                        this.$emit('finishedQuestions', {
                            isFinished: true,
                            goFinishedPage: true
                        });
                        return;
                    }
                    this.pageNum++;
                    this.canClick = true;
                });
        },
        handlePrevious() {
            this.pageNum--;
        }
    }
};
</script>

<style lang="scss" scope>
.register-questions-container {
    height: 100%;
    h2 {
        flex-shrink: 0;
        font-size: 24px;
        font-weight: 700;
        line-height: 30px;
        color: #191919;
        text-align: center;
    }
    .register-questions-list {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        .register-questions-item {
            display: flex;
            align-items: center;
            gap: 15px;
            width: 100%;
            padding: 25px 15px;
            border: 1px solid rgba(25, 25, 25, 0.1);
            border-radius: 12px;
            cursor: pointer;
            .register-questions-icon {
                flex-shrink: 0;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 20px;
                text-align: center;
                position: relative;
                &::before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background: var(--color-theme);
                    opacity: 0.1;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                }
                i {
                    color: var(--color-theme);
                    opacity: 1;
                }
            }

            .register-questions-label {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: #191919;
            }
            &:hover,
            &.active {
                border-color: var(--color-theme);
            }
        }
    }
    .previous-btn {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #a8a8a8;
        margin-top: 150px;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
