<template>
    <div class="video-box def" :class="videoClass">
        <video ref="video" preload="auto" v-bind="attrs" @click="playOrPause"></video>
        <schema-info :info="seoSchema" v-if="seoSchema && attrs.src" />
    </div>
</template>

<script>
import { getVideoSchemeInfo } from '@/common/schemaInfo.js';
import util from '@/common/util';

let nativePs = null;
export default {
    name: 'defaultVideo',
    props: {
        muted: {
            type: Boolean,
            default: false
        },
        preview: {
            type: String,
            default: ''
        }
    },
    data: function() {
        return {
            videoClass: '',
            duration: ''
        };
    },
    computed: {
        attrs() {
            var attrs = {};
            Object.keys(this.$attrs).forEach(key => {
                if (key.slice(0, 2) !== 'on') {
                    attrs[key] = this.$attrs[key];
                }
            })

            if (attrs.playsinline) {
                attrs.playsinline = 'playsinline';
            }
            return attrs;
        },
        seoSchema({ $attrs, duration }) {
            let { src, preview } = $attrs;
            return getVideoSchemeInfo({
                embedUrl: src,
                thumbnailUrl: preview,
                duration
            });
        }
    },
    mounted() {
        this.$video = this.$refs.video;
        this.initVideo();
    },
    beforeUnmount() {
        this.$video.removeEventListener(
            'loadedmetadata',
            this.loadedmetadataFn
        );
    },
    methods: {
        prepare() {
            if (!nativePs) {
                nativePs = new Promise(resolve => {
                    util.getUserActionPs().then(() => {
                        resolve();
                    });
                });
            }
            return nativePs;
        },
        initVideo() {
            this.toggleMuted(this.muted);
            if (!this.playOnPop) {
                this.videoClass = 'is-contain';
            }

            if (this.preview) {
                this.$emit('loadPreview');
            }

            this.canplayFn = () => {
                this.duration = parseInt(this.$video.duration);
                this.$emit('loadedmetadata', this);
                this.prepare().then(() => {
                    this.$emit('canplay');
                });
            };
            
           
            this.$video.addEventListener('loadedmetadata', this.canplayFn);
        },
        calcVideoStyle(option) {
            let { autoHeight, ratio, playOnPop } = option;
            let videoRatio = this.$video.videoWidth / this.$video.videoHeight;
            if (
                (autoHeight &&
                    this.attrs.playsinline &&
                    !this.attrs.autoplay) ||
                (playOnPop && ratio > videoRatio)
            ) {
                this.videoClass = 'auto-height';
            } else {
                this.videoClass = 'auto-width';
            }

            return videoRatio;
        },
        playOrPause() {
            if (!this.platform.isMobile) {
                return;
            }

            if (!this.$video.paused) {
                this.$emit('pause');
            } else {
                this.$emit('play');
            }
        },
        playVideo() {
            console.log('-----playvideo-----');
            this.$video.play();
        },
        toggleMuted(muted) {
            this.$video.muted = muted;
        },
        pauseVideo() {
            this.$video.pause();
        }
    }
};
</script>

<style lang="scss">
.video-box.def {
    width: 100%;
    height: 100%;
    video {
        width: 100%;
        height: 100%;
    }
}
</style>
