import util from '@/common/util';
import { PrefetchMethod } from '@/config';
import { findModuleStore } from '@/store/helpers';

export default {
    install: function(app) {
        app._currFiles = new Set();
        let mixin = {
            /**
             * 纯客户端时: PrefetchMethod就是created, 在模块组件上执行, 这个方法在mixin里面会早于配置项上的created
             * SSR服务端时: PrefetchMethod是serverPrefetch, 在模块组件上执行, 拿到的数据要放store里面, 这样在注水阶段客户端才能拿到继续使用
             * SSR客户端时: PrefetchMethod是serverPrefetch, 不会执行
             * 会在模块实例上保存一个asyncDataPromise
             */
            [PrefetchMethod]() {
                if (util.isSSRServer) {
                    this.$options._currFile &&
                        app._currFiles.add(this.$options._currFile());
                }
                const isBlockRoot = this.mdData?.isBlockRoot;
                const asyncData = this.$options.asyncData;
                if (typeof asyncData === 'function' && isBlockRoot) {
                    let store = findModuleStore.call(this);
                    this.asyncDataPromise = asyncData.call(this, store);
                    return this.asyncDataPromise;
                } else {
                    if (this.$parent && this.$parent.asyncDataPromise) {
                        return (this.asyncDataPromise =
                            this.$parent.asyncDataPromise);
                    }
                }
            },
            beforeMount() {
                const afterAsyncData = this.$options.afterAsyncData;
                if (afterAsyncData) {
                    if (util.isSSRClient) {
                        afterAsyncData.call(this);
                    } else if (this.asyncDataPromise) {
                        this.asyncDataPromise.then(() => afterAsyncData.call(this))
                    }
                }
            }
        };
        if (util.isSSRClient) {
            // 处理定制模块里面的serverPrefetch的执行
            mixin.created = function() {
                if (this.$options.serverPrefetch) {
                    // 定制模块，因为不会在服务端运行 serverPrefetch，所以客户端手动调用
                    let parent = this;
                    while (parent && !parent.mdData?.isBlockRoot) {
                        parent = parent.$parent;
                    }
                    if (!parent || !parent._csr) {
                        return;
                    }
                    let run = Array.isArray(this.$options.serverPrefetch) ? this.$options.serverPrefetch : [this.$options.serverPrefetch];
                    run.forEach((fn) => {
                        fn.call(this);
                    });
                }
            }
        }
        app.mixin(mixin);
    }
};

export const mapAsyncData = function(method) {
    if (util.isFunction(method)) {
        return function() {
            let store = findModuleStore.call(this);
            return method.apply(this, [store, ...arguments]);
        };
    } else {
        var res = {};
        for (var attr in method) {
            res[attr] = mapAsyncData(method[attr]);
        }
        return res;
    }
};
