import util  from "../common/util";
import { watch, reactive } from "vue"

const useCmsGlobal = (target, key) => {
    if (!util.inCmsPageEditor) {
        return target;
    }
    const state = reactive(JSON.parse(JSON.stringify(target)));
    watch(() => {
        return {
            ...target
        }
    }, (a) => {
        Object.assign(state, a);
    }, { deep: true })
    if (window.getStore) {
        const global = window.getStore('page')?.page;
        if (global) {
            watch(() => {
                if (key) {
                    return global[key];
                }
                return global;
            }, (a) => {
                Object.assign(state, a);
            }, { deep: true });
        }
    }
   
    return state;
}
export default useCmsGlobal;