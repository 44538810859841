<template>
    <div
        class="input_container"
        :class="containerClass"
        @mouseenter="hovering = true"
        @mouseleave="hovering = false"
    >
        <slot name="prepend"></slot>
        <div class="input_wrap">
            <div class="search" @click="search" v-if="searchable">
                <span class="iconfont icon-quick-search-block"></span>
            </div>
            <input
                :ref="onRef"
                :value="value"
                class="input"
                :class="inputClass"
                :disabled="inputDisabled"
                :readonly="readonly"
                @focus="handleFocus"
                @blur="handleBlur"
                @change="handleChange"
                @input="handleInput"
                @compositionstart="handleCompositionStart"
                @compositionend="handleCompositionEnd"
                @keyup.enter="$emit('enter')"
                v-bind="$attrs"
            />
            <div class="clear" @click="clear" v-show="showClear">
                <span class="iconfont icon-clear_circle_outlined"></span>
            </div>
        </div>
        <slot name="append"></slot>
    </div>
</template>

<script>
import classNames from 'classnames';

export default {
    name: 'im-input',
    emits: [
        'update:value',
        'input',
        'change',
        'clear',
        'search',
        'focus',
        'blur'
    ],
    props: {
        theme: {
            validator: value =>
                ['standard', 'template1', 'template4', 'broker'].indexOf(
                    value
                ) !== -1,
            default: 'standard'
        },
        textAlign: {
            validator: value =>
                ['left', 'right', 'center'].indexOf(value) !== -1,
            default: 'left'
        },
        value: [String, Number],
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        searchable: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        customContainerClass: {
            type: String,
            default: ''
        },
        customInputClass: {
            type: String,
            default: ''
        }
    },
    data: function() {
        return {
            focused: false,
            isComposing: false,
            hovering: false,
            canChangeMoneyValue: true,
            inputRef: null
        };
    },
    computed: {
        containerClass() {
            return classNames(
                this.theme,
                this.customContainerClass,
                { focus: this.focused },
                { disabled: this.inputDisabled },
                { readonly: this.readonly }
            );
        },
        inputClass() {
            return classNames(
                this.textAlign,
                this.customInputClass,
                { rpad35: this.clearable },
                { lpad35: this.searchable }
            );
        },
        inputDisabled() {
            return this.disabled;
        },
        showClear() {
            return (
                this.clearable &&
                !this.inputDisabled &&
                !this.readonly &&
                !!this.nativeInputValue &&
                (this.focused || this.hovering)
            );
        },
        nativeInputValue() {
            return this.value === null || this.value === undefined
                ? ''
                : String(this.value);
        }
    },
    watch: {
        nativeInputValue() {
            this.setNativeInputValue();
        }
    },
    mounted() {
        this.setNativeInputValue();
    },
    methods: {
        onRef(e) {
            this.inputRef = e;
        },
        //  Obtain input example 
        getInput() {
            return this.inputRef;
        },
        focus() {
            let input = this.getInput();
            input && input.focus();
        },
        blur() {
            let input = this.getInput();
            input && input.blur();
        },
        //  click clear clear text box
        clear() {
            this.$emit('input', '');
            this.$emit('change', '');
            this.$emit('clear', '');
        },
        //  click search  button
        search() {
            this.$emit('search', this.value);
        },
        //  monitor focus
        handleFocus() {
            this.focused = true;
            this.$emit('focus');
        },
        //  monitor  blur
        handleBlur() {
            this.focused = false;
            this.$emit('blur');
        },
        //  monitor input
        handleInput(e) {
            if (this.isComposing) {
                return; 
            }
            if (e.target.value === this.nativeInputValue) {
                return; 
            }
            this.$emit('update:value', e.target.value);
            this.$emit('input', e.target.value);
            this.$nextTick(this.setNativeInputValue);
        },
        //  monitor  compostion start
        handleCompositionStart() {
            this.isComposing = true;
        },
        //  monitor  compostion end
        handleCompositionEnd($event) {
            if (this.isComposing) {
                this.isComposing = false;
                this.handleInput($event);
            }
        },
        //  monitor  change
        handleChange($event) {
            let targetValue = $event.target.value;
            this.$emit('change', targetValue);
        },
        setNativeInputValue() {
            const input = this.getInput();
            if (!input) {
                return; 
            }
            if (input.value === this.nativeInputValue) {
                return; 
            }
            input.value = this.nativeInputValue;
        }
    }
};
</script>

<style lang="scss">
/*  styles for different themes  */
/*  layout  */
.input_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    background: #fff;
    &.standard {
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #ebecf1;
    }
    &.broker {
        border: solid 1px #dadada;
        border-radius: 5px;
    }
    &.template4 {
        border: solid 2px #d6dae8;
    }
    &.template1 {
        border-radius: 2px;
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #d5d5d5;
    }
    &.standard.readonly {
        .input {
            color: #202437;
        }
    }
    &.template4.readonly {
        .input {
            color: #4a4a60;
        }
    }
    &.template1.readonly {
        .input {
            color: #202437;
        }
    }
    &:hover,
    &.focus {
        border-color: var(--color-theme);
    }
    &.disabled {
        border: solid 1px #ebecf1;
        background: #f8f9fd;
        &.hover,
        &.focus {
            border: solid 1px #ebecf1;
        }
    }
    .input_wrap {
        position: relative;
        flex: 1 0 0;
        display: flex;
        height: 100%;
        .input {
            width: 100%;
            height: 100%;
            outline: none;
            border: 0 !important;
            box-shadow: none;
            border-radius: 0;
            background: transparent;
            box-sizing: border-box;
            padding: 0 10px;
            /* Eliminate global style interference */
            height: 100% !important;
            background-color: transparent !important;
            box-shadow: none !important;
            font-size: 14px;
            min-height: 30px;
            &.rpad35 {
                padding-right: 35px;
            }
            &.lpad35 {
                padding-left: 45px;
            }
            &.left {
                text-align: left;
            }
            &.center {
                text-align: center;
            }
            &.right {
                text-align: right;
            }
            ::placeholder {
                line-height: normal;
            }
        }
        .search {
            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            height: 100%;
            width: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #cccccc;
            cursor: pointer;
        }
        .clear {
            position: absolute;
            z-index: 1;
            right: 0;
            top: 0;
            cursor: pointer;
            height: 100%;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #cccccc;
        }
    }
}

</style>
