import { hasPermission } from "@/common/permission/utils.js";

const vHasPermission = {
    inserted(el, binding) {
        const resource = binding.value;
        if (!hasPermission(resource)) {
            // el.style.display = 'none';
            el.remove();
        }
    }
}


export default Vue => {
    Vue.directive('hasPermission', vHasPermission)
}