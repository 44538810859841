import util from './common/util';
import FastClick from 'fastclick';

if (util.isMobile) {
    FastClick.attach(document.body);
}
FastClick.prototype.focus = function(targetElement) {
    var length;
    if (
        util.isIos &&
        targetElement.setSelectionRange &&
        targetElement.type.indexOf('date') !== 0 &&
        targetElement.type !== 'time' &&
        targetElement.type !== 'month' &&
        targetElement.type !== 'email'
    ) {
        length = targetElement.value.length;
        targetElement.focus(); //  solve part ios Trigger keyboard insensitivity problem 
        targetElement.setSelectionRange(length, length);
    } else {
        targetElement.focus();
    }
};