import util from "@/common/util";

function doObserver(container, config) {
    let { duration = 1000, name, selector, enable, options } = config;
    if (enable !== false) {
        let elements = selector ? container.querySelectorAll(selector) : [container];
        // Store observers on the element for cleanup
        let observer = util.getObserver(elements, (target) => {
            target.classList.add(name)
            target.style['transition-duration'] = `${duration}ms`;
            window.setTimeout(() => {
                target.classList.remove(`${name}-enter-from`)
                target.classList.add(`${name}-enter-to`);
            }, 200);
        }, options || { threshold: 0 });
        container._observerList.push(observer);
    }
}

function doPrepare(container, config) {
    let { name, selector, enable } = config;
    let elements = selector ? container.querySelectorAll(selector) : [container];
    if (enable !== false) {
        elements.forEach(ele => {
            ele.classList.add(`${name}-enter-from`);
            ele.classList.remove(`${name}-enter-to`);
            ele.style['transition-duration'] = `0ms`;
        });
    } else {
        elements.forEach(ele => ele.classList.remove(`${name}-enter-from`)); 
    }
}

const animated = (container, binding) => {
    if (util.isSSRServer) {  // util.inCms
        return;
    }
    // console.log('------animated-------', binding.value);
    if (container._observerList) {
        container._observerList.forEach(item => {
            item.disconnect();
        });
    }
    container._observerList = [];
    if (util.isArray(binding.value)) {
        binding.value.forEach(config => doObserver(container, config))
    } else {
        doObserver(container, binding.value);
    }
}

const prepareAnimation = (container, binding) => {
    if (util.isSSRServer) {
        return;
    }
    if (util.isArray(binding.value)) {
        binding.value.forEach(config => doPrepare(container, config))
    } else {
        doPrepare(container, binding.value);
    }
}

const genSSRClassProps = (config) => {
    let { name, selector, enable } = config;
    if (enable !== false && !selector) {
        return `${name}-enter-from`;
    }
}

/**
 * v-animated="{name: 'fade-in', duration: 500}"
 * v-animated="[
 *    {name: 'fade-in', duration: 500, selector: 'h3', enable: false, option: { rootMargin: '40%' }}, 
 *    {name: 'fade-in', duration: 1000, selector: 'h2'}
 * ]"
 */
export default (Vue) => {
    Vue.directive("animated", {
        updated(el, binding) {
            if (util.inCms) {
                prepareAnimation(el, binding);
                animated(el, binding);
            }
        },
        beforeMount(el, binding) {
            prepareAnimation(el, binding);
        },
        mounted(el, binding) {
            animated(el, binding);
        },
        unmounted(el) {
            if (el._observerList) {
                el._observerList.forEach(observer => observer.disconnect()); 
            }
        },
        getSSRProps(binding) {
            if (binding.instance?.platform?.isReallyUser) {
                // server-side implementation:
                // return the props to be rendered.
                // getSSRProps only receives the directive binding.
                let className = '';
                if (util.isArray(binding.value)) {
                    className = binding.value.map(config => genSSRClassProps(config)).filter(c => !!c).join(' ')
                } else {
                    className = genSSRClassProps(binding.value);
                }
                return {
                    class: className
                }
            }
        }
    });
}